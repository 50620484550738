@import "Variables.less";

.custom-switch-wrapper {
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .switch-handle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s linear;
  }

  &.checked {
    justify-content: flex-end;
    background: transparent;
    border: 1px solid @primary-2;

    .switch-handle {
      background: @primary-2;
      margin-right: -4px;

      .custom-icon {
        fill: @panel;
        stroke: @panel;
      }
    }
  }

  &.unchecked {
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.25);
    border: none;

    .switch-handle {
      background: @panel;
      margin-left: -4px;

      .custom-icon {
        opacity: 0.6;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.primary {
    &.checked {
      background: transparent;
      border: 1px solid @panel;
      .switch-handle {
        background: @panel;
        .custom-icon {
          fill: @primary-2;
          stroke: @primary-2;
        }
      }
    }

    &.unchecked {
      background: @header-background;
      border: none;
      .switch-handle {
        background: @primary-2;
        .custom-icon {
          fill: @panel;
          stroke: @panel;
        }
      }
    }
  }
}
