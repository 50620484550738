@import "./Variables.less";
.hs-button {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 12px;
    border: 2px solid transparent;
    color: @labels;
    background: linear-gradient(@background, @background) padding-box,
      @button-stroke-default border-box;
    height: 36px;
    cursor: pointer;
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      color: @labels !important;
      border-color: #4096ff;
    }
    .action {
      display: flex;
      align-items: center;
      .hs-button-icon {
        display: flex;
        width: 20px;
        height: 20px;
        padding: 2.667px 1.333px 1.333px 2.667px;
        justify-content: center;
        align-items: center;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    .hs-button-text {
      padding-top: 2px;
      color: #000;
      text-align: right;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.3px;
      padding-left: 5px;
      text-transform: uppercase;
    }
  }