@import "Variables.less";

@font-face {
  font-family: "Euclid Circular";
  src: url("../../public/Euclid-Circular-A.woff");
}

/* generic classes */
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-just-center {
  display: flex;
  justify-content: center;
}
.flex-just-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Hide dates from previous or next month */
.ant-picker-cell:not(.ant-picker-cell-in-view) {
  visibility: hidden; /* Hides the cell but keeps layout */
  pointer-events: none; /* Ensures they are not clickable */
}
.custom-rangepicker-container {
  position: relative;
  display: inline-flex;
  align-items: center;

  .ant-picker-range {
    width: 400px !important;
    height: 61px;
    border-radius: 15px;
    border-color: #D9ECFA;
  }
}

.ant-picker-dropdown:not(.ant-picker-dropdown-range) .ant-picker-month-panel-container .ant-picker-panel-layout {
  width: 338px;
  background-color: #D9ECFA;
  position: absolute !important;
  top: 100%;

  .ant-picker-month-panel {
    width: 338px;
  }
}
.endOnSingleDatePicker:not(.ant-picker-dropdown-range) .ant-picker-month-panel-container .ant-picker-panel-layout {
  position: static !important;
  top: 100%;

  .ant-picker-month-panel {
    width: 338px;
  }
}

.ant-picker-dropdown:not(.ant-picker-dropdown-range) .ant-picker-decade-panel-container .ant-picker-panel-layout {
  width: 338px;
  background-color: #D9ECFA;
  position: absolute !important;
  top: 100%;

  .ant-picker-decade-panel {
    width: 338px;
  }
}

.endOnSingleDatePicker:not(.ant-picker-dropdown-range) .ant-picker-decade-panel-container .ant-picker-panel-layout {
  position: static !important;
  top: 100%;

  .ant-picker-month-panel {
    width: 338px;
  }
}
.ant-picker-dropdown:not(.ant-picker-dropdown-range) .ant-picker-year-panel-container .ant-picker-panel-layout {
  width: 338px;
  background-color: #D9ECFA;
  position: absolute !important;
  top: 100%;

  .ant-picker-year-panel {
    width: 338px;
  }
}

.endOnSingleDatePicker:not(.ant-picker-dropdown-range) .ant-picker-year-panel-container .ant-picker-panel-layout {
  position: static !important;
  top: 100%;

  .ant-picker-month-panel {
    width: 338px;
  }
}

/* Apply styles only to DatePicker, not RangePicker */
.ant-picker-dropdown:not(.ant-picker-dropdown-range) .ant-picker-date-panel {
  width: 338px;
  background-color: #D9ECFA;
  position: absolute !important;
  top: 100%;
}

.endOnSingleDatePicker:not(.ant-picker-dropdown-range) .ant-picker-date-panel {
  position: static !important;
  top: auto !important;
}
.ant-picker-dropdown:not(.ant-picker-dropdown-range) 
.ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) 
.ant-picker-cell-inner {
  background: #005D98 !important;
  color: white !important;
}

.endOnSingleDatePicker:not(.ant-picker-dropdown-range) 
.ant-picker-date-panel
.ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) 
.ant-picker-cell-inner {
  background: #005D98 !important;
  color: white !important;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  background-color: #D9ECFA;
}

.ant-picker-range-wrapper {
  .ant-picker-panel-container {
    .ant-picker-year-panel  {
      background-color: #D9ECFA;
      width: 338px;
      .ant-picker-header >button{
        display: none;
      }
      .ant-picker-cell.ant-picker-cell-in-range.ant-picker-cell-in-view .ant-picker-cell-inner {
        background: none !important;
      }
    }
    .ant-picker-decade-panel {
      background-color: #D9ECFA;
      width: 338px;
      .ant-picker-header >button{
        display: none;
      }
    }

    .ant-picker-month-panel {
      background-color: #D9ECFA;
      width: 338px;
      .ant-picker-header >button{
        display: none;
      }
    }

  }
}
  

/* Hide the next and previous year arrows only for DatePicker */
.ant-picker-dropdown:not(.ant-picker-dropdown-range) 
.ant-picker-header-super-prev-btn,
.ant-picker-dropdown:not(.ant-picker-dropdown-range) 
.ant-picker-header-super-next-btn {
  display: none !important;
}

.ant-picker-dropdown .ant-picker-header {
  color:  #113E57;
  font-size: 16px;
  font-weight: 700;
  
    button .ant-picker-prev-icon {
      color:  #113E57;
      font-weight: 700;
    }

    button .ant-picker-next-icon {
      color:  #113E57;
      font-weight: 700;
    }
}
.ant-picker-dropdown .ant-picker-content th {
  color:#0A0A0A;
  font-size: 14px;
  font-weight: 500;
}

.ant-picker-dropdown:not(.ant-picker-month-panel-container):not(.ant-picker-year-panel-container) .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  width: 36px;
  height: 30px;
  background: white;
  line-height: 30px;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.88);
}

.ant-picker-dropdown:not(.endOnSingleDatePicker) .ant-picker-cell-disabled .ant-picker-cell-inner {
  // border: 1px solid #CCDCE4;
  // border-radius: 4px;
  // background: #CCDCE4;    
  color: #808080;
  background: none !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
  background: none !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before {
  background: none !important;
}
.ant-picker-dropdown .ant-picker-cell-disabled::before {
  background: none;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before{
  background: none;  
} 
/* Apply background color to enabled dates */
.custom-rangepicker-container .ant-picker-cell:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background: #ffff;
  color: black;
}
.ant-picker-cell.ant-picker-cell-in-range.ant-picker-cell-in-view .ant-picker-cell-inner {
  background: #3FA9F5 !important;    
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #113e57 !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner { 
  background: #113E57;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background: #113E57 !important;
  color: white !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background: #113E57 !important;
  color: white !important;

}

.custom-prefix-icon {
  position: absolute;
  left: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  pointer-events: none; /* Ensure the icon doesn't block input interactions */
}

.custom-rangepicker-container .ant-picker {
  padding: 4px 80px 4px 56px; /* Adjust to make space for the prefix icon */
  color: #113E57;
}
.ant-picker-range .ant-picker-range-separator {
  color: #CCDCE4;
  padding: 0 20px 0 8px;
}
/* Hide year navigation arrows */
  .ant-picker-panels {
    .ant-picker-panel {
      .ant-picker-date-panel {
        width: 375px;
        .ant-picker-header{
          .ant-picker-header-super-prev-btn,
          .ant-picker-header-super-next-btn {
            display: none;
          }
        }  
      }    
    }
  }

.clickable-item {
  cursor: pointer;
}
.non-clickable-item {
  cursor: default !important;
}
.overflow-hidden {
  overflow: hidden;
}

span.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.rotate-180 {
  transform: rotate(180deg);
}

.custom-scrollbar {
  ::-webkit-scrollbar {
    width: 10px;
    height: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    // outline: 1px solid slategrey;
  }
}

/* wrapper for page content inside mainpage */
.page-content-wrapper {
  height: 100%;
  display: flex;
}

/* header of individual pages */
.page-header-container {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @labels;
  padding: 10px 30px;
  background: @panel;
  cursor: default;

  .page-header {
    color: @text-colour;
    font-size: 30px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
  }

  .header-action-container {
    display: flex;
    align-items: center;

    .search-container {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      text-align: center;

      .ant-input-affix-wrapper {
        .ant-input-prefix {
          height: 40px;
          margin-inline-end: 10px;
        }
      }

      .search-input {
        height: 30px;
        width: 300px;
      }
      .search-acc-input {
        height: 50px;
        width: 300px;
        background:  #D9ECFA;
        border: none;
        border: 1px solid #D9ECFA !important;

        .ant-input::placeholder {
          color: #113E57 !important; /* Change this to your desired color */
          font-weight: 700; /* Set the font weight */
          font-size: 18px; /* Adjust placeholder size */
        }
      }
     
      .search-helper-text {
        font-size: 12px;
        color: @selected;
        font-weight: 400;
        margin-left: 4px;
        margin-top: 2px;
      }
    }
    .filter-container {
      margin-left: 15px;
      margin-top: 4px;
    }

    .applied-filter-container {
      display: flex;
      align-items: center;

      .applied-filter {
        display: flex;
        align-items: center;
        padding: 5px 20px;
        border-radius: 30px;
        background-color: @low-priority-fill;
        margin-right: 10px;

        .text {
          font-size: 14px;
          font-weight: 700;
          line-height: normal;
        }
        .remove-icon {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .search-exist {
      margin: 20px;
    }

    .clear-all-container {
      color: #3fa9f5;
      font-size: 16px;
      cursor: pointer;
    }

    .search-icon {
      height: 30px;
      width: 30px;
      margin-left: 15px;
      fill: @text-colour;
      stroke: @text-colour;
      cursor: pointer;
    }
    .filter-icon {
      height: 24.3px;
      width: 24px;
      fill: @text-colour;
      stroke: @text-colour;
      cursor: pointer;
    }
  }

  .divider {
    height: 42px;
    background: @low-priority-fill;
  }
}

/* count display for list of items  */
.item-count-container {
  margin: 10px 30px;
  // gap: 42px;
  cursor: default;
  .item-count {
    color: @primary;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.29px;
    text-align: left;
    color: @text-colour;
  }
  .accounts-count {
    display: flex;
    flex-direction: column;

    .accounts-count-text {
      color: #808080;
      font-size: 13px;
      font-weight: 700;
      line-height: 20.29px;
    }
  }
}

/* display list of items as cards */
.item-list-container {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  overflow-y: auto;
  flex: 1;

  .skeleton-container {
    height: 68px;
  }

  .item-container {
    display: flex;
    align-items: center;
    background-color: @panel;
    border-radius: 16px;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    margin-bottom: 10px;
    padding: 4px 20px;
    color: @labels;
    padding: 4px 20px 4px 20px;
    gap: 15px;

    &:hover {
      box-shadow: 6px 6px 2px 0px rgba(4, 76, 123, 0.3);
    }

    .ant-divider.ant-divider-vertical {
      background: @panel-outline;
      height: 35px;
    }

    .list-association-count-container {
      display: flex;
      cursor: default;
      .count-icon {
        height: 30px;
        width: 30px;
      }

      .count-container {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        margin-top: 3px;

        .count-text {
          font-size: 16px;
          font-weight: 700;
          width: 30px;
          margin-top: 4px;
          color: #113E57;
        }

        .association-text {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .pending-action-container {
      min-width: 90px;
    }

    .association-count-container {
      display: flex;
      .count-icon {
        height: 25px;
        width: 25px;
      }
      .count-container {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        margin-top: 3px;

        .count-text {
          font-size: 16px;
          font-weight: 700;
          color: #113E57;
          width: 30px;
        }
        .association-text {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .red-icon {
        fill: red !important;
      }    
      .red-text {
        color: red !important;
      }
    }

    .actions-container {
      display: flex;
      align-items: center;
      .empty-action {
        width: 50px;
      }
      .action {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}

.user-profile-image {
  border: 2px solid @primary-1;
  border-radius: 50%;
  height: inherit;
  width: inherit;
}

/* custom page: page with box */
.custom-page-wrapper {
  padding: 19px 50px;

  .page-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 16px;
    padding: 10px 20px;
    padding-top: 0;
    filter: drop-shadow(6px 6px 20px rgba(4, 76, 123, 0.2));
  }
}

/* form related */
.custom-form {
  .form-item-verticle {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 10px;
    }
  }

  .form-item {
    margin-right: 30px;

    label {
      color: @labels;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      height: fit-content;
    }
    input {
      color: @labels;
      height: 42px;
      width: 300px;
      font-size: 16px;
      background: @panel;
      border-radius: 8px;
      border: 1px solid @primary;

      &:disabled {
        opacity: 0.7;
      }

      &::placeholder {
        font-weight: 400;
        color: @low-priority-label;
      }
    }
  }
}

/* Event type button related */
.zoomeventtype {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #000;
  background: #fff;
  border-radius: 30px;
  padding: 4px 20px;
}

.hseventype {
  color: #005c98;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #005c98;
  background: #fff;
  border-radius: 30px;
  padding: 4px 20px;
  cursor: pointer;
}
.eventype-container {
  display: flex;
  flex-direction: row; 
  align-items: center;
  color: #0A0A0A;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #FFA500;
  background: #FFDEA1;
  border-radius: 30px;
  padding: 4px 15px 4px 15px;;
  cursor: pointer;

  .event-type  {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;    

    .event-type-icon {
      height: 20px;
      width: 20px;
    }
    
    .event-icon-color {
      stroke: black !important;
      fill: black !important;
    }
  }
}

.call-activity-hseventype{
  color: #005c98;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #005c98;
  background: #fff;
  border-radius: 30px;
  padding: 4px 20px;
  cursor: default;
}

.call-activity-zoomeventtype {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #000;
  background: #fff;
  border-radius: 30px;
  padding: 4px 20px;
  cursor: default;
}

.remindereventtype {
  color: #005c98;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #005c98;
  background: #fff;
  border-radius: 30px;
  padding: 4px 20px;
  cursor: pointer;
}

/* button related */
.custom-button {
  display: flex;
  align-items: center;
  height: 36px;
  border: none;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  padding: 8px 24px;
  z-index: 1;
  color: @labels;
  background: linear-gradient(135deg, #fff, #aaa);

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background-color: #f6f6f6;
    border-radius: 10px;
    z-index: -1;
    transition: 200ms;
  }

  &:hover::before {
    opacity: 70%;
  }

  .button-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    // filter: invert(0%) sepia(100%) saturate(7500%) hue-rotate(3deg)
    //   brightness(116%) contrast(114%);

    .custom-icon {
      height: 20px;
      width: 20px;
    }
  }
  .button-text {
    height: 20px;
    margin-top: 2px;
    display: flex;
    align-items: center;
  }

  &.primary {
    color: @panel;
    background: linear-gradient(135deg, #fff, #235c93);
    &::before {
      background-color: @primary-2;
    }
  }

  &.warning {
    color: @panel;
    &::before {
      background-color: @warning;
    }
  }

  &.primary,
  &.warning {
    .button-icon .custom-icon {
      fill: @panel;
      stroke: @panel;
      // filter: invert(93%) sepia(94%) saturate(28%) hue-rotate(249deg)
      //   brightness(107%) contrast(100%);
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

/* custom styles*/
.note-box {
  height: fit-content;
  padding: 20px;
  color: @labels;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 16px;
  background: @panel;
  box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
}

.badge {
  color: @panel;
  font-size: 14px;
  font-weight: 700;
  background: @highlight;
  border-radius: 30px;
  width: fit-content;
  padding: 6px 20px;
  cursor: pointer;

  &.yellow {
    background: @highlight-yellow;
  }
}

/* text related */
.header-1 {
  color: @labels;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.header-2 {
  color: @labels;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.header-3 {
  color: @labels;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.header-4 {
  color: @labels;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.header-5 {
  color: @labels;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.body-text-1 {
  color: @labels;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.body-text-2 {
  color: @labels;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.body-text-3 {
  color: @labels;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.small-text {
  color: @labels;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.large-text-1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* dropdown related */
.custom-dropdown {
  color: @labels;
  width: 200px;
  height: 45px;
  border-radius: 8px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  background: linear-gradient(@panel, @panel) padding-box,
    @button-stroke-default border-box;
  cursor: pointer;
  box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .dropdown-label {
    display: flex;
    align-items: center;
    width: 90%;

    .text {
      margin-left: 5px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .filter-textt {
      font-size: 14px;
    }
  }
  .status-icon {
    fill: #005D98 !important;
    stroke: @labels !important;
  }
}

.accounts-filter {
  border: 1px solid #D9ECFA !important;   
  background: none;
  box-shadow: none;
  height: 50px;
  margin-top: 10px;
}

.custom-dropdown-style {
  z-index: unset;
  margin-top: -10px;
  min-width: 0px !important;
  z-index: 1;

  .ant-dropdown-menu {
    padding: 0;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    border-radius: 16px;
    min-width: max-content;

    .ant-dropdown-menu-item {
      height: 50px;
      border-radius: 0;
      padding-left: 20px;
      padding-right: 20px;

      &.highlight-label{
        background: rgba(63, 169, 245, 1) !important;  
        border-radius: 7px !important;
      }

      &.ant-dropdown-menu-item-selected {
        color: @panel;
        background-color: @primary;
      }

      .ant-dropdown-menu-title-content {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .ant-dropdown-menu-item:first-child {
      &.ant-dropdown-menu-item-selected,
      &:hover {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }
    .ant-dropdown-menu-item:last-child {
      &.ant-dropdown-menu-item-selected,
      &:hover {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }
}

.custom-gradient-dropdown-style {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item:not(:first-child) {
      margin-top: 3px;
    }
    .ant-dropdown-menu-item {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 8px 24px;
      border-radius: 12px;
      border: 2px solid transparent;
      background: linear-gradient(@background, @background) padding-box,
        @button-stroke-default border-box;

      .custom-icon {
        width: 20px;
        height: 20px;
      }
      .menu-icon {
        margin-right: 10px;
      }
      .menu-label {
        height: 16px;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled) {
      color: @labels;
      &:hover {
        filter: brightness(0.9);
      }
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
      .custom-icon {
        opacity: 0.5;
      }
    }
  }
}

.ant-dropdown {
  .listschedule-filter {
    background: #ffffff;
    border: 2px solid #d9ecfa;
    border-radius: 13px;
    padding: 10px 30px 10px 10px !important;
    ul {
      .ant-dropdown-menu-item-group {
        .ant-dropdown-menu-item-group-title {
          font-size: 16px;        
          font-weight: 700;
          border: none;
        }
      }
    }
    
    .dropdownbuttons {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-left: 24px;

      .buttonstyle {
        color: #005d98;
        cursor: pointer;
        font-family: "Euclid Circular A";
        font-feature-settings: "salt" on;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-decoration-line: underline;
      }

      .reset-btn {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }

  .listschedule-filter .ant-dropdown-menu {
    box-shadow: none;
    background-color: #ffffff;
  }
  .ant-dropdown-menu {
    padding: 0;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    border-radius: 16px;
  
    .ant-dropdown-menu-item-group {
      .ant-dropdown-menu-item-group-title {
        border-bottom: 1px solid #000;
        color: #000;
        font-size: larger;
        font-weight: bold;
        width: 75%;
      }

      .ant-dropdown-menu-item-group-list {
        .ant-dropdown-menu-item {
          .ant-dropdown-menu-title-content {
            .ant-checkbox-wrapper {
              span {
                cursor: pointer;
              }
              .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #005C98;
                border-color: #005C98;
              }
            }
          }
        }
      }
    }
  }
}

/* icons related */
.custom-icon {
  fill: #113E57;
  stroke: #113E57;

  &.disabled {
    fill: @selected !important;
    stroke: @selected !important;
    cursor: not-allowed !important;
  }

  .stroke {
    fill: none !important;
  }
  .fill {
    stroke: none !important;
  }

  &.white {
    fill: @panel;
    stroke: @panel;
  }
}

.hc200-icon-container {
  background-color: @new-background;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin: -2px;

  .hc200-icon-status {
    width: 15px;
    height: 15px;
    margin-bottom: -70%;
    margin-left: -20%;
    z-index: 1;
    .disconnected {
      stroke: #b9b9b9;
    }
    .failure {
      stroke: @warning;
    }
  }
}

/* table related */
.entity-table {
  .entity-actions-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .entity-action {
      display: flex;

      .custom-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .empty-action {
      width: 22px;
    }
  }
}

/* antd style changes*/
.ant-divider.divider {
  margin: 15px 0;
  border-block-color: #C9C9C9
}
.ant-divider.ant-divider-vertical {
  margin: 0 15px;
}

/* modal */
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }

  .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left;
  }
}

/* New Custom Buttom */
.new-custom-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #005d98;
  border-radius: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;

  .new-button-icon {
    display: flex;
    align-items: center;
  }

  .new-button-text {
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    font-feature-settings: "salt" on;
    color: #005d98;
  }
  &.disabled {
    cursor: not-allowed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    background: #808080;
    border-radius: 32px;
    border: none;

    .new-button-text {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on;
      color: #ffffff;
    }
  }
  .primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    background: #d9ecfa;
    border-radius: 32px;

    .new-button-text {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on;
      color: #113e57;
    }
  }
  &.primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    background: #d9ecfa;
    border-radius: 32px;
    border: none;
    .new-button-text {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on;
      color: #113e57;
    }
  }
}
  /* Styling the scrollbar */
  .selected-list-container::-webkit-scrollbar,
  .contact-list-scroll::-webkit-scrollbar {
    width: 8px;
  }
  .selected-list-container::-webkit-scrollbar-thumb,
  .contact-list-scroll::-webkit-scrollbar-thumb {
    background-color: #005d98;
    border-radius: 20px; /* Rounded corners for the scrollbar thumb */
    min-height: 50px; /* Adjust this to increase the scrollbar thumb height */
    height: 50px;
  }
  .selected-list-container::-webkit-scrollbar-track,
  .contact-list-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }
  .selected-list-container::-webkit-scrollbar-thumb:hover,
  .contact-list-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #005d98;
  }