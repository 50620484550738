@import "./Variables.less";

.progressbar-wrapper {
  width: 100%;
  border-radius: 10px;
  background: @panel-layer-1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  position: relative;

  &.adjustable {
    cursor: pointer;
  }

  .progressbar-inner {
    height: inherit;
    border-radius: 10px;
    background-color: @primary-2;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
    transition: width 0.3s ease-in-out;
  }

  .adjust-circle {
    position: absolute;
    top: -5px;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: white;
    border: 2px solid @primary-2;
    cursor: pointer;
    transition: left 0.3s;

    &:active {
      transform: translateX(-50%) scale(1.2);
    }
  }
}
