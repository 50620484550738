.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .header-icon-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .header-title {
      color: rgba(0, 92, 152, 1);
      padding: 10px;
      margin: auto;
      font-family: "Euclid Circular A";
      font-size: 33px;
      font-weight: 700;
      line-height: 38.04px;
      text-align: left;
    }
    .header-icon {
      /* 30px Icons */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 10px;
      isolation: isolate;

      width: 45px;
      height: 45px;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  .header-filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .header-filter-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .header-filter-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-family: "Euclid Circular A";
        font-size: 16px;
        text-align: left;
        width: 112px;
        height: 19px;
        left: 0px;
        top: 2px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        text-align: center;

        color: #005d98;

        .header-filter-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.date-toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .date-container {
    .date-title {
      /* Latest Conditions */

      width: 266px;
      height: 21px;

      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      margin: 2px;
      padding: 2px;
      line-height: 23px;

      color: #000000;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .date-content {
      /* Group 25464 */

      width: 267px;
      height: 21px;
      margin: 2px;
      padding: 2px;
      font-weight: 500;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  .toggle-container {
    .temperature-button {
      color: white;
      width: 42px;
      height: 32px;
      border-color: transparent;
      margin: 2px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.cards-container {
  /* Frame 25562 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0px 0px;
  gap: 20px;

  width: 100%;
  height: 420px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

// .ant-modal-content {
//   width: 1200px;
//   right: 200px;
//   height: 900px;
// }

.graph-location-container{
  display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 0px;
gap: 8px;
width: 1004px;
height: 25px;
align-self: stretch;

.graph-location-title{
font-family: "Euclid Circular A";
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
color: #005D98;
}
}

.custom-modal-trend-graph .ant-modal-body {
  font-size: 14px;
    line-height: 1.5714285714285714;
    word-wrap: break-word;
    padding: 0;
    min-height: 745px;
}