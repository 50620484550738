.motion-activity-header {
  display: flex;
  padding: 15px;
  cursor: default;
  .motion-activity-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 33px;
    font-weight: 800;
    color: rgba(0, 92, 152, 1);
    padding: 10px;
  }
}

.motion-calendar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .motion-calendar-date-display {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-size: 18px;
    padding: 15px;
    width: 185px;
    cursor: default;
  }
  .calendar-nav-icons {
    display: flex;
    align-items: center;
    margin-left: 30px;
    gap: 10px;
    .arrow-icons {
      width: 16px;
      height: 12px;
      .calendar-nav-icon {
        cursor: pointer;
        margin: 0 5px; /* Adjust as needed */
      }
    }
  }
}

.main-activity-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  // gap: 10px;
  margin: 30px 15px;

  // width: max-content; //1085px;
  min-width: 1080px;
  height: 200px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  &.expanded {
    height: 430px;
  }
  &.collapsed {
    height: 220px;
  }

  .device-info-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 100px 0 20px;
    gap: 80px;

    width: 100%;
    // height: 89px;
    flex: none;
    order: 0;
    flex-grow: 0;

    .device-id-info {
      display: flex;
      flex-direction: column;

      .device-info-header {
        width: 157px;
        height: 23px;

        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;

        color: #005c98;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .device-serial-number {
        font-family: "Euclid Circular A";
        font-size: 13px;
        font-weight: 400;
        line-height: 16.1px;
        text-align: left;
        color: #6087a0;
        margin: 2px 0;
      }
    }

    .device-icon {
      width: 60px;
      height: 60px;

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .date-range {
      // width: 725px;
      height: 20px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #005c98;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: auto;

      .day-bold {
        font-weight: 700;
      }
    }
  }

  .motion-activity-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    width: auto; // 814px;
    height: 59px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px;
    margin-left: 15%;

    .trend-legends {
      display: flex;
      flex-direction: column;
      margin-top: 35px;
      gap: 5px;

      .motion-legend {
        height: 25px;
        width: 110px;
        border-radius: 5px;
        font-weight: 600;
        padding: 6px;
        background-color: blue;
        color: white;
      }

      .ambient-light-legend {
        height: 25px;
        width: 110px;
        border-radius: 5px;
        font-weight: 600;
        padding: 6px;
        background-color: black;
        color: white;
      }
    }

    .motion-trend-container {
      position: relative;

      .motion-trend-hours {
        display: flex;
        flex-direction: row;
        z-index: 1;
      }

      .motion-trend-graph {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }

    .past-motion-container {
      font-family: "Euclid Circular A";
      padding: 8px;
      margin-top: 2px;
      font-size: medium;
      color: black;
      width: 100px;
      text-align: right;
    }

    .main-hour-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1px;
      &.trend {
        height: 64px;
      }
      &.not-trend {
        height: 32px;
      }
      .motion-empty-block {
        background-color: rgba(217, 217, 217, 1);
        width: 32px;
        height: 32px;
      }
      .day-night-time {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        padding: 0px;
        font-size: 10px;
        color: #808080;
        font-weight: 700;

        width: 32px;
        height: 13px;
        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .night-time-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;

        width: 32px;
        height: 32px;
        border: 1px solid rgba(201, 201, 201, 1);

        background: #113e57;
        flex: none;
        order: 1;
        flex-grow: 0;
        // border-radius: 0;
        &.start {
          border-radius: 5px 0 0 5px;
        }
        &.end {
          border-radius: 0 5px 5px 0;
        }

        .motion-activity-block {
          .motion-bubble {
            border-radius: 50%;
            left: calc(50% - 20px / 2);
            top: calc(50% - 20px / 2);

            background: #f26d24;
            border: 2px solid #ffffff;

            &.hide-bubble {
              display: none;
            }
          }
        }
      }

      .day-time-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;

        width: 32px;
        height: 32px;
        border: 1px solid rgba(201, 201, 201, 1);

        background: #d9ecfa;
        flex: none;
        order: 1;
        flex-grow: 0;
        // border-radius: 0;
        &.start {
          border-radius: 5px 0 0 5px;
        }
        &.end {
          border-radius: 0 5px 5px 0;
        }

        .motion-activity-block {
          .motion-bubble {
            border-radius: 50%;
            left: calc(50% - 20px / 2);
            top: calc(50% - 20px / 2);

            background: #f26d24;
            border: 2px solid #ffffff;

            &.hide-bubble {
              display: none;
            }
          }
        }
      }

      .future-hour-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;

        width: 32px;
        height: 32px;
        border: 1px solid rgba(201, 201, 201, 1);

        flex: none;
        order: 1;
        flex-grow: 0;
        // border-radius: 0;
        &.start {
          border-radius: 5px 0 0 5px;
        }
        &.end {
          border-radius: 0 5px 5px 0;
        }
      }

      .night-time-block,
      .day-time-block,
      .future-hour-block {
        &.trend {
          height: 64px;
        }
        &.not-trend {
          height: 32px;
        }
      }
    }
  }

  .past-render-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;

    .btn-text {
      display: flex;
      flex-direction: row;
      background: none;
      border: none;
      color: #005d98;
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      gap: 8px;
    }
  }
}

.button-select-date {
  /* Tag */
  font-family: "Euclid Circular A";
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 8px 20px;
  gap: 10px;
  cursor: pointer;

  width: 121px;
  height: 26px;

  background: #d9ecfa;
  border: 1px solid #000000;
  border-radius: 30px;
  margin: 0px 5px 0px 20px;

  /* Inside auto layout */
  flex: none;
  // order: 1;
  flex-grow: 0;
  font-weight: bold;
}

.spin-margin {
  margin: 80px 420px;
}
