@import "./Variables.less";

.taskform-container {
  .taskform-container {
    padding: 50px 45px;
    background: white;

    .taskform-card {
      // max-width: 1250px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      background: white;
      // padding: 20px;

      .task-title {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px;

        .form-title {
          color: #005D98;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 15px;
          gap: 15px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .divider {
    background-color: @panel-outline;
  }

  .form-items-container {
    display: flex;
    flex-direction: row;
    gap: 15px;

    .column-1 {
      display: flex;
      flex-direction: column;
      // flex: 1;
      border-radius: 16px;
      padding: 30px;
      border: 1px solid #D9ECFA;

      .general-info-text {
        display: flex;
        align-items: center;
        color: #113E57;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    .column-2 {
      display: flex;
      flex-direction: column;
      width: 400px;

      .contact-selection-list-wrapper {
        border: 1px solid #D9ECFA;
        padding-bottom: 20px;

        .selected-list-container {
          border: none;
          width: auto;
        }
      }
    }

    .form-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      // flex-wrap: wrap;
      // justify-content: space-between;

      .hs-daypicker .list-item {
        background-color: #d9ecfa;
        border: 1px solid #3fa9f5;
        border-radius: 18px;
        padding: 9px;
        color: #113E57;
      }

      .list-item-selected {
        background-color: #113E57;
        border: 1px solid #113E57;
        border-radius: 18px;
        padding: 9px;
        color: #ffff;
      }
    }

    .single-datepicker-wrapper {
      height: 61px;
      width: 375px;
      border-radius: 13px;
      border-color: #D9ECFA;
      .single-datepicker-calendar {
        .action {          
          .ant-picker-outlined {
            height: 0px;
            width: 0px;
          }
        }
      }
    }
   
    .onetimetask {
      width: fit-content;
      height: 61px;
      border-radius: 13px;
      border-color: #D9ECFA;

      .hs-datepicker-content {
        .hs-datepicker-date {
          .hs-datepicker-calendar {
            .action {
              .ant-picker {
                width: 200px;
                height: 46px;
              }
            }
          }
        }
      }
      .hs-datepicker-wrapper .hs-datepicker-content .hs-datepicker-date .hs-datepicker-calendar .ant-picker {
        width: 200px;
        height: 46px;
      }
    }

    .reminder-text {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      color: #808080;
      margin-top: 42px;
      padding: 30px;
    }

    .ant-picker-outlined {
      height: 61px;
      width: 400px;
      border-radius: 13px;
      border-color: #D9ECFA;
    }

    label {
      font-weight: 700;
      margin-bottom: 5px;
      display: flex;
      font-size: 14px;
      color: #113E57;
    }

    .form-group {
      margin-top: 27px;

      .ant-form-item .ant-form-item-label>label.ant-form-item-required::before {
        content: "";
      }

      input {
        height: 61px;
        width: 400px;
        border-radius: 13px;
        border-color: #D9ECFA;
      }
    }

    .form-group-select {
      width: 400px;
      border-radius: 5px;
      border-width: 2px;
      border-color: #005c98;

      .ant-select-selector {
        height: 61px;
        width: 400px;
        border-radius: 13px;
        border-color: #D9ECFA;

        .ant-select .ant-select-selection-wrap {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .ant-select .ant-select-arrow {
        color: #005D98;
        margin-top: 8px;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .manadatory-text {
      font-weight: 700;
      display: flex;
      font-size: 14px;
      color: #113E57;
    }

    .form-description {
      width: 512px;
      min-height: 120px;
      max-height: 150px;
      border-color: #D9ECFA;
    }
  }

  .taskform-action-button-container {
    display: flex;
    justify-content: end;
    gap: 14px;
    margin-top: 65px;

    .cancel-icon {
      display: flex;
      width: 24px;
      height: 24px;
      fill: #005d98;
    }

    .cancel-icon {
      display: flex;
      width: 24px;
      height: 24px;
      stroke: #005d98;
    }

    .update-icon-enabled {
      width: 24px;
      height: 24px;
      fill: #113e57;
      stroke: #113e57;
    }

    .update-icon-disabled {
      width: 24px;
      height: 24px;
      fill: white;
      stroke: white;
    }

    .save-icon-enabled {
      width: 24px;
      height: 24px;
      fill: #113E57;
    }

    .save-icon-disabled {
      width: 24px;
      height: 24px;
      fill: white;
    }
  }
}

.task-modal-conatiner{
  .ant-modal-content {
    width: 480px;
    height: 310px;
    padding: 40px;
  
    .ant-modal-header {
      .ant-modal-title {
        font-size: 24px;
        font-weight: 700;
        color: #113E57;
      }
    }
  
    .ant-modal-body {
      .taskmodal {
        .taskmodaldescription {
          display: flex;
          margin: 20px 0px 20px 0px;
          gap: 20px;
          flex-direction: column;
          font-size: 16px;
          font-weight: 700;
          color: #005D98;
        }
        .taskmodifydescription {
          margin: 28px 0px 28px 0px !important;
        } 
        .taskmodalbutton {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: end;
  
          .custom-yes-button,
          .custom-no-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 32px;
            gap: 8px;
            min-width: 120px;
            height: 56px;
            border-radius: 32px;
            cursor: pointer;
          }
  
          .custom-yes-button {
            border: #d9ecfa !important;
            background: #d9ecfa !important;
          }
  
          .custom-no-button {
            background: #ffffff !important;
            border: 1px solid #005d98 !important;
            border-radius: 32px !important;
          }
        }
      }
    }
  }
}

//view task
.view-taskform-container {
  .view-taskform-container {
    display: flex;
    align-items: center;

    .view-taskform-card {
      display: flex;
      // align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 40px 42px;
      flex-direction: column;
      flex-direction: column;
      gap: 35px;

      .view-task-title {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 40px 42px;

        .view-form-title {
          display: flex;
          align-items: center;
          font-size: 30px;
          font-weight: 700;
          gap: 20px;
          color: #005D98;
        }

        .view-task-action {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }

      .view-task-info-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 22.82px;
        text-align: left;
        color: #113E57;
      }

      .view-task-information {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .view-task-info-data {
          display: flex;
          flex-direction: column;
          gap: 20px;
          background-color: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #D9ECFA;
          padding: 24px 16px;
          font-size: 16px;
          font-weight: 700;
          color: #113E57;

          .view-task-info-header {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }

          .view-task-infovalue {
            font-weight: 400;
          }
        }
      }

      .view-task-status-info {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .view-task-status-calendar {
          background-color: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #D9ECFA;
          display: flex;
          align-items: center;
          padding: 40px;
          flex-direction: column;
          gap: 20px;

          .view-task-statushead {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;

            .view-task-status {
              border-radius: 11px;
              width: auto;
              padding: 8px;
              font-size: 12px;
              font-weight: 400;
            }
          }         
        }
      }
    }
  }
}

.task-calendar-container {
  margin: 0 auto;

  .task-calendar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;

    .selectyearmonth {
      background-color: #FFFFFF;
      border-radius: 15px;
      border: 1px solid #D9ECFA;
      font-size: 16px;
      font-weight: 400;
      width: 160px;
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .task-calendar-date-container {
    display: flex;
    align-items: center;

    .task-calendar-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 5px;
      background-color: #FFFFFF;
      border-radius: 15px;
      border: 1px solid #D9ECFA;
      padding: 30px;
      box-sizing: border-box;
    }
  }

  .task-calendar-day-header {
    color: #0A0A0A;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .task-calendar-day {
    text-align: center;
    padding: 8px;

    border-radius: 8px;
    width: 36px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .task-date-ongoing {
    background: #F9C030;
    border-color: #F9C030 !important;
  }
  .task-date-scheduled {
    background: #808080;
    border-color: #808080 !important;
  }
  .task-date-completed {
    background: #4BD37B;
    border-color: #4BD37B !important;
  }
  .task-date-missed {
    background: #F46363;
    border-color: #F46363 !important;
  }
  .task-date-expired {
    background: #999191;
    border: 2px solid #999191;
  }
  // .blank {
  //   background-color: #c66161;
  // }

  .today {
    border: 2px solid #005D98 !important;
    /* Blue border for today's date */
  }
}

.view-task-decription {
  .task-description {
    height: 187px;
    margin-top: 11px;
    
  }
}