.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;

  width: 100%;
  height: 66px;
  min-width: 950px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  flex: none;
  order: 1;
  flex-grow: 0;

  .heading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 200px;
    min-width: 120px;
    height: 41px;

    .location {
      height: 23px;

      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;

      color: #005d98;
    }

    .deviceId {
      height: 18px;

      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      color: #6087a0;
    }
  }

  .content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 55px;
    padding: 10px 20px;
    .battery-container {
      width: 60px;
      height: 60px;

      .accuweather-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin: 20px 0px;
      }
    }
    .temperature-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 10px 0px 0px;
      gap: 4px;
      height: 51px;

      .content-value {
        width: 61px;
        height: 51px;

        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 51px;
        text-align: center;

        color: #005d98;
      }

      .trendIcon {
        width: 30px;
        height: 30px;
      }
    }
    .humidity-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 10px 0px 0px;
      gap: 4px;

      width: 125px;
      height: 51px;

      .content-value {
        width: 72px;
        height: 51px;

        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 35px;
        text-align: center;

        color: #005d98;

        .superscript {
          vertical-align: super;
          font-size: 20px;
        }
      }
    }
    .light-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      width: 246px;
      height: 51px;

      .content-value {
        width: 66px;
        height: 51px;

        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #005d98;

        .superscript {
          vertical-align: super;
          font-size: 15px;
        }
      }
      .content-category {
        width: 132px;
        height: 51px;

        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        display: flex;
        align-items: center;

        color: #005d98;
      }
    }
  }

  .time-container {
    width: 200px;
    height: 51px;

    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #005d98;
    justify-content: flex-end;
  }
}
