@import "Variables.less";
.hs-daypicker {
    display: flex;
    padding: 5px;
    align-items: center;
    gap: 6px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    color: #005C98;
    text-align: center;
    border-radius: 5px;
    background: #F6F6F6;
    font-weight: 700;
    flex-direction: row;
    cursor: pointer;
    height: 45px;
    .list-item{
        display: flex;
        height: 35px;
        padding: 4px;
        align-items: center;
        min-width: 38px;
        justify-content: center;
        animation: list-item 300ms ease-out;
    }
    .list-item-selected{
        display: flex;
        color: #FFFFFF;
        background-color: #005C98;
        border-radius: 3px;
        min-width: 38px;
        padding: 4px;
        height: 35px;
        align-items: center;
        justify-content: center;
        animation: list-item-selected 300ms ease-out;
    } 
      @keyframes list-item-selected {
        0% {
          transform: scale(1);
        }
        10% {
          transform: scale(0.9);
        }
        30% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1.15);
        }
        100% {
          transform: scale(1);
        }
      }

      @keyframes list-item {
        0% {
          transform: scale(1);
        }
        10% {
          transform: scale(1.15);
        }
        30% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }
  }

  .list-item-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }