.ribbon {
  // position: fixed;
  font-family: Euclid Circular;
  display: flex;
  flex-direction: row;
  top: 0;
  width: 100%;
  background-color: #fbd0d0;
  color: #113E57;
  text-align: left;
  padding: 12px 16px;
  z-index: 1000;
  box-sizing: border-box;
  border: 1px solid #f46363;
  // border-radius: 8px;
  font-size: 16px;

  .message-type {
    font-weight: 700;
    margin: 3px;
  }
  .message {
    font-weight: 400;
    margin: 3px;
  }
}

.ribbon-close {
  position: absolute;
  top: 6px;
  right: 10px;
  background: none;
  font-weight: 700;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
