/* Add Contact */

/* Container for the entire add-contact form */
.add-contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 40px 0px 40px;
  gap: 40px;

  /* Styling for form labels */
  .label {
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #005d98;
    align-self: stretch;
    cursor: default;
  }

  /* Container for the role selection section */
  .role-container-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    align-self: stretch;
    flex-grow: 0;

    /* List of available roles */
    .roles-list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
    }
  }

  /* Container for individual form input fields */
  .form-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 4px;
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 180%;
    display: flex;
    align-items: center;
    font-feature-settings: "salt" on, "ss01" on;
    color: #113e57;
  }

  /* Container for form buttons (cancel and update) */
  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 20px 0px 0px;
    gap: 16px;
    align-self: stretch;

    /* Styling for cancel button icon */
    .cancel-icon {
      width: 24px;
      height: 24px;
      fill: #005d98;
    }
    /* Styling for enabled update button icon */
    .update-icon-enabled {
      width: 24px;
      height: 24px;
      fill: #113e57;
      stroke:#113e57;
    }
    /* Styling for disabled update button icon */
    .update-icon-disabled {
      width: 24px;
      height: 24px;
      fill: white;
      stroke: white;
    }
  }
  /* Header section for the add-contact form */
  .add-contact-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Icon next to the form header */
    .icon {
      width: 36px;
      height: 36px;
    }

    /* Styling for the form header text */
    .header {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #113e57;
      cursor: default;
    }
  }
  /* Block styling for the form sections */
  .add-contact-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 32px 24px;
    gap: 40px;
    background: #ffffff;
    box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
    border-radius: 16px;

    /* Container for the relationship section */
    .relationship-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      flex: none;
      order: 0;
      flex-grow: 1;

      /* Form item container for relationships */
      .relationship-form-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding: 0px;
        gap: 4px;
        /* Relationship details section */
        .relationship-details {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 24px;
          align-self: stretch;
        }
      }
    }
    /* Container for the contact section */
    .contact-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      /* Container for new contact section */
      .new-contact-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        align-self: stretch;
      }
    }
  }
  .shared-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    margin: 0 auto;
    padding: 20px 0px 0px 0px;
    .shared-content-title {
      font-family: "Arial";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #005d98;
    }
    .shared-content-remove-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      gap: 8px;
      border-radius: 32px;

      .shared-content-remove-button {
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        font-feature-settings: "salt" on;
        color: #005d98;
        cursor: pointer;
      }
    }
  }
}

/* Container for input fields */
.input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 482px;
  height: 61px;
  background: #ffffff;
  border: 1px solid #d9ecfa;
  border-radius: 16px;
  margin: 0 auto;
}

/* content within input fields */
.input-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px 0px 0px;
  gap: 8px;
  border: none;
  box-shadow: none !important;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on, "ss01" on;
  color: #000000;
}

/* Default border styling for input container */
.input-container.default {
  border: 1px solid #d9ecfa;
}

/* Error state border styling for input container (email)*/
.input-container.error {
  border: 1px solid #f46363;
}

/* Error text styling */
.error-text {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on, "ss01" on;
  color: #000000;

  /* error message content */
  .error-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    align-self: stretch;
  }

  /* Styling for error icon */
  .error-icon {
    width: 20px;
    height: 20px;
    border-radius: 0px;
  }
}
/* Make border and arrow red when error */
.error-select .ant-select-selector {
  border-color: red;
}
/* Change dropdown arrow to red */
.error-select .ant-select-arrow {
  color: red;
}
.timezone-error-field .ant-form-item-explain {
  display: block;
  margin-top: 7px;
}

/* Container for form details section */

.details-1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 20px 0px;
  gap: 24px;
  align-self: stretch;
}

/* Container for details section in the form */

.details-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

/* Placeholder text styling for input fields */

.ant-input::placeholder {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on, "ss01" on;
  color: #808080;
}

/* Styling for divider lines in the form */

.divider-style {
  border: 1px solid #c9c9c9;
  margin: 0;
}

.ant-input-outlined[disabled] {
  background-color: rgba(0, 0, 0, 0);
}

.remove-shared-content-pop-up-container .ant-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  gap: 32px;
  width: 589px;
  height: 269px;
  background: #ffffff;
  border-radius: 16px;

  .remove-all-shared-gallery-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 509px;
    height: 106px;
    justify-content: space-between;

    .remove-shared-content-title {
      width: 509px;
      height: 30px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #113e57;
    }
    .remove-shared-content-description {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #113e57;
    }
  }
  .remove-shared-footer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    gap: 16px;
    width: 509px;
    height: 51px;
    margin-top: 25px;
    .custom-yes-button,
    .custom-no-button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      gap: 8px;
      width: 246.5px;
      height: 51px;
      background: #ffffff;
      border: 1px solid #005d98;
      border-radius: 32px;
      cursor: pointer;
    }
    .custom-yes-button {
      border: #d9ecfa !important;
      background: #d9ecfa !important;
    }
    .custom-no-button {
      background: #ffffff !important;
      border: 1px solid #005d98 !important;
      border-radius: 32px !important;
    }
    .custom-yes-label,
    .custom-no-label {
      height: 19px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on;
    }
    .custom-yes-label {
      color: #113e57;
    }
    .custom-no-label {
      color: #005d98;
    }
    .custom-cancel-icon {
      fill: #005d98;
    }
  }
}
