@import "Variables.less";

.hs-datepicker-wrapper {
  display: flex;
  height: 34px;
  padding: 4px 17px 4px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #FFF;
  background: #FFF;
  box-shadow: 0px 3px 6px 0px rgba(0, 92, 152, 0.16);

  .hs-datepicker-hide {
    display: none;
  }

  .hs-datepicker-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .hs-datepicker-date {
      color: #000;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .hs-datepicker-day {
        font-weight: 700;
      }

      .hs-datepicker-calendar {
        padding-left: 10px;

        .action {
          display: flex;
          align-items: center;
          gap: 10px; /* Space between icon and DatePicker */
          cursor: pointer;
          flex-direction: row; /* Ensure normal row direction */
        }

        .ant-picker {
          height: 34px;
          border-color: rgb(0, 93, 152);
        }
      }
    }
  }
}

.calendar-icon {
  margin-right: 8px; /* Space between icon and DatePicker */
}