@import "./Variables.less";

/* VideoCallNotificationModal */
.video-call-notification-modal {
  z-index: 1350 !important;
  .ant-modal {
    min-width: 300px;
    .ant-modal-content {
      background: @panel;
      padding: 50px 100px;
      border-radius: 16px;
      box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    }
  }
  header {
    color: @primary-2;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  footer {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    .decline-icon {
      fill: @warning;
      stroke: @warning;
    }
    .custom-icon.accept-icon {
      fill: @good-dark-green;
      stroke: @good-dark-green;
    }
  }
  .content {
    margin-top: 25px;
    color: @primary-2;
    width: 25vw;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .placeholder-profile-pic {
        position: absolute;
        aspect-ratio: 1/1;
        z-index: 1;
        top: 23.8%;
        left: 31.7%;
        width: 37%;
        height: 52.2%;
        background: white;
        border-radius: 100%;
        border: 30px solid white;
      }
      .user-profile-pic {
        position: absolute;
        aspect-ratio: 1/1;
        z-index: 1;
        top: 22%;
        left: 30.5%;
        width: 39%;
      }
      .calling-gif {
        position: relative;
        width: 70%;
      }
    }
    .name {
      margin-top: 25px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .relation {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

/* VideoCallModal*/
.video-call-modal {
  .ant-modal {
    max-width: unset;
    padding-bottom: unset;
    .ant-modal-content {
      padding: 0;
    }
  }
}

/* UserTile */
.user-tile-container {
  aspect-ratio: 1/1;

  .layer-1 {
    border-radius: 50%;
    background-color: rgba(0, 114, 188, 0.4);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .layer-2 {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: rgba(0, 114, 188, 0.5);
  }
  .layer-3 {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background-color: rgba(0, 114, 188, 0.8);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .layer-4 {
    width: 85%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #04385a;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .user-icon {
    stroke: white;
    height: 50%;
    width: 50%;
    margin-bottom: 5%;
  }
}

/* UserVideoTile */
.user-video-tile {
  // aspect-ratio: 1/1;
  height: 100%;
  width: 100%;
  position: relative;

  .nameplate-container {
    height: 38px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: rgba(5, 55, 86, 0.8);
    border-radius: 30px;
    z-index: 2;
    gap: 5px;

    .mic-icon {
      background-color: @primary-2;
      stroke: @panel;
      fill: @panel;
      width: 38px;
      height: 38px;
      padding: 7px;
      border-radius: 50%;
      path {
        stroke-width: 1;
      }
    }
    .nameplate {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      padding-right: 12px;
      &.no-icon {
        padding-left: 12px;
      }
    }
  }

  .animation-icon-container {
    position: relative;
    height: 70%;
    .user-profile-pic {
      position: absolute;
      aspect-ratio: 1/1;
      z-index: 1;
      top: 24.5%;
      left: 24.6%;
      width: 51%;
    }
    .calling-gif {
      height: 100%;
    }
  }

  .user-icon-container {
    aspect-ratio: 1/1;

    .layer-1 {
      height: 100%;
      border-radius: 50%;
      background-color: @secondary-render;

      &.with-status {
        opacity: 0.6;
      }
    }
    .layer-2 {
      aspect-ratio: 1/1;
      height: 86%;
      background-color: @primary-1;
      border-radius: 50%;
    }
    .layer-3 {
      aspect-ratio: 1/1;
      height: 87%;
      background-color: #04385a;
      border-radius: 50%;
    }

    .user-profile-pic {
      height: 60%;
      border: 5px solid @panel;
      border-radius: 50%;
    }

    .user-icon {
      stroke: white;
      width: 60%;
      height: 60%;
      margin-bottom: 5%;
      border: 5px solid @panel;
      border-radius: 50%;
    }
  }

  .status {
    margin-top: 8%;
    color: @panel;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .actions-container {
    margin-top: 20px;
  }
}

/* VideoCall */
.video-call-wrapper {
  height: 100vh;

  .video-call-main-container {
    color: @panel;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 40px 15px 10px;
    background: linear-gradient(
      238.24deg,
      #054168 18.76%,
      #063757 46.07%,
      #07304b 73.39%,
      #0b2535 100.7%
    );

    .local-video,
    .remote-video {
      background: @black;
      border-radius: 19px;
      .ch-video {
        border-radius: 19px;
        object-fit: contain !important;
      }
    }

    .video-off-container {
      width: 100%;
      height: 100%;
      background: @black;
      position: relative;
      border: 2px solid @secondary-light;
      border-radius: 19px;
    }

    .video-tiles-container {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      // height: 85%;
      height: calc(100% - 140px - 10px);
      gap: 10px;

      .screen-share-container {
        height: 100%;
        width: 100%;
      }

      .video-grid {
        gap: 10px;
        background: transparent;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      .wait-message {
        color: @panel;
      }
    }

    .video-footer-container {
      // height: 15%;
      flex: 1;
      display: flex;
      min-height: fit-content;

      .custom-icon {
        fill: @panel;
        stroke: @panel;
      }

      .local-video-container {
        height: 100%;
        position: relative;
        aspect-ratio: 16 / 9;
        display: flex;
        align-items: flex-end;

        .local-video {
          width: 100% !important;
          height: 100% !important;

          .ch-video {
            transform: unset !important;
          }
        }

        .visibility-icon {
          position: absolute;
          bottom: 3px;
          right: 3px;
        }
      }

      .call-info-actions-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .call-info-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          padding: 0px 50px;
          gap: 10px;

          .call-info {
            display: flex;
            align-items: center;
            overflow: hidden;

            .event-name,
            .callee-info {
              margin-left: 20px;
              font-size: 30px;
              font-weight: 400;
              line-height: normal;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .timer-display {
            display: flex;
            align-items: center;

            .label {
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .value {
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .duration-value {
              margin-left: 5px;
            }
          }
        }

        .call-actions-container {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;
          padding: 5px 50px 0px;
          min-height: 75px;

          .container-1 {
            display: flex;
            gap: 25px;

            .end-button {
              margin-left: 32px;
            }
          }

          .container-2 {
            display: flex;
            align-items: center;
            gap: 25px;

            .count-container {
              display: flex;
              align-items: center;
              cursor: pointer;

              .contact-icon {
                height: 30px;
                width: 30px;
                padding: 5px 3px;
                path {
                  stroke-width: 1;
                }
              }
              .count {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-left: 5px;
              }
            }

            .screen-share-icon-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;

              .sub-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: @selection;
                position: absolute;
                bottom: -20px;
              }
            }

            .screen-icon {
              width: 30px;
              height: 30px;
              padding: 7px 7px 8px;
              cursor: pointer;
            }

            .settings-icon {
              width: 22px;
              height: 22px;
              cursor: pointer;
            }

            .selected {
              fill: @selection;
              stroke: @selection;
              color: @selection;
            }
          }
        }
      }
    }

    .call-options-sider {
      position: absolute;
      min-width: 350px;
      bottom: 0;
      right: 0;
      border-radius: 16px;
      background: @secondary-extra-light;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 20px 25px;
      z-index: 5;
      max-height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      .option-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        gap: 14px;
        padding: 0 10px;

        .option-title {
          color: #113E57;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 23px;
          font-family: "Euclid Circular A";
          font-style: normal;
        }
        .close-icon {
          width: 30px;
          height: 30px;
          padding: 7px;
        }
      }
    }
  }

  .call-end-container-1 {
    width: 100%;
    // margin-top: 65px;
    // border-radius: 16px;
    background: @primary;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);

    .call-end-container {
      height: 100%;
      gap: 11px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .call-end-icon {
        height: 62px;
        width: 62px;
        path {
          stroke-width: 0.5;
        }
      }

      .profile-pics-container {
        display: flex;
        gap: 11px;
        .user-profile-pic {
          height: 59px;
          width: 59px;
          stroke: white !important;
        }
      }

      .header-1 {
        color: @panel;
      }
      .body-text-1 {
        height: 49px;
        color: @panel;
      }
      .body-text-2 {
        height: 49px;
        color: @panel;
      }

      .close-button {
        .custom-icon {
          height: 11px;
          width: 11px;
        }
      }
    }
  }

  .screen-icon {
    width: 15px;
    height: 14px;
  }
  .video-icon {
    width: 18px;
    height: 13px;
  }
  .video-off-icon {
    width: 20px;
    height: 15px;
  }
  .mic-icon {
    width: 13px;
    height: 20px;
  }
  .mic-off-icon {
    width: 18px;
    height: 20px;
  }
  .volume-icon {
    width: 18px;
    height: 18px;
  }
  .volume-off-icon {
    width: 26px;
  }
}

@media (max-width: 1280px) {
  .video-call-wrapper {
    .video-call-main-container {
      min-width: max-content;
      min-height: max-content;

      .call-options-sider {
        position: relative;
      }
    }
  }
}

/* InviteParticipants */
.invite-participants-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .invite-contact-list {
    .status {
      font-size: 14px;
      font-weight: 400;
    }
    .device-offline-icon {
      width: 30px;
      height: 30px;
      padding: 7px;
    }
    &.contact-selection-list-wrapper {
      box-shadow: unset;
      background: transparent;

      .selected-header,
      .selection-list-header,
      .selected-list-container,
      .selection-list-container {
        padding: 0;
      }
    }
  }

  .invite-contact-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

/* VideoCallSettings */
.videocall-settings-wrapper {
  .divider {
    background-color: @panel-outline;
    margin: 20px 0;
  }
  .settings-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .settings-icon {
      height: 30px;
      width: 30px;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .video-settings {
    .self-view-setting {
      text-wrap: wrap;
    }
  }
  .audio-settings {
    .volume-level {
      width: calc(100% - 30px - 7px - 15px);
    }
  }
  .microphone-settings {
    margin-bottom: 20px;
  }
  .options {
    width: 90%;
  }
}

/* Override Contact Selection List Styles */
/* Selected Contact List */
.video-call-wrapper .selected-list-container {
  // background: #f9f9f9;
  border-radius: 12px;
  padding: 10px !important;
  max-height: 150px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Contact List Container */
.video-call-wrapper .contact-list-scroll {
  overflow-y: auto; 
  max-height: 350px;
  padding: 10px 0;
}