@keyframes wave-animation {
  0% {
    opacity: 1;
    transform: scaleY(1) scaleX(1);
  }
  50% {
    opacity: 0.6;
    transform: scaleY(0.9) scaleX(0.9);
  }
  100% {
    opacity: 1;
    transform: scaleY(1) scaleX(1);
  }
}

.homesight-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-container {
    width: 20%;
    display: flex;
    justify-content: center;

    .homesight-logo {
      width: 80%;
      height: auto;
      max-width: 300px;
      max-height: 300px;
      // animation: wave-animation 2s linear infinite;
    }
  }

  .loading-message {
    font-size: 24px;
  }
}
