@import "../../styles/Variables.less";

.addevent-container {
  .add-event-header {
    display: flex;
    padding: 15px;
    cursor: default;

    .hub-icon {
      display: flex;
      align-items: center;

      .hub-schedule-icon {
        fill: #005c98;
        width: 40px;
        height: 41px;
      }
    }
    .add-event-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: 700;
      color: rgba(0, 92, 152, 1);
      padding: 10px;
      line-height: 38.04px;
      text-align: left;
    }
  }
  .add-event-form-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }
  .participants-container {
    min-width: 430px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    gap: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;
    box-sizing: border-box;
    border: 1px solid #D9ECFA;
    border-radius: 16px;
    background-color: white;
  }
  .flex-column{
  .flex-row {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0 20px;
    gap: 15px;

    .custom-calendar{
     fill: #113E57;
     stroke: none;
    }

    .repeat-text{
      width: 80px;
      height: 20px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin: 7px;
    }

    .reoccuring-class {
      max-height: 820px;
    }
    .zoom-reoccuring-class{
      max-height: 1055px;
    }

    .nonreoccuring-class {
      max-height: 742px;
    }
    .zoom-nonreoccuring-class{
      max-height: 978px;
    }
  }

  .add-event-form-button-container {
    display: flex;
    gap: 10px;
  }

  .event-zoom-container {
    .header {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }

    .zoom-card {
      margin-top: 20px;
      display: flex;
      width: 23vw;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      border: 1px solid #c9c9c9;
    }
    .form-group input {
      border-radius: 8px;
      border: 1px solid #005c98;
      height: 42px;
      padding: 10px;
    }
  }
}
}

/* form container */
.form-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .optional-label {
    color: #868585;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    height: 63px;
    width: 63px;
    gap: 10px;
    padding: 10px;
  }

  .form-title {
    color: @primary-2;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
  }

  .form-card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: white;
    padding-top: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .form-row-datepicker {
    margin-left: 20px;
    .ant-form-item-control-input-content {
      flex: none;
    }
    .form-group input {
      width: inherit;
    }
  }

  .form-group {
    .ant-form-item .ant-form-item-label > label.ant-form-item-required::before {
      content: "";
    }

    .add-name {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
      gap: 10px;
      background: #ffffff;
      border: 1px solid #005c98;
      border-radius: 8px;
      width: 642px !important;
      height: 42px;
    }

    label {
      font-weight: 700;
      display: flex;
      font-size: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
    input {
      width: 310px;
      height: 45px;
      border-radius: 5px;
      border-color: #005c98;
    }
  }

  .form-description {
    min-height: 120px;
    max-height: 150px;
    border-color: rgb(0, 92, 152);
    width: 642px !important;
  }

  .form-group-select {
    width: 300px;
    border-radius: 5px;
    border-width: 2px;
    border-color: #005c98;
    .ant-select-selector {
      width: 100%;
      height: 42px;
      align-self: stretch;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      background: #ffffff;
      border: 1px solid #005c98;
      border-radius: 8px;
    }
    .ant-select-arrow {
      position: absolute;
      color: #000000;
      height: 22px;
    }
  }
}


/* Common > ContactSelectList */
.contact-selection-list-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  max-width: 410px;
  max-height: 550px;
  border-radius: 16px;
  padding: 5px 15px;

  .add-participant {   
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    align-items: center;
    padding: 10px;
    gap: 35px;

    .add-participant-label {
      color: #005D98;
      font-size: 20px;
      font-weight: 700;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
    }
  
    .remove-expanded-list {
      width: 42px;
      background:  #D9ECFA;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 38%;
      cursor: pointer;
      margin-right: -16px;

      .close-icon {        
        width: 25px;
        height: 30px;
        padding: 7px;
      }
    }
  }
  .selected-header {
    color: @primary;
    font-size: 18px;
    font-weight: 700;
    padding: 0 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    font-family: "Euclid Circular A";
    font-style: normal;
    line-height: 23px;
  }

  .contacts-divider {
    margin: 10px 10px !important;
    border: 1px solid #c9c9c9;
  }

  .selection-list-header {
    color:  #113E57;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px;
    line-height: 23px;
    font-family: "Euclid Circular A";
    font-style: normal;
  }

  .selected-list-container {
    width: 400px;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;    /* max-height: 50%; */
    border-radius: 12px;
    display: flex;
  }

  .selection-list-container {
    width: 400px;
    border-radius: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .account-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
      height: 24px;

      .username {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: #113e57;
        font-family: "Euclid Circular A";
        font-style: normal;
        align-items: center;
        display: flex;

        .username-truncate {
          display: inline-block;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: bottom;
        }
      }
    }
    .contact-search-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px;
      gap: 4px;
      .find-a-contact {
        height: 25px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 180%;
        display: flex;
        align-items: center;
        font-feature-settings: "salt" on, "ss01" on;
        color: #113e57;
      }
      .contact-input-search {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        gap: 8px;
        height: 61px;
        background: #ffffff;
        border: 1px solid #d9ecfa;
        border-radius: 16px;
        .search-input {
          border: none;
          box-shadow: none;
          background: transparent;
          padding: 0;
        }
        .search-input:focus {
          border: none;
          box-shadow: none;
          padding: 0;
        }
      }
    }
    .contact-list-scroll,
    .selected-list-container {
      overflow-y: auto;
      display: flex;
      gap: 10px;
      flex-direction: column;
      .no-filtered-contacts-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 8px;
        background: #ffffff;
        border: 1px dashed #808080;
        border-radius: 12px;
        margin: 10px auto;
        width: 100%;
        .no-contact-available {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          color: #113e57;
        }
      }
    }
  }

  /* Styling the scrollbar */
  .selected-list-container::-webkit-scrollbar,
  .contact-list-scroll::-webkit-scrollbar {
    width: 8px;
  }
  .selected-list-container::-webkit-scrollbar-thumb,
  .contact-list-scroll::-webkit-scrollbar-thumb {
    background-color: #005d98;
    border-radius: 20px; /* Rounded corners for the scrollbar thumb */
    min-height: 50px; /* Adjust this to increase the scrollbar thumb height */
    height: 50px;
  }
  .selected-list-container::-webkit-scrollbar-track,
  .contact-list-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }
  .selected-list-container::-webkit-scrollbar-thumb:hover,
  .contact-list-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #005d98;
  }

  .close-icon {
    width: 30px;
    height: 30px;
    padding: 7px;
  }
  .add-icon {
    width: 30px;
    height: 30px;
    padding: 4px;
    fill: #005c98 !important;
    stroke: #005c98 !important;
  }
}

.event_dropdown > ul {
  padding: 8px !important;
}