.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

    .page {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
    
        &.active {
        background-color: #007bff;
        color: #fff;
        }
    }

    .prev,
    .next {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
    }

    .prev.disabled,
    .next.disabled {
        cursor: not-allowed;
        color: #ccc;
    }

    .prev {
        margin-right: 10px;
    }
    
    .next {
        margin-left: 10px;
    }

    .page:hover,
    .prev:hover,
    .next:hover {
        background-color: #f8f9fa;
    }

    .page:active,
    .prev:active,
    .next:active {
        background-color: #e9ecef;
    }

    .page:focus,
    .prev:focus,
    .next:focus {
        outline: none;
    }

    .page:focus-visible,
    .prev:focus-visible,
    .next:focus-visible {
        outline: 1px solid #007bff;
    }

}
