.help-page-main-container {
  .help-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    background: #ffffff;
    height: 110px;
    .help-page-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 40px;
      gap: 15px;
      .help-page-title {
        width: 62px;
        height: 34px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 34px;
        color: #005d98;
      }
    }
  }
  .help-page-card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 40px;
    width: 510px;
    height: 408.5px;
    background: #ffffff;
    box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
    border-radius: 16px;
  }
  .help-page-content-container {
    display: flex;
    align-items: flex-start;
    padding: 40px;
    gap: 40px;
    flex-direction: row;
    .help-page-card-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
    }
    .help-page-card-header {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #005d98;
      width: 290px;
      height: 30px;
      cursor: default;
    }
    .help-page-card-information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 462px;
      height: 74px;
      cursor: default;
      .help-page-download-label {
        width: 462px;
        height: 40px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
      .help-page-click-to-download {
        width: 462px;
        height: 18px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-decoration-line: underline !important;
        color: #3fa9f5;
        cursor: pointer;
      }
    }
    .help-page-card-data-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 16px;
      .help-page-data-label {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #113e57;
      }
      .hslogo {
        border-radius: 16px;
      }
      .help-page-info-header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 0px;
        gap: 15px;
        width: 347px;
        height: 40px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        cursor: default;
      }
      .help-page-info-code {
        width: 397px;
        height: 24px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        cursor: default;
      }
      .help-page-providerpin {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        cursor: default;
      }
      .help-page-app-store-button {
        width: 180px;
        height: 54px;
      }
    }
    .help-page-about-data-container {
      gap: 20px;
      display: flex;
      flex-direction: column;

      .help-page-about-data.copyright {
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        cursor: default;
      }
      .help-page-about-data.version {
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        cursor: default;
      }
    }
  }
}
