@import "./Variables.less";
 

  .taskform-container {
    padding: -1;
    background: white;

    .taskform-card {
      // max-width: 1250px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      background: white;
      // padding: 20px;

      .task-title {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px;

        .form-title {
          color: #005D98;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 15px;
          gap: 15px;
          display: flex;
          align-items: center;
          font-family: "Euclid Circular A";
        }
      }
    }
  }

  .divider {
    background-color: @panel-outline;
  }

  .form-items-container {
    display: flex;
    flex-direction: row;
    gap: 25px;

    .column-1 {
      display: flex;
      flex-direction: column;
      // flex: 1;

      .column-1-border {
        padding: 28px;
        border-radius: 16px;
        border: 1px solid #D9ECFA;
      }
    }

    .column-2 {
      display: flex;
      flex-direction: column;
      width: 480px;
    }

    .general-info-text {
      display: flex;
      align-items: center;
      color: #113E57;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 20px;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      // flex-wrap: wrap;
      // justify-content: space-between;

      .hs-daypicker .list-item {
        background-color: #d9ecfa;
        border: 1px solid #3fa9f5;
        border-radius: 18px;
        padding: 9px;
        color: #113E57;
      }

      .list-item-selected {
        background-color: #113E57;
        border: 1px solid #113E57;
        border-radius: 18px;
        padding: 9px;
        color: #ffff;
      }
    }

    .single-datepicker-wrapper {
      height: 48px;
      width: 375px;
      border-radius: 13px;
      border-color: #CCDCE4;
      box-shadow: none;
      .single-datepicker-calendar {
        .action {          
          .ant-picker-outlined {
            height: 0px;
            width: 0px;
          }
        }
      }
    }
   
    .onetimetask {
      width: fit-content;
      height: 61px;
      border-radius: 13px;
      border-color: #D9ECFA;

      .hs-datepicker-content {
        .hs-datepicker-date {
          .hs-datepicker-calendar {
            .action {
              .ant-picker {
                width: 200px;
                height: 46px;
              }
            }
          }
        }
      }
      .hs-datepicker-wrapper .hs-datepicker-content .hs-datepicker-date .hs-datepicker-calendar .ant-picker {
        width: 200px;
        height: 46px;
      }
    }

    .reminder-text {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      color: #808080;
      margin-top: 42px;
      padding: 30px;
    }

    .ant-picker-outlined {
      height: 48px;
      width: 248px;
      border-radius: 16px;
      border-color: #CCDCE4;
    }

    label {
      font-weight: 700 !important;
      margin-bottom: 5px !important;
      display: flex !important;
      font-size: 14px !important;
      color: #113E57 !important;
    }

    .form-group {
      margin-top: 27px;

      .ant-form-item .ant-form-item-label>label.ant-form-item-required::before {
        content: "";
      }

      input {
        height: 48px;
        width: 400px;
        border-radius: 16px;
        border-color: #CCDCE4;
      }
      .ant-form-item {
        .ant-form-item-row {
          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                .zoom-url-display {
                  gap:  10px;
                  border-color: #ccdce4;
                  border-radius: 16px;
                  height: 48px;
                  display: flex;
                  align-items: center;
                  color: #113E57;
                  
                  .ant-input {
                    width: 725px ;
                  }
                  .ant-input-suffix {
                    display: flex;
                    justify-content: end;
                    align-items: center;                
                    .custom-icon  {
                      height: 20px;
                      fill: #005D98;
                      stroke: #005D98;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .form-group-select {
      width: 248px;
      border-radius: 16px;
      border-width: 2px;
      border-color: #005c98;

      .ant-select-selector {
        height: 48px;
        width: 248px;
        border-radius: 16px;
        border-color: #CCDCE4;

        .ant-select .ant-select-selection-wrap {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .ant-select .ant-select-arrow {
        color: #005D98;
        margin-top: 2px;
        font-size: 18px;
        font-weight: 1000;
      }
    }

    .manadatory-text {
      font-weight: 700;
      display: flex;
      font-size: 14px;
      color: #113E57;
    }

    .form-description {
      border-radius: 16px;
      border: 1px solid #CCDCE4;
      min-height: 159px;
    }
  }

  .taskform-action-button-container {
    display: flex;
    justify-content: end;
    gap: 14px;
    margin-top: 65px;

    .cancel-form-icon {
      border-color: #CCDCE4 !important;

      .new-button-text {
        color: #4E758E !important;
      }
    }

    .cancel-icon {
      display: flex;
      width: 24px;
      height: 24px;
      fill: #4E758E;
    }

    .disabled {
      background-color: #F5F5F5;
   
      .update-icon-disabled {
        width: 24px;
        height: 24px;
        fill:#C9C9C9;
      }
  
      .new-button-text {
        color: #C9C9C9;
      }
    }
    .primary {
      border-color: #4E758E;
   
      .update-icon-enabled {
        width: 24px;
        height: 24px;
        fill: #4E758E;  ;
        fill: #4E758E;  ;
      }
  
      .new-button-text {
        color: #4E758E;
      }
    }

    .delete-form {
      border: none;
      .new-button-text {
        color: #4E758E !important;
      }
    }

    .save-icon-enabled {
      width: 24px;
      height: 24px;
      fill: #113E57;
    }

    .save-icon-disabled {
      width: 24px;
      height: 24px;
      fill: white;
    }
  }

.task-modal-conatiner {
  .ant-modal-content {
    width: 589px;
    height: 271px;
    padding: 40px;
    align-items: center;
    text-align: center;
  
    .ant-modal-header {
      .ant-modal-title {
        font-size: 22px;
        font-weight: 700;
        color: #113E57;
        font-family: "Euclid Circular A";
      }
    }
  
    .ant-modal-body {
      .taskmodal {
        .taskmodaldescription {
          display: flex;
          margin: 15px 0px 15px 0px;
          gap: 10px;
          flex-direction: column;
          font-size: 16px;
          font-weight: 700;
          color: #113E57;

          .ant-radio-wrapper {
            color: #113E57;

            .ant-radio-checked .ant-radio-inner {
              border-color: #005D98;
              background-color: #005D98;
            }
          }
          .ant-radio-wrapper-disabled {
            color: rgba(0, 0, 0, 0.25);
          }
        }
        .taskmodalbutton {
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: end;
          margin-top: 30px;

          .new-custom-button {
            border-color: #CCDCE4 !important;
          } 
          .cancel {
            .new-button-icon {
              .cancel-icon {
                fill: #4E758E !important;
              }
            }
            .new-button-text {
                color: #4E758E;
            }
          }

         .primary {            
            .new-button-icon {
              .update-icon-enabled{
                fill: #003252 !important;
              }
            }
            .new-button-text{
              color: #003252 !important;
            }            
          }
        }
        .recurring-event-modal {
          margin-top: 20px !important;
        }
      }
    }
  }
}

.recurring-modal {
  .ant-modal-content {
    height: 290px;
  }
}

//view task

  .view-taskform-container {
    display: flex;
    align-items: center;

    .view-taskform-card {
      display: flex;
      min-width: 58rem;
      flex-direction: column;

      .view-task-decription{
        margin-top: 25px;
      }

      .view-task-title {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 30px 10px;

        .view-form-title {
          display: flex;
          align-items: center;
          font-size: 30px;
          font-weight: 700;
          gap: 20px;
          color: #005D98;
        }

        .view-task-action {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }

      .view-task-info-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 22.82px;
        text-align: left;
        color: #113E57;
      }

      .view-task-information {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 3px 0 25px;
        

        .view-task-info-data {
          display: flex;
          flex-direction: column;
          gap: 13px;
          background-color: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #D9ECFA;
          padding: 24px 16px;
          font-size: 16px;
          font-weight: 700;
          color: #113E57;

          .view-task-sub-header-info{
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            gap: 9px;

            .view-task-info-header {
              display: flex;
              flex-direction: row;
              gap: 10px;
            }
  
            .view-task-infovalue {
              font-weight: 400;
            }
          }

          
        }
      }

      .view-task-status-info {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .view-task-status-calendar {
          background-color: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #D9ECFA;
          display: flex;
          align-items: center;
          padding: 40px;
          flex-direction: column;
          gap: 20px;

          .view-task-statushead {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;

            .view-task-status {
              border-radius: 11px;
              width: auto;
              padding: 8px;
              font-size: 12px;
              font-weight: 400;
            }
          }         
        }
      }
    }
  }

.task-calendar-container {
  margin: 0 auto;

  .task-calendar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;

    .selectyearmonth {
      background-color: #FFFFFF;
      border-radius: 15px;
      border: 1px solid #D9ECFA;
      font-size: 16px;
      font-weight: 400;
      width: 160px;
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .task-calendar-date-container {
    display: flex;
    align-items: center;

    .task-calendar-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 3px;
      background-color: #FFFFFF;
      border-radius: 15px;
      border: 1px solid #D9ECFA;
      padding: 30px;
      box-sizing: border-box;
    }
  }

  .task-calendar-day-header {
    color: #0A0A0A;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .task-calendar-day {
    text-align: center;
    padding: 8px;

    border-radius: 8px;
    width: 36px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .task-date-ongoing {
    background: #F9C030;
    border-color: #F9C030 !important;
    color: #113E57;
  }
  .task-date-scheduled {
    background: #808080;
    border-color: #808080 !important;
    color: #FFFFFF;
  }
  .task-date-completed {
    background: #4BD37B;
    border-color: #4BD37B !important;
    color: #027B0E;
  }
  .task-date-expired {
    background: #F46363;
    border-color: #F46363 !important;
    color: #0A0A0A;
  }
  // .blank {
  //   background-color: #c66161;
  // }

  .today {
    border: 2px solid #005D98 !important;
    /* Blue border for today's date */
  }
}

.view-task-decription {
  .task-description {
    min-height: 187px;
    margin-top: 11px;
    background-color: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #D9ECFA;
    padding: 15px;
    font-size: 16px;
    font-family: "Euclid Circular A";
    font-weight: 400;
    line-height: 28.8px;
    height: 300px;
    overflow: auto;
  }
}

.category-subCategory-dropdown{
  display: flex;
  flex-direction: column;
}
.task-modal-container {
  z-index: 900;
}

.task-modal-container .ant-modal-content {
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-container {
background-color: #ffffff;
padding: 40px;
border-radius: 16px;
gap: 32px;
display: flex;
flex-direction: column;
align-items: center;
cursor: default;
}

/* Common > ContactSelectList */
.contact-selection-list-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  max-width: 450px;
  border-radius: 16px;
  padding: 5px 15px;
  max-height: 1025px;
  border: 1px solid #D9ECFA;
  padding-bottom: 20px;
  padding-top: 20px;
  
  .add-participant {   
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    align-items: center;
    padding: 10px;
    gap: 35px;

    .add-participant-label {
      color: #005D98;
      font-size: 20px;
      font-weight: 700;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
    }
  
    .remove-expanded-list {
      width: 42px;
      background:  #D9ECFA;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 38%;
      cursor: pointer;
      margin-right: -16px;

      .close-icon {        
        width: 25px;
        height: 30px;
        padding: 7px;
      }
    }
  }
  .selected-header {
    color: @primary;
    font-size: 18px;
    font-weight: 700;
    padding: 0 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    font-family: "Euclid Circular A";
    font-style: normal;
    line-height: 23px;
  }

  .contacts-divider {
    margin: 10px 10px !important;
    border: 1px solid #c9c9c9;
  }

  .selection-list-header {
    color:  #113E57;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px;
    line-height: 23px;
    font-family: "Euclid Circular A";
    font-style: normal;
  }

  .selected-list-container {
    width: 400px;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;    /* max-height: 50%; */
    border-radius: 12px;
    display: flex;
    border: none;
    width: auto;
  }

  .selection-list-container {
    width: 400px;
    border-radius: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .account-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
      height: 24px;

      .username {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: #113e57;
        font-family: "Euclid Circular A";
        font-style: normal;
        align-items: center;
        display: flex;

        .username-truncate {
          display: inline-block;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: bottom;
        }
      }
    }
    .contact-search-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px;
      gap: 4px;
      .find-a-contact {
        height: 25px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 180%;
        display: flex;
        align-items: center;
        font-feature-settings: "salt" on, "ss01" on;
        color: #113e57;
      }
      .contact-input-search {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        gap: 8px;
        background: #ffffff;
        height: 48px;
        width: 375px;
        border: 1px solid #CCDCE4;
        border-radius: 16px;
        .search-input {
          border: none;
          box-shadow: none;
          background: transparent;
          padding: 0;
        }
        .search-input:focus {
          border: none;
          box-shadow: none;
          padding: 0;
        }
      }
    }
    .contact-list-scroll,
    .selected-list-container {
      overflow-y: auto;
      display: flex;
      gap: 10px;
      flex-direction: column;
      .no-filtered-contacts-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 8px;
        background: #ffffff;
        border: 1px dashed #808080;
        border-radius: 12px;
        margin: 10px auto;
        width: 100%;
        .no-contact-available {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          color: #113e57;
        }
      }
    }
  }

  .close-icon {
    width: 30px;
    height: 30px;
    padding: 7px;
  }
  .add-icon {
    width: 30px;
    height: 30px;
    padding: 4px;
    fill: #005c98 !important;
    stroke: #005c98 !important;
  }
}

  /* Custom scrollbar styles */
  .task-description::-webkit-scrollbar {
    width: 12px;
    height: 50px;
  }

  .task-description::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 12px;
  }

  .task-description::-webkit-scrollbar-thumb {
    background-color: #005D98;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  .task-description::-webkit-scrollbar-thumb:hover {
    background-color: #005D98;
  }