.edit-profile-container {
  display: flex;
  height: 100%;
  // justify-content: center;
  //   align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px 15px;
  border-radius: 16px;
  left: 324px;

  .edit-profile-header {
    display: flex;
    width: 100%;
    height: 38px;
    align-items: center;
    gap: 24px;
    background-color: #f5f5f5;

    .edit-profile-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 364px;
      height: 38px;
      .edit-icon {
        fill: #113e57;
      }
      p {
        color: #113e57;
        font-size: 30px;
        line-height: 38px;
        font-weight: 700;
        font-style: normal;
        font-family: "Euclid Circular A";
      }
    }
  }
  .edit-profile-form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    background: #ffffff;
    border-radius: 16px;

    .edit-profile-info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;
      .edit-profile-photo-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 24px 16px;
        gap: 8px;

        .profile-avatar-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 22px;
          min-height: 200px;
          min-width: 200px;
          cursor: pointer;

          .profile-avatar {
            display: flex;
            width: 200px;
            height: 200px;
            box-sizing: border-box;
            border: 2px solid #005d98;
            border-radius: 50%;
          }
          .profile-avatar-border {
            border: 2px solid #005d98;
            border-radius: 50%;
          }
        }
        .emptyprofile {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          .empty-profile-container {
            display: flex;
            justify-content: center;
            border: 2px solid #c9c9c9;
            border-radius: 100%;
            width: 200px;
            gap: 22px;
            height: 200px;
            cursor: pointer;
          }
        }
        .edit-profile-edit-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 16px;
          justify-content: center;
          margin-top: 130px;
          .upload-remove-picture {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 24px;
            gap: 8px;
            background: #ffffff;
            border-radius: 32px;
            .label {
              font-family: "Euclid Circular A";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 120%;
              display: flex;
              align-items: center;
              font-feature-settings: "salt" on;
              color: #005d98;
              .edit-delete-icon {
                width: 20px;
                height: 20px;
                stroke: #005d98;
                fill: #005d98;
                margin-right: 9px;
                display: flex;
                padding: 2.667px 1.333px 1.333px 2.667px;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .upload-remove-picture.upload {
            box-sizing: border-box;
            border: 1px solid #005d98;
          }
          .label.upload {
            cursor: pointer;
          }
          .label.remove {
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
      }
      .edit-profile-general-info-label {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #005d98;
      }
      .edit-profile-content-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 24px;
        .edit-profile-form-row-title {
          width: 466px;
          height: 25px;
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          line-height: 180%;
          display: flex;
          align-items: center;
          font-feature-settings: "salt" on, "ss01" on;
          color: #113e57;

          .optional-label {
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            display: flex;
            align-items: center;
            font-feature-settings: "salt" on, "ss01" on;
            color: #113e57;
            margin-left: 5px;
          }
        }
        .edit-profile-form-row-title.generalinfo {
          font-size: 14px;
        }
        .edit-profile-form-row-title.contactingstaff {
          font-size: 16px;
        }
        .edit-profile-form-row {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 4px;
          .postal-code-info-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 8px;
            width: 466px;
            height: 25px;
            .postal-code-info-message {
              font-family: "Euclid Circular A";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 180%;
              display: flex;
              align-items: center;
              font-feature-settings: "salt" on, "ss01" on;
              color: #0a0a0a;
              margin-bottom: 46px;
            }
          }
          // Red border when validation fails
          .timezone-error-field .ant-select-selector {
            border: 1px solid #f5222d !important; /* Red error border */
          }
          // Error message style
          .error-text {
            display: flex;
            align-items: center;
            color: #0a0a0a;
            font-size: 14px;
            margin-top: 4px;
          }
          .error-icon {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          /* Change dropdown arrow to red */
          .error-select .ant-select-arrow {
            color: red;
          }
        }
        .edit-profile-authorization-form {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 24px;
          gap: 24px;
          border: 1px solid #d9ecfa;
          border-radius: 16px;

          .edit-profile-form-column {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0px;
            gap: 16px;
            margin: 0 auto;
            .contactingstaff {
              .togglelistbutton,
              .subtogglebutton {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 8px;
              }
              .subtogglelist {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0px 60px 0px 40px;
                gap: 5px;
              }
            }
          }
        }
      }
      /* 24-hour format toggle container */
      .togglebutton {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px;
        gap: 12px;
        width: 466px;
        height: 39px;
      }
      /* Switch styling  */
      .togglebutton .ant-switch,
      .subtogglebuttonprincipal .ant-switch {
        width: 41px;
        height: 23px;
        box-sizing: border-box;
        border: 1px solid #808080;
        border-radius: 220px;
        background: #C9C9C9;
      }
      /* Text next to the toggle */
      .timeformat-text {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #113e57;
        display: flex;
        align-items: center;
      }
      .togglebutton .ant-switch.ant-switch-checked,
      .subtogglebuttonprincipal .ant-switch.ant-switch-checked {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 4px;
        gap: 10px;
        width: 41px;
        height: 24px;
        background: #005d98;
        border: 1px solid #005d98;
        border-radius: 220px;
      }
      .togglebutton .ant-switch:not(.ant-switch-checked) .ant-switch-handle::before, .subtogglebuttonprincipal .ant-switch:not(.ant-switch-checked) .ant-switch-handle::before {
        background-color: #808080 !important;
        box-shadow: none !important;
      }
      .edit-profile-content-container
        .edit-profile-form-row
        .edit-profile-form-row-input-box
        .ant-input,
      .edit-profile-content-container
        .edit-profile-form-row
        .edit-profile-form-row-input-box
        .ant-select-selector {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        gap: 8px;
        width: 466px;
        height: 61px;
        background: #ffffff;
        border: 1px solid #d9ecfa;
        border-radius: 16px;
      }
      .edit-profile-form-row-input-box .ant-select-arrow {
        color: #005d98;
        font-size: 15px;
        margin: 0 auto;
        width: 24px;
        height: 24px;
      }
      .edit-profile-toggle-list-container-block {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 24px;
        gap: 24px;
        width: 956px;
        height: 224px;
        border: 1px solid #d9ecfa;
        border-radius: 16px;
      }
    }
    .edit-profile-divider {
      border: 1px solid #c9c9c9;
    }

    .edit-profile-button-container {
      display: flex;
      flex-direction: row;
      width: 988px;
      padding: 20px;
      gap: 16px;
      min-height: 76px;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
.edit-user-profile-container-overflow{
  overflow: auto;
}
