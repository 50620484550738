@import "Variables.less";

.single-datepicker-wrapper {
  display: flex;
  height: 34px;
  padding: 4px 27px 4px 0px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #FFF;
  background: #FFF;
  box-shadow: 0px 3px 6px 0px rgba(0, 92, 152, 0.16);
  width: auto;
  position: relative;

  .single-datepicker-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .single-datepicker-date {
      color: #000;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .single-datepicker-day {
        font-weight: 700;
      }

      .single-datepicker-calendar {
        .action {
          display: flex;
          align-items: center;
          margin-right: 10px; /* Space between icon and DatePicker */
          cursor: pointer;
          flex-direction: row; /* Ensure normal row direction */
        }

        .ant-picker {
          border-color: #fff;

          .ant-picker-input {
            display: none !important;  
          }
        }
      }
    }
  }
}

.calendar-icon {
  margin-right: 8px; /* Space between icon and DatePicker */
}

 .ant-picker-panel{
    background-color: #026cb8;
}

