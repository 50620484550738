.date-picker {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  font-family: "Euclid Circular A", sans-serif;
  background-color: #ffffff;
  position: absolute;
  width: 286px;
  height: auto;
  left: 497px;
  top: 215px;
  z-index: 10;

  .select-date-heading {
    box-sizing: border-box;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    gap: 10px;
    cursor: pointer;
    left: 24.25%;
    right: 30.6%;
    top: 2.82%;
    bottom: 88.03%;
    width: 121px;
    height: 26px;

    background: #000000;
    border: 1px solid #000000;
    border-radius: 30px;
    margin-left: 72px;

    .select-date-label {
      font-size: 14px;
      font-weight: 400;
      // line-height: 17.75px;
      text-align: center;
      color: #ffffff;
      width: 80px;
      height: 18px;
      margin-top: 4px;
    }
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 8px;
  gap: 10px;
  left: 7.09%;
  right: 83.21%;
  top: 25%;
  bottom: 65.85%;
  background: #c9c9c9;
  border-radius: 30px;
}

.calendar-day.selected {
  background-color: rgba(0, 0, 0, 1);
  color: white;
}

p {
  margin-top: 10px;
}
