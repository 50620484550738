/* Wellness.jsx */
.wellness-header-container {
  display: flex;
  cursor: default;
  align-items: flex-start;
  align-self: stretch;
}

.wellness-header-icon-title {
  display: flex;
}

.wellness-header-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #808080;
  margin-top: 8px;
}


.wellness-header-icon-title {
  display: flex;
}

.wellness-count {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  /* gap: 16px; */
  margin-top: 2rem;
  justify-content: space-between;
}

.wellness-actions {
  display: flex;
  align-items: center;

  .wellness-datefilter {
    display: flex;
    height: 34px;
    padding: 4px 17px 4px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 92, 152, 0.16);

    .wellness-datapicker-hide {
      display: none;
    }
    .wellness-datefilter-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .wellness-date {
        color: #000;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        .wellness-day {
          font-weight: 700;
        }
        .wellness-calendar {
          padding-left: 10px;
          .action {
            display: flex;
            gap: 10px;
          }
          .ant-picker {
            height: 34px !important;
          }
        }
      }
    }
  }
  .wellness-divider {
    width: 1px;
    height: 42px;
    background: #d9d9d9;
  }
}

.wellness-count-title >label {
  width: 76px;
  height: 25px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #005d98;
}

.wellness-item-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wellness-item-header-support-device-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.wellness-item-header-support-device-btn > label {
  width: 144px;
  height: 19px;
  cursor: pointer;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #005d98;
}

.wellness-item-header-view-history-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  width: 176px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #005d98;
  border-radius: 32px;
  cursor: pointer;
}

.wellness-item-header-view-history-btn label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #005d98;
  cursor: pointer;
}

.wellness-item-content {
  display: flex;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  width: auto;

}

.wellness-item-content-without-shawdow{
  display: flex;
  padding: 5px 28px;
  gap: 24px;
  background: #ffffff;
  border-radius: 16px;
  width: auto;
  justify-content: space-between;
}

.wellness-item-content-divider{
  margin-left: 17%;
}

.wellness-item-content-divider-without-shadow{
  padding-left: 3rem;
}

.wellness-item-content-day-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #113e57;
  margin-left: 10%;
  max-width: 32px;
}


.wellness-item-content-statistic {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
  min-width: 35%;
  margin-left: 5px;
}

.wellness-item-content-device {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 1rem 3rem;
}

.wellness-item-content-device-model-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
}


.wellness-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  gap: 1rem;
  padding-bottom: 2rem;
}

.wellness-item-with-history {
  width: 100%;
  box-sizing: border-box;
  cursor: default;
  white-space: nowrap;
}

.wellness-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -23px;
}

.wellness-item-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.wellness-item-header-title label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #005d98;
  margin-left: -30px;
}

.wellness-item-header-title-glucose-meter{
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: 10px;
  justify-content: center;
}
.wellness-item-header-title-glucose-meter label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #005d98;
  margin-left: -30px;
}

.wellness-item-header-actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.wellness-item-header-support-device-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.wellness-item-header-support-device-btn label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-decoration: underline;
  color: #005d98;
}

.wellness-item-content-header {
  display: flex;
  align-items: center;
  min-width: 31%;
}

.wellness-item-content-time-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
  margin-left: 3%;
}


.wellness-item-content-statistic-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-width: 18%;
}

.wellness-item-content-statistic-info-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.wellness-item-content-statistic-info-value {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #005d98;
}

.wellness-item-content-device-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
}

.wellness-item-content-device-model-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #113e57;
} 

.wellness-item-content-device-model-title{
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #005d98;
  margin-right: 10px;
  min-width: 160px;
  display: flex;
  align-items: center;
}

.wellness-item-content-device-model-model{
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113E57;
  margin-left: 10px;
}

.wellness-modal-container .ant-modal-content {
  max-height: 50vh; /* You can use `vh` to make it responsive based on viewport height */
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
}

.wellness-header-icon-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.wellness-header-icon {
  display: flex;
 
  padding: 0px;

  isolation: isolate;
  margin-bottom: 25px;
}

.close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 32px;
  cursor: pointer;
  justify-content: center;
  background: #d9ecfa;
}

.wellness-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  order: 1;
  align-self: stretch;
}

// ##############

.wellness-page-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #d9ecfa;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  align-self: stretch;
}

.horizontal-divider {
  width: 99%; /* Same width as the SVG */
  height: 1px;  /* Matches the line height of 1 */
  background-color: #C9C9C9; /* Matches the stroke color of the SVG */
  margin: 20px auto; /* Center align the line horizontally (optional) */
}

.table-data-container {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 8px;
}

.wellness-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.wellness-tabs .ant-tabs-tab {
  font-family: "Euclid Circular A";
  font-weight: 700;
  font-size: 20px;
  color: #808080;
}

.wellness-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #113e57 !important;
  font-weight: 600;
}

.wellness-tabs .ant-tabs-ink-bar {
  background-color: #113e57;
  height: 3px;
}


.modal-table-data-history-container::-webkit-scrollbar {
  width: 15px;
}

.modal-table-data-history-container::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.modal-table-data-history-container::-webkit-scrollbar-thumb {
  background-color: #005d98;
  border-radius: 10px;
  border: 3px solid #f0f0f0;
}

.modal-table-data-history-container::-webkit-scrollbar-thumb:hover {
  background-color: #004075;
}

.modal-table-data-history-container::-webkit-scrollbar-button {
  display: none;
}

.wellness-tabs .ant-tabs-nav::before {
  border: none;
}

.wellness-tabs .ant-tabs-ink-bar {
  background-color: #113e57;
  height: 20px;
}


.modal-wellness-item-content-header {
  display: flex;
  min-width: 28%;
}


.modal-wellness-item-content-day-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #113e57;
  margin-left: 10%;
  max-width: 50px;
}

.modal-wellness-item-content-divider{
  margin-left: 15%;
}

.modal-wellness-item-content-time-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
  margin-left: 3%;
}


.modal-wellness-item-content-statistic-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-width: 18%;
}

.modal-wellness-item-content-statistic-info-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.tab-content > .no_data{
  display: flex;
  justify-content: center;
}

.modal-wellness-item-content-statistic-info-value {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #005d98;
}

.modal-wellness-item-content-device {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 1rem 5rem;
}

.modal-wellness-item-content-device-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
}

.modal-wellness-item-content-device-model-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
}


.modal-wellness-item-content-device-model-title{
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #005d98;
  margin-right: 10px;
}

.modal-wellness-item-content-device-model-model{
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #113E57;
  margin-left: 10px;
}

.modal-wellness-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  order: 1;
  align-self: stretch;
}

.modal-horizontal-divider {
  width: 99%; /* Same width as the SVG */
  height: 1px;  /* Matches the line height of 1 */
  background-color: #C9C9C9; /* Matches the stroke color of the SVG */
  margin: 20px auto; /* Center align the line horizontally (optional) */
}

.wellness-modal-container .ant-modal-content {
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
}

.wellness-modal-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 31rem;
  white-space: nowrap;
  align-self: stretch;
}

.wellness-header-icon-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.wellness-header-icon {
  display: flex;
  align-items: center;
}

.wellness-header-title {
  color: #113e57;
  font-family: "Arial";
  font-size: 20px;
  font-weight: 700;
  line-height: 25.36px;
  cursor: default;
}

.wellness-supported-device-header-title {
  color: #005d98;
  font-family: "Arial";
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  cursor: default;
}

.wellness-support-device-header-sub-title{
  color: #113e57;
  font-family: "Arial";
  font-size: 18px;
  font-weight: 400;
  line-height: 22.82px;
  margin-top: 10px;
}

.close-button {
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 32px;
  cursor: pointer;
  background: #d9ecfa;
  align-items: center;
}

.wellness-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.wellness-tabs .ant-tabs-tab {
  font-family: "Euclid Circular A";
  font-weight: 700;
  font-size: 20px;
  color: #808080;
}

.wellness-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #113e57;
  font-weight: 600;
}

.wellness-tabs .ant-tabs-ink-bar {
  background-color: #113e57;
  height: 3px;
}

.tab-content {
  width: 100%;
  margin-top: 2rem;
}

.modal-wellness-item-content,
.modal-wellness-insight_chart-content {
  display: flex;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  gap: 16px;
  width: 87rem;
  white-space: nowrap;
  padding-left: 12px;
}

.modal-wellness-list-item-content {
  display: flex;
  background: #ffffff;
  gap: 25px;
  width: 100%;
  white-space: nowrap;
}

.modal-wellness-item-content-statistic {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  min-width: 40%;
  justify-content: center;

}

.modal-wellness-item-content-day-info,
.modal-wellness-item-content-time-info,
.modal-wellness-item-content-device-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  color: #113e57;
}

.modal-wellness-item-content-statistic-info-value,
.modal-wellness-item-content-device-model-title {
  font-weight: 700;
  font-size: 20px;
  color: #005d98;
}

.vertical-divider {
  display: flex;
    width: 1px;
    height: 50px;
    background-color: #c9c9c9;
    margin-top: 23px;
}

.modal-horizontal-divider {
  width: 99%;
  height: 1px;
  background-color: #c9c9c9;
  margin: 20px auto;
}

.modal-table-data-history-container {
  display: flex;
  max-height: 40vh;
  overflow-y: auto; 
  flex-direction: column;
  /* padding-right: 8px; */
  min-width: -webkit-fill-available;

}

.modal-wellness-item-content-history-statistic{
  display: flex;
  max-height: 40vh;
  /* overflow-y: auto; */
  flex-direction: column;
  padding-right: 8px;
  min-width: -webkit-fill-available;
}

/* Wellness.less */
.modal-insight-chart-tab-content {
  width: 100%;
  margin-top: 2rem;
}

.modal-insight-chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-insight-chart-summary-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  width: 80rem;
}

.modal-insight-chart-summary-item-value-header{
  display: flex;
  padding: 5px 55px;
  width: -webkit-fill-available;
}

.modal-insight-chart-summary-item-value{
      display: flex;
      padding: 5px 55px;
      width: -webkit-fill-available;
      border: 1px solid #D9ECFA;
      border-radius: 8px;
}

.modal-insight-chart-summary-title {
  font-family: "Euclid Circular A";
  font-weight: 700;
  font-size: 18px;
  color: #113e57;
  margin-bottom: 16px;
}

.modal-insight-chart-summary-row {
  display: flex;
  flex-direction: column;
  gap:8px;
}

.modal-insight-chart-summary-item-label{
  display: flex;
  min-width: 15%;
  align-items: center;
}

.modal-insight-chart-summary-item {
  display: flex;
  // flex-direction: column;
  gap: 8px;
}

.modal-insight-chart-label {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #808080;
}

.modal-insight-chart-value {
    color: #005D98;
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

.modal-insight-chart-value.avg {
    color: #005D98;
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    min-width: 44%;
}

.modal-insight-chart-value.avg>label {
  color: #113e57;
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}


.modal-insight-chart-value.avg-header {
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;

  color: #808080;

}

.modal-insight-chart-value.low {
  color: #005D98;
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    min-width: 42%;
}
.modal-insight-chart-value.low-header {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;
    margin-left: 24rem;
    color: #808080;

}

.modal-insight-chart-value.high {
  // color: #005d98;
}

.modal-insight-chart-value.high-header {
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-left: 22.8rem;
  color: #808080;

}

.modal-insight-chart-chart-card {
  padding: 20px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  width: 100%; /* Set the width to 100% */
  height: 33vh; /* You can adjust this height as needed */
}

.recharts-tooltip-label {
  font-family: "Euclid Circular A";
  font-size: 14px;
  color: #113e57;
}

.recharts-cartesian-grid-line {
  stroke: #e8e8e8;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #e8e8e8;
}

.recharts-default-legend {
  font-family: "Euclid Circular A";
  font-weight: 500;
  font-size: 14px;
  color: #113e57;
}


.date-arrow {
  cursor: pointer;
  font-size: 16px;    // Adjust icon size
  color: #173f5f;     // Dark teal color from your screenshot
  font-weight: 900;   // Increases line thickness slightly
  margin: 0 10px;
  &:hover {
    color: #40a9ff;
}
}

.note-box {
  width: -webkit-fill-available;
}

.close-button {
  cursor: pointer;
}

/* Custom Table Styling */
.custom-table {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .table-header-row {
    display: flex;
    justify-content: space-between;
    gap: 16.5em;
    font-weight: bold;
    font-size: 14px;
    color: #003366;
    margin-right: 33rem;
  }
  .table-body {
    border-top: 1px solid #C9C9C9;
    margin-top: 20px;
    .table-row {
      display: flex;
      gap: 9em;
      font-weight: bold;
      font-size: 14px;
      color: #003366;
      margin-right: 33rem;
      padding: 15px 0px 10px 2px;
      white-space: nowrap;
    }
  }
}

.table-header,
.table-cell {
  > span {
    flex: 1;
    line-height: 20.29px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
  }
}

.table-cell {
  &:nth-child(1) {
    color: #005d98;
    min-width: 30%;
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
    line-height: 22.82px;
    font-family: "Euclid Circular A";
  }
  &:nth-child(2) {
    min-width: 25%;
    font-family: "Euclid Circular A";
    font-weight: 400;
    font-size: 16px;
    line-height: 20.29px;
  }
}
