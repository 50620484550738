// Roles.jsx

/* Container for the roles list */
.roles-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  /* individual role cards */
  .role-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    background: #f5f5f5;
    border-radius: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    width: 293px;
    height: 308px;
    cursor: pointer;

    /* Content container inside the role card */
    .content-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;

      /* Header content inside the card */
      .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;

        /* Role label text inside the card(Social,care,manage,staff,staffadmin) */
        .role-label {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #113e57;
        }
      }
      /* Role description inside the role card */
      .role-description {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #113e57;
      }
    }
  }
}
/* Container for roles in a row */
.roles-row {
  gap: 24px;
  display: flex;
  width: 100%;

  /* Privileges container inside the roles */
  .privileges-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    /* Style for individual privilege */
    .privileges-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;

      /* Check icon inside the privilege */
      .check-icon {
        color: #005d98;
        border-radius: 0.5px;
        width: 28px;
        height: 27px;
      }

      /* privilege display label */
      .privilege-display-label {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #113e57;
      }
    }
  }
}

/* Icon inside the role card(Social,manage,care) */

.roles-icon {
  width: 36px;
  height: 36px;
  fill: #113e57;
  stroke: #113e57;
}

/* Warning text that appears when no role is selected */
.warning-text {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.29px;
  display: flex;
  align-items: center;
  color: #f46363;
  flex: none;
  order: 3;
  flex-grow: 0;
  padding-top: 20px;
}

/* Container for the "Selected" label and icon */
.selected-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  gap: 8px;
  position: absolute;
  width: 116px;
  height: 36px;
  left: 206.17px;
  top: -16px;
  background: #4bd37b;
  border-radius: 32px;
  z-index: 1;

  /* Style for the "Selected" label */
  .selected-label {
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  /* Selected label icon */
  .selected-icon {
    width: 20px;
    height: 20px;
    fill: #000000;
    stroke: #000000;
  }
}

/* if role card is selected*/
.role-card.selected {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  isolation: isolate;
  width: 313.33px;
  height: 308px;
  background: #d9ecfa;
  border: 2px solid #3fa9f5;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}
