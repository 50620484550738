@import "./Variables.less";

/* Tobe removed later for new UI design */
.form-header-container {
  display: flex;
  padding: 20px 50px 0px 50px;
  gap: 15px;
  background: white;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .form-item-container {
    display: flex;
    font-style: normal;
    line-height: normal;
    gap: 20px;
    .ant-form-item {
      display: flex;
      height: 72px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
    }
    label {
      font-size: 16px;
      font-weight: 700;
      height: inherit;
    }
    .ant-form-item .ant-form-item-label > label.ant-form-item-required::before {
      content: "";
    }
    input {
      display: flex;
      height: 42px;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid #005c98;
      background: #fff;
    }
    .select-type {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      height: 42px;
      width: 240px;
    }
  }
  // .form-button-container {
  //   display: flex;
  //   gap: 20px;
  // }
}

.add-event-form-button-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 30px 24px 8px 44px;
}

.cancelmeeting {
  display: flex;
  padding: 50px 50px 0px 50px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px;

  .cancelform {
    color: @primary;
    padding: 5px;

    h4 {
      margin: 18px 0px;
      font-size: 20px;
      font-weight: 1200;
    }

    .cancel-icon {
      fill: #005c98;
      stroke: #005c98;
    }
  }

  .form-button-cancel {
    display: flex;
    gap: 20px;

    .hsbuttons {
      font-size: 10px;
      padding: 6px 4px;
    }
  }
}

.content-wrapper {
  height: 100vh;
  background: #f7f7f7;
}

.event_dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      height: 61px;     
      color: #113E57;
      font-size: 16px;
      font-weight: 700;
      border-radius: 10px;
      min-width: 180px;   
    }
    .ant-dropdown-menu-item:hover {
      background-color: #D9ECFA !important;
    }
  }
}
/* MainPage */
.mainpage-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: @background;

  .mainpage-header-container {
    height: @header-height;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: @primary;
    color: @panel;
    box-shadow: 5px 5px 2px 0px rgba(0, 92, 152, 0.15);

    .custom-icon {
      fill: @panel;
      stroke: @panel;
    }

    .logo-bread-container {
      display: flex;
      align-items: center;

      .logo-divider {
        height: 42px;
        margin: 0 25px;
        background-color: @panel;
      }
      .logo-container {
        height: 54px;
        margin-left: 7px;
        .full-logo {
          height: inherit;
        }
      }
    }

    .header-actions-container {
      display: flex;
      align-items: center;
      gap: 25px;

      .action {
        display: flex;
        cursor: pointer;
      }

      .logout-icon {
        transform: rotate(180deg);
      }
    }
  }

  .mainpage-sider {
    background: #ffffff;
    // box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #d9ecfa;
    // z-index: 2;
  }

  .mainpage-body-container {
    background: @background;
    width: 100%;
    height: 100%;
    min-width: fit-content;
  }

  .mainpageaddaccount-body-container {
    background: @background;
    width: auto;
    height: auto;
  }

  .main-page-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: @footer-height;
    text-align: center;
    padding: 3px 6px;
    font-size: 8px;
    color: rgba(0, 0, 0, 0.45);
    background-color: #e4e4e4;
  }
}

/* MainSider */
.new-ui-mainsider-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background: #d9ecfa;
  width: 324px;
  gap: 10px;
  padding: 15px 0px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  // position: fixed;

  .new-ui-main-logo-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .empty-logo-div {
      margin: auto;
    }
  }
  .new-ui-top-container {
    width: 324px;
  }

  .new-ui-bottom-container {
    width: 324px;
  }

  .new-ui-logout-gear-notification-help-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .new-ui-help-container.active-icon .action {
    fill: #3fa9f5;
    stroke: #3fa9f5;
  }

  .new-ui-gear-container.active-icon .action {
    fill: #3fa9f5;
    stroke: #3fa9f5;
  }

  .new-ui-gear-container.inactive-icon .action {
    fill: #113e57;
    stroke: #113e57;
  }

  .new-ui-help-container.inactive-icon .action {
    fill: #113e57;
    stroke: #113e57;
  }

  .new-ui-provider {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #113e57;
    margin-bottom: 20px;
    width: 324px;
    height: 23px;
    padding: 5px;
  }

  .new-ui-provider-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ffffff;
    gap: 0px;
  }

  .new-ui-logout-container:hover {
    cursor: pointer;
  }

  .new-ui-provider-name-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #113e57;
    margin-bottom: 20px;
    padding: 5px;
    width: 324px;
    height: 23px;
    align-items: center;
    cursor: default;
  }

  .new-ui-userinfo-container {
    display: flex;
    border-width: 0px;
    border-style: solid;
    border-color: #c9c9c9;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    cursor: default;
    gap: 16px;

    .new-ui-image-container {
      display: flex;
      border-width: 0px;
      border-style: solid;
      border-color: #c9c9c9;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      cursor: default;
      gap: 16px;
      .new-ui-user-profile-pic.img {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        border: 2px solid #005D98;
        border-radius: 56px;
      }
      .new-ui-user-profile-pic.svg {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        border-radius: 56px;
      }
      new-ui-img {
        border: 2px solid #c9c9c9;
        border-radius: 100%;
      }
    }
    .new-ui-accountPrincipalSocialRole {
      display: none;
    }

    .new-ui-info-container {
      justify-content: center;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;

      .new-ui-name {
        font-size: 22px;
        font-weight: 700;
        color: @primary;
        white-space: break-spaces;
        text-overflow: ellipsis;
        line-height: 30px;
        text-align: center;
        font-family: "Euclid Circular A";
        font-style: normal;
      }
      .new-ui-type {
        color: @labels;
        background: @alert-panel;
        margin-top: 10px;
      }
      .new-ui-permission-level{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        .main-sider-permission-level-container{
          display: flex;
          flex-direction: row;
          align-items: center;
          .shield-icon{
            width: 24px;
            height: 24px;
          }
          .staff-admin-shield{
            fill : #1C1C1C;
          }
          .staff-shield{
            fill: #FFFFFF;
          }
        }
        .new-ui-staffAdminColor {
          border-radius: 32px;
          width: fit-content;
          padding: 4px 8px;
          background: #FFDEA1;
          margin-top: 5px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          display: flex;
          .new-ui-staffAdminColor-label{
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #1C1C1C;
          margin: 3px;
          }
        }
        .new-ui-staffColor {
          border-radius: 32px;
          width: fit-content;
          padding: 4px 12px;
          background: #3fa9f5;
          margin-top: 5px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .new-ui-staff-label{
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin: 3px;
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            }
        }
        .new-ui-accountPrincipalSocialRole {
          visibility: hidden;
          border-radius: 32px;
          width: fit-content;
          padding: 4px 12px;
          margin-top: 5px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .new-ui-staffAdminColor-label{
          visibility: hidden;
          }
        }
      }
    }

    &.new-ui-profile-active {
      background-color: @primary;
      .user-profile-pic {
        stroke: white !important;
      }
      .new-ui-menu-arrow,
      .new-ui-usernamecontainer .new-ui-name,
      .new-ui-provider {
        color: @panel;
      }
    }
  }

  .new-ui-center {
    text-align: center;
    // padding: 25px;
  }

  .new-ui-menu-container {
    .new-ui-menu-item {
      display: flex;
      flex-direction: column;
      // margin-top: 10px;
      color: @primary;
      padding: 18px 20px 18px 20px;
      gap: 0px;
      border-bottom: 1px solid #ffffff;
      &:new-ui-hover {
        cursor: pointer;
        background: @panel-outline;
      }

      &.new-ui-active-menu-item {
        background-color: @primary;
        border-color: @primary;

        &:new-ui-hover {
          filter: brightness(120%);
        }
        .new-ui-menu-arrow {
          color: @panel;
        }
        .new-ui-menu-item-header {
          color: #ffffff;
          .new-ui-menu-icon-label-container {
            .new-ui-menu-icon {
              .new-ui-icon {
                fill: @panel;
                stroke: @panel;
              }
            }
          }
        }

        .new-ui-menu-item-body {
          color: #ffffff;
        }

        .submenu-container {
          color: #113e57;
          flex-direction: column;
          align-items: center;
          padding: 12px;
          gap: 12px;
          background: #d9ecfa;
          border-radius: 12px;
          .submenu-account-selected-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px;
            gap: 8px;
            background: #ffffff;
            border-radius: 999px;
            height: 75px;
            cursor: default;
            .submenu-account-selected-content {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0px;
              gap: 12px;
              .submenu-profile-container {
                width: 56px;
                height: 56px;
              }
              .submenu-profile-container.image {
                box-sizing: border-box;
                border: 2px solid #005d98;
                border-radius: 56px;
              }
              .submenu-profile-name-role-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0px;
                gap: 8px;
                width: 184px;
                height: 59px;
                .submenu-profile-name-block {
                  .name-label {
                    font-family: "Euclid Circular A";
                    font-family: "Euclid Circular A";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #113e57;
                    display: flex;
                    width: 184px;
                    height: 19px;
                  }
                  .role-label {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 8px;
                    background: #ffdea1;
                    border-radius: 32px;
                    font-family: "Euclid Circular A";
                    font-family: "Euclid Circular A";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    color: #1c1c1c;
                    gap : 6px;
                    min-width: 54px;
                    max-width: 141px;
                    height: 32px;
                  }
                }
              }
            }
          }

          .submenu-container-items {
            margin-top: 15px;
            .submenu-icon-label-container {
              display: flex;
              align-items: center;
              gap: 10px;
              height: 35px;
              border-radius: 10px;

            .sub-menu-icon {
              margin-left: 8px;
              .calendar-icon {
                width: 26px;
                height: 25px;
                fill: #113e57;
              }

              .contact-icon {
                width: 26px;
                height: 25px;
                stroke: #113e57;
              }

              .call-icon {
                width: 26px;
                height: 25px;
                stroke: #113e57;
              }
            }
          }

          .active {
            background: #113e57;
            border-radius: 6px;

            .active-calendar-icon {
              fill: white !important;
              stroke: none;
            }
            .active-contact-icon {
              fill: white !important;
              width: 30px;
              height: 30px;
            }
            .active-call-icon {
              fill: white !important;
              stroke: white !important;
              width: 30px;
              height: 25px;
            }
            .active-icon {
              fill: white !important;
              stroke: none;
              width: 30px;
              height: 25px;
            }
            .active-label {
              color: white !important;
            }

            .inactive-calendar-icon {
              fill: #113e57 !important;
            }
            .inactive-contact-icon {
              fill: #113e57 !important;
              width: 30px;
              height: 30px;
            }
            .inactive-call-icon {
              fill: #113e57 !important;
              stroke: #113e57 !important;
              width: 30px;
              height: 25px;
            }
            .inactive-icon {
              fill: #113e57 !important;
              stroke: none;
              width: 30px;
              height: 25px;
            }
          }

          .inactive {
            .inactive-calendar-icon {
              fill: #113e57 !important;
              stroke: none;
            }
            .inactive-contact-icon {
              fill: #113e57 !important;
              width: 30px;
              height: 30px;
            }
            .inactive-call-icon {
              fill: #113e57 !important;
              stroke: #113e57 !important;
              width: 30px;
              height: 25px;
            }
            .inactive-icon {
              fill: #113e57 !important;
              stroke: none;
              width: 30px;
              height: 25px;
            }
            .inactive-label {
              color: #113e57 !important;
            }
            }
          }
        }
      }

      .new-ui-menu-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;

        .new-ui-menu-icon-label-container {
          display: flex;
          align-items: center;
          .new-ui-menu-icon {
            font-size: 30px;
            .new-ui-icon {
              fill: @primary;
              stroke: @primary;
            }
          }
          .new-ui-menu-label {
            font-size: 18px;
            margin-left: 12px;
          }
        }

        .new-ui-menu-count-container {
          display: flex;
          align-items: center;
          .new-ui-menu-count {
            font-size: 18px;
            margin-right: 10px;
          }
        }
      }

      .new-ui-menu-item-body {
        display: flex;
        flex-direction: column;
        margin: 10px 0px;
        font-weight: 700;
        gap: 5px;

        .new-ui-body-row-1 {
          display: flex;
          justify-content: space-between;
          line-height: normal;
          .new-ui-label {
            font-size: 24px;
          }
          .new-ui-status {
            color: #ffffff;
            padding: 5px 20px;
            font-size: 14px;
            border-radius: 24px;
            background: #3fa9f5;
          }
        }
        .new-ui-body-row-2 {
          line-height: normal;
          margin-top: 5px;
          .new-ui-label {
            font-size: 16px;
            font-weight: 400px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 22rem;
          }
        }
      }
    }
  }
}

/* UserHubModal */
.userhubmodalcall {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  padding: 35px;

  p {
    color: #005c98;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    line-height: normal;
  }
  .devicestatus {
    font-weight: 900;
    font-size: 25px;
  }
  .usercustomdivider {
    width: 60% !important;
    border-bottom: 2px solid #005c98;
    margin: 0 auto;
    margin-top: 10px;
  }
}

/* UserHubs */
@media (max-width: 1500px) {
  .userhubs-wrapper {
    min-width: max-content;
  }  
}
.userhubs-wrapper {
  display: flex;
  flex-direction: column;

  .hub-list-container {
    .hub-container {
      justify-content: space-between;

      .container-1 {
        display: flex;
        align-items: center;
        min-width: 30%;
        cursor: default;
        // flex: 1;
      }
      .container-2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .usertype-container {
        display: flex;
        align-items: center;
        margin-right: 4px;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #FFA500;
        background: #FFDEA1;
        border-radius: 30px;        
        padding: 6px 20px;
        margin-top: 5px;

        .manage-icon {
          width: 22px;
          height: 22px;
        }

        .type-text {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 700;
          line-height: 20.29px;
          text-align: left;
          height: 20px;
          color: #113E57;
          font-family: "Euclid Circular A";
        }
      }

      .user-info-container {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 15px;
        cursor: pointer;

        .image-container {
          display: flex;
          width: 59px;
          height: 59px;

          img {
            border: 2px solid #005D98;
            border-radius: 100%;
            border-width: 0px, 0px, 0px, 0px;
            border-style: solid;
            width: 59px;
            height: 59px;
          }

          .user-profile-pic {
            width: 59px;
            height: 59px;
          }
        }

        .info-container {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          color: #113E57;
          // height: 34px;
          width: 410px;
          // overflow: hidden;

          .username {
            font-size: 16px;
            font-weight: 400;
            // overflow: hidden;
            white-space: nowrap;
            // text-overflow: ellipsis;
          }
          .relation {
            font-size: 12px;
            font-weight: 400;
            margin-top: 4px;
          }
        }
      }

      .association-count-container {
        cursor: pointer;
        color: #000000;
        gap: 10px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20.29px;
        text-align: left;
      }

      .device-icon-container {
        width: 60px;
        justify-content: space-between;
        margin-left: 5px;
        margin-right: 5px;
      }

      .staff-add-icon-container {
        gap: 10px;
        .staff-add-icon {
          cursor: pointer;
        }
      }

      .actions-container {
        .auto-answer-icon {
          padding: 3px;
          path {
            stroke-width: 3;
          }
        }
      }
    }
  }
}

/* HubDetails */
.hub-details-wrapper {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  .hub-header-container {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    gap: 16px;
    background: @panel;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: auto;

    .hub-header-info-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 10px;
      gap: 16px;
      .hub-header-image-container {
        .hub-header-user-profile-pic.image {
          width: 80px;
          height: 80px;
          box-sizing: border-box;
          border: 2px solid #005d98;
          border-radius: 56px;
        }
        .hub-header-user-profile-pic.svg {
          width: 80px;
          height: 80px;
        }
        .yellow-border {
          width: 80px;
          height: 80px;
          box-sizing: border-box;
          border: 2px solid #f9c030;
          border-radius: 56px;
        }
        .incomplete-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 2px 6px;
          gap: 8px;
          background: #f9c030;
          border-radius: 99px;
          position: absolute;
          margin-top: -20px;
          height: 26px;
          width: 78px;

          .incomplete-text {
            width: 66px;
            height: 15px;
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #0a0a0a;
          }
        }
      }
      .hub-header-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        .hub-header-user-name {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #113e57;
        }
        .hub-header-user-role-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px 8px;
          gap: 6px;
          .hub-header-user-role-locale {
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #113e57;
            height: 18px;
          }
          .user-role-timezone {
            height: 18px;
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #113e57;
            min-width: 186px;
          }
        }
        .hub-header-edit-profile-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          gap: 8px;
          width: 165px;
          height: 56px;
          border-radius: 32px;
          cursor: pointer;
          .hub-edit-icon {
            width: 24px;
            max-width: 32px;
            height: 24px;
            max-height: 32px;
            fill: #d9ecfa;
          }
          .hub-header-edit-profile-label {
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            display: flex;
            align-items: center;
            text-decoration-line: underline;
            font-feature-settings: "salt" on;
            color: #005d98;
          }
        }
      }
    }

    .hub-action-device-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 120px;
      gap: 16px;

      .header-action-buttons {
        display: flex;
        margin-left: 50px;
        flex-direction: row;
        align-items: center;
        padding: 5px 0px;
        gap: 8px;
        .action {
          margin-left: 15px;
          &:not(:disabled) {
            cursor: pointer;
          }
        }
      }
    }

    .header-device-info-container {
      display: flex;
      align-items: center;

      .header-device-description-container {
        // margin:auto;
        // padding:"50px";
        width: 106px;
        height: 61px;
        margin: 24.5px;
        margin-left: 0px;
        color: rgba(0, 93, 152, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: default;
        line-height: 20px;
        gap:2px;

        .title-1 {
          font-family: "Euclid Circular A";
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          color: #005D98;
        }

        .title-2, .title-3 {
          font-family: "Euclid Circular A";
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: #005D98;
          font-style: normal;
        }
      }
    }
  }

  .hub-content-container {
    flex: 1;
    display: flex;
    background: @background;
    overflow-y: auto;
    width: auto;

    .hubnewui-content-container {
      width: 100%;

      .hubnewui-schedule-container {
        height: 100%;
        .list-schedule-container-newui {
          border-radius: 0px;
          box-shadow: none;
          padding: 15px;
          .schedule-header-newui {
            background-color: #f5f5f5;
          }
        }
      }

      .hubnewui-contacts-container {
        .custom-page-wrapper {
          padding: 0px;
          .page-wrapper {
            border-radius: 0px;
            .contacts-table {
              overflow-y: hidden;
            }
          }
        }
      }

      .hubnewui-notifications-container {
        .custom-page-wrapper {
          padding: 0px;

          .page-wrapper {
            padding-top: 30px;
            .notifications-table {
              overflow-y: hidden;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .hub-details-wrapper {
    min-width: 950px;
  }
}

/* ListCallActivity */
.list-callactivity-container {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 128, 255, 0.2);
  display: flex;
  flex-direction: column;

  .callactivity-content {
    flex: 1;
  }

  .callactivity-header {
    display: flex;
    padding: 20px 50px 8px 30px;
    gap: 15px;
    height: 66px;
    background: #f5f5f5;
    flex-wrap: wrap;
    cursor: default;

    .callactivity-title {
      display: flex;
      gap: 10px;

      .callactivity-icon-newui {
        width: 38px;
        height: 36px;
        fill: #005c98 !important;
        stroke: #005c98 !important;
      }

      .callactivity-text-newui {
        color: #005c98;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .callactivity-list-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: #f5f5f5;

    .callactivity-actions {
      display: flex;
      padding: 10px 50px 20px 50px;
      align-items: center;
      gap: 15px;
      margin-left: 80px;

      .callactivity-datefilter {
        display: flex;
        height: 34px;
        padding: 4px 17px 4px 20px;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        border: 1px solid #fff;
        background: #fff;
        box-shadow: 0px 3px 6px 0px rgba(0, 92, 152, 0.16);

        .callactivity-datapicker-hide {
          display: none;
        }
        .callactivity-datefilter-content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .callactivity-date {
            color: #000;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.3px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            .callactivity-day {
              font-weight: 700;
            }
            .callactivity-calendar {
              padding-left: 10px;
              .action {
                display: flex;
                gap: 10px;
              }
              .ant-picker {
                height: 34px !important;
              }
            }
          }
        }
      }
      .callactivity-divider {
        width: 1px;
        height: 42px;
        background: #d9d9d9;
      }
    }

    .skeleton-container {
      height: 68px;
    }
    .callactivity-list-date {
      height: 40px;
      background: #f5f5f5;
      color: #6087a0;
      margin-bottom: 11px;
      .callactivity-date {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 10px;
        padding-left: 20px;
      }
    }
    .callactivity-list-today {
      background: #f5f5f5;
      color: #005c98;
    }

    .callactivite-data-list {
      margin-bottom: 50px;

      .callactivity-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: @panel;
        border-radius: 16px;
        box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
        margin-bottom: 11px;
        padding: 4px 20px;
        height: 130px;
        color: @labels;

        &:hover {
          box-shadow: 6px 6px 2px 0px rgba(4, 76, 123, 0.3);
        }

        .ant-divider.ant-divider-vertical {
          background: @panel-outline;
          height: 35px;
        }

        .callactivity-list-row1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .callactivity-list-row2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          gap: 20px;
          .contact-icon {
            width: 32px;
            height: 30px;
            fill: #005d98;
            position: relative;
          }

          .contacts-icon {
            width: 32px;
            height: 30px;
            stroke: #005c98;
            fill: #005d98;
            position: relative;
          }

          .invited-count {
            font-size: 18px;
            font-weight: 700;
            color: #005c98;
            cursor: default;
          }
          .invited-list {
            display: flex;
            gap: 10px;

            .invited-count {
              font-size: 20px;
              align-items: center;
              font-weight: 900;
            }
            .user-image-container {
              width: 59px;
              height: 59px;
              .user-profile-pic {
                width: 59px;
                height: 59px;
              }
              .user-image {
                width: 100%;
                width: 100%;
              }
              .user-image-hide {
                display: none;
              }
            }
            .user-more {
              justify-content: center;
              border-radius: 29px;
              border: 2.3px solid #808080;
              height: 59px;
              width: 59px;
              text-align: center;
              color: #505050;
              display: flex;
              align-items: center;
              font-size: 18px;
              font-weight: 700;
            }
          }
        }
        .container-1 {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          min-width: 30%;
          cursor: default;
          color: #000;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          gap: 15px;
        }
        .callactivity-eventtime {
          font-weight: 700;
          font-size: 16px;
        }
        .callactivity-eventname {
          font-weight: 400;
        }
      }

      .callactivity-grey-container {
        background-color: #c9c9c9;
      }
    }

    .container-2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .status {
        color: #ffffff;
        padding: 7px 20px;
        font-size: 14px;
        border-radius: 24px;
        background: #ffc600;
        cursor: pointer;
      }
    }

    .actions-container {
      display: flex;
      align-items: center;
      .empty-action {
        width: 30px;
        margin-left: 10px;
      }
      .action {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 20px;
        cursor: pointer;
      }
      .action:last-child {
        margin-right: 0px;
      }
    }
  }
}

/* ListStaff */
.liststaff-wrapper {
  display: flex;
  flex-direction: column;

  .provider {
    display: flex;
    align-items: center;
    width: 186px;
    height: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.29px;
    text-align: left;
    color: #000000;
    cursor: default;
  }

  .staff-list-container {
    .staff-container {
      padding: 10px;
      justify-content: space-between;

      .container-1 {
        display: flex;
        align-items: center;
        min-width: 20%;
      }

      .container-2 {
        display: flex;
        align-items: center;
        margin-left: 120px;
      }

      .user-info-container {
        display: flex;
        align-items: center;

        .image-container {
          display: flex;
          .user-profile-pic {
            width: 59px;
            height: 59px;
          }
        }

        .info-container {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          cursor: default;
          min-width: 380px;
          width: 250px;
          .username {
            font-size: 16px;
            font-weight: 400;
            line-height: 20.29px;
            text-align: left;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis
          }
        }
      }
      .association-count-container {
        margin-right: 20px;
      }

      .role {
        width: 140px;
        margin-right: 14px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        .staffBadge {
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          background: #3fa9f5;
          border-radius: 30px;
          width: fit-content;
          padding: 6px 20px;
          margin-left: -70px;
        }

        .staffAdminBadge {
          color: #000000;
          font-size: 14px;
          font-weight: 700;
          background: #f4ead1;
          border-radius: 30px;
          width: fit-content;
          padding: 6px 11px;
          // width: 121px;
          height: fit-content;
          gap: 10px;
          margin-left: -70px;
        }
      }
      .relation {
        width: 160px;
        margin-right: 14px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 1536px) {
  .liststaff-wrapper {
    min-width: 1200px; 

    .page-header-container {
      min-width: 1200px; 
    }
  }
}

/* List Notifications */
.notifications-list-wrapper {
  .note-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 360px;
  }
  .page-wrapper {
    padding-top: 10px;
    height: fit-content;
    max-height: 100%;
    .notification-header {
      display: flex;
      padding: 0px 50px 10px 30px;
      gap: 15px;
      height: 66px;
      background: #f5f5f5;
      flex-wrap: wrap;

      .notification-title {
        display: flex;
        gap: 10px;
        color: #005c98;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        justify-content: flex-start;
        align-items: center;

        .hub-icon {
          display: flex;
          align-items: center;

          .hub-notification-icon {
            width: 41px;
            height: 41px;
            stroke: none;
            fill: #005c98;
          }
        }
      }
    }
  }
  .notifications-table {
    overflow-y: auto;

    .ant-table-cell {
      color: @labels;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      vertical-align: middle;
    }
    &.ant-table-wrapper .ant-table-tbody > tr > td {
      border-bottom: none;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .user-profile-pic {
    width: 59px;
    height: 59px;
  }

  .type_icon {
    width: 34px;
    height: 34px;
    background-color: @primary;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-icon {
      fill: @panel;
      stroke: @panel;
    }
    &.red {
      background-color: @warning;
    }
    &.grey {
      background-color: @low-priority-label;
      .custom-icon {
        fill: @labels;
        stroke: @labels;
      }
    }
    &.blue {
      background-color: @highlight;
    }
  }

  .description {
    font-weight: 700;
  }

  .status {
    display: flex;
    justify-content: center;
  }

  .entity-actions-container {
    justify-content: flex-start;
    .entity-action .custom-icon.action-icon {
      width: 30px;
      height: 30px;
    }
    .divider {
      height: 45px;
      background: @low-priority-fill;
      margin-right: 35px;
    }
    .action-icon {
      width: 30px;
      height: 30px;
    }
  }
}

@media (max-width: 1280px) {
  .notifications-list-wrapper{
    min-width: 1050px;
  }
}
/* ListContacts */
.contacts-list-wrapper {
  .page-header-container {
    height: unset;
    border-radius: 16px;
    padding: 18px 30px;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: #f5f5f5;
    .addcontacts-icon {
      fill: #005c98;
    }
  }

  .favorite svg {
    width: 1.7em !important;
    height: 1.2em !important;
    display: flex;
    align-items: center;
    path {
      fill: rgba(17, 62, 87, 1);
    }
  }

  .notfavorite svg {
    color: #113e57;
    width: 1.7em !important;
    height: 1.2em !important;
    display: flex;
    align-items: center;

    path:nth-of-type(1) {
      fill: white;
    }
    path:nth-of-type(2) {
      fill: #113e57;
    }
  }

  .relationship-column {
    cursor: default;
  }

  .contacts-info {
    display: flex;
    align-items: center;
    padding: 10px 50px 20px 50px;
    gap: 15px;

    .contacts-count {
      color: #005c98;
      font-size: 16px;
      font-weight: 700;
      cursor: default;
    }

    .add-contact-icon {
      cursor: pointer;
    }
  }

  .contacts-table {
    overflow-y: auto;

    .ant-table-cell {
      color: @labels;
      font-size: 16px;
      vertical-align: middle;
    }
    .ant-table-cell:last-child {
      width: 250px;
    }

    .user-info-container {
      display: flex;
      align-items: center;

      .icons-container {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .favorite-icon {
          padding: 2px;
          margin-left: -16px;
          margin-top: 25px;
          z-index: 1;

          svg {
            width: 19px;
            height: 28px;

            path {
              fill: rgba(249, 192, 48, 1);
            }
          }
        }

        .user-profile-pic {
          width: 59px;
          height: 59px;
        }

        .event-icon {
          width: 28px;
          height: 28px;
          padding: 2px;
          border-radius: 25px;
          background-color: @primary;
          border: 1px solid #c9c9c9;
          margin-left: -20px;
          margin-bottom: 25px;
          z-index: 1;
          &.red {
            background-color: @warning;
            fill: @panel;
            stroke: @panel;
          }
          &.grey {
            background-color: @low-priority-label;
            fill: @labels;
            stroke: @labels;
          }
        }
        .type_icon {
          .custom-icon {
            fill: @panel;
            stroke: @panel;
          }
          &.red {
            background-color: @warning;
          }
          &.grey {
            background-color: @low-priority-label;
            .custom-icon {
              fill: @labels;
              stroke: @labels;
            }
          }
        }
      }
      .name {
        font-weight: 700;
        margin-left: 16px;
      }

      .favorite-name {
        margin-left: 10px !important;
      }
    }
    .usertype-container {
      display: flex;
      align-items: center;
      cursor: default;
      .divider {
        height: 45px;
        background: @low-priority-fill;
      }
      .type-text {
        margin-left: 7px;
      }
    }
    .item-counter {
      display: flex;
      align-items: center;
      .item-icon {
        height: 20px;
        width: 24px;
        cursor: default;
      }
      .count-text {
        font-weight: 700;
        margin-left: 10px;
        color: #113e57;
      }
      .device-icon {
        width: 25px;
        height: 25px;
      }
    }
    .entity-actions-container {
      justify-content: space-between;
      align-items: center;
      .divider {
        height: 45px;
        background: @low-priority-fill;
      }

      .remove-icon {
        fill: #113e57;
        height: 16px;
      }

      .call-icon {
        stroke: #113e57;
        width: 24px;
        height: 24px;
      }

      .schedule-icon {
        stroke: #113e57;
        width: 25px;
        height: 25px;
      }

      .resend-icon {
        stroke: #113e57;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .edit-icon {
        fill: white;
        width: 28px;
        height: 24px;
      }
    }

    .zoom-control {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

.action {
  .edit-icon {
    fill: white;
    width: 30px;
    height: 30px;
  }
}

/* form container */
.form-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-title {
    color: @primary-2;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
  }

  .form-card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: white;
    padding-top: 20px;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .form-row-datepicker {
    margin-left: 20px;
    .ant-form-item-control-input-content {
      flex: none;
    }
    .form-group input {
      width: inherit;
    }
  }

  .form-group {
    .ant-form-item .ant-form-item-label > label.ant-form-item-required::before {
      content: "";
    }
    label {
      font-weight: 700;
      margin-bottom: 5px;
      display: flex;
      font-size: 16px;
    }
    input {
      width: 310px;
      height: 45px;
      border-radius: 5px;
      border-color: #005c98;
    }
  }

  .form-description {
    min-height: 120px;
    max-height: 150px;
    border-color: rgb(0, 92, 152);
  }

  .form-group-select {
    width: 300px;
    border-radius: 5px;
    border-width: 2px;
    border-color: #005c98;
    .ant-select-selector {
      width: 100%;
      height: 42px;
    }
  }
}

/* Loading */
.loading-container {
  color: @primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading-icon {
    font-size: 30px;
  }

  .loading-message {
    font-size: 16px;
    margin-top: 10px;
  }
}

/* Common > Cantact */
.contact-wrapper {
  height: 92px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  // border: 1px solid @background;
  color: #005d98;
  background: @new-background;
  // box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);

  &.primary {
    cursor: default;
    color: @panel;
    background: #005d98;
    color: white;
    .custom-icon {
      cursor: pointer;
      fill: @panel;
      stroke: @panel;
    }
    .photo-container {
      stroke: white !important;
    }
  }

  .group-1 {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .group-2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 16px;
    height: 16px;
    margin-left: 17px;
    width: fit-content;
    height: fit-content;
    align-items: center;

    .device-assigned {
      background: #6087A0 !important;
    }
  }

  .photo-container {
    width: 59px !important;
    height: 59px !important;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    margin-left: 17px;
    overflow: hidden;

    .name {
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .role {
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .custom-icon {
    height: 16px;
    width: 16px;
    fill: @primary;
    stroke: @primary;
  }
}

/* Common > CantactList */
.contact-list-container {
  align-items: center;

  .photo-container {
    height: 70px;
    width: 70px;
  }

  .info-container {
    width: 100%;
    font-size: 18px;
    margin-left: 10px;

    .name {
      width: 100%;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .role {
      font-weight: 500;
    }
  }
}

/* Common > ContactSelectList */
.contact-selection-list-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  max-width: 410px;
  max-height: 550px;
  border-radius: 16px;
  padding: 5px 15px;

  .selected-header {
    color: @labels;
    font-size: 18px;
    font-weight: 700;
    padding: 0 10px;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .contacts-divider {
    color: @panel-outline;
    margin: 10px 0;
    border-top: 1px solid;
  }

  .selection-list-header {
    color: #113E57;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 10px;

  }

  .selected-list-container {
    padding: 0 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .selection-list-container {
    padding: 0 10px;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .account-name {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;

      .username {
        font-size: 16px;
        line-height: 18.4px;
        font-weight: 700;
        color:  #113E57;

        .username-truncate {
          display: inline-block;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: bottom;
        }
      }
    }

    .contct-list-btn {
      padding: 12px 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      color: @primary;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
    }

    .view-more-contacts {
      background: #d9ecfa;     
    }

    .add-all-contacts {      
      border: 1px solid @primary;      
      background: #ffffff;    
    }
  }

  .close-icon {
    width: 30px;
    height: 30px;
    padding: 7px;
  }
  .add-icon {
    width: 30px;
    height: 30px;
    padding: 4px;
  }
}

/* Dropdown Selector */
.form-item-dropdown {
  .ant-select-selector {
    border: 0;
    height: 42px;
    display: flex;
    align-items: center;

    .ant-select-selection-search {
      inset-inline-start: 0px;
    }
  }
}
/* EditUserProfile */
.edit-profile-container {
  display: flex;
  height: 100%;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px 15px;
  border-radius: 16px;
  left: 324px;

  .hub-edit-icon {
    fill: #113e57;
  }

  .edit-profile-header {
    display: flex;
    width: 100%;
    height: 38px;
    align-items: center;
    gap: 24px;
    background-color: #f5f5f5;

    .edit-profile-title-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 364px;
      height: 38px;

    .edit-icon {
      fill: #113e57;
    }
    p {
      color: #113e57;
      font-size: 30px;
      line-height: 38px;
      font-weight: 700;
      font-style: normal;
      font-family: "Euclid Circular A";
    }
  }

    .form-button {
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 12px;
      border: 2px solid #fff;

      .text {
        text-align: right;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
      }

      .cancel-text {
        color: #000;
      }

      .update-text {
        color: #fff;
      }

      .img-cancel {
        display: flex;
        width: 20px;
        height: 20px;
        padding: 4.579px 4.246px 4.579px 4.912px;
        justify-content: center;
        align-items: center;
      }

      .img-update {
        width: 20px;
        height: 20px;
      }
    }

    .cancel {
      background: #f6f6f6;
    }

    .update {
      background: @primary;

      &:hover {
        background: @primary;
        color: #fff;
      }
    }

    .update:disabled {
      background: #f6f6f6;
      opacity: 0.3;
      p {
        color: #000;
      }
    }
  }
  .custom-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 20px 0px;
    gap: 16px;
    width: 988px;
    min-height: 91px;
  
    .password-button {
      box-sizing: border-box;
      align-items: center;
      padding: 16px 24px;
      gap: 8px;
      min-width: 186px;
      min-height: 51px;
      background: #FFFFFF;
      border: 1px solid #005D98;
      border-radius: 32px;
    }
} 
.edit-profile-form-container {
    display: flex;
    padding: 40px;
    justify-content: flex-start;
    background: #fff;
    gap: 15px;
    flex-direction: column;

    .edit-contact-staff {
      padding: 115px 24px 8px 300px !important;
    }

    .edit-profile-button-container {
      display: flex;
      flex-direction: row;
      width:988px;
      padding: 20px;
      gap: 16px;
      min-height: 76px;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .form-button {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
  
    .cancel-icon {
      width: 24px;
      height: 24px;
      fill: #005d98;
    }

    .update-icon-enabled {
      width: 24px;
      height: 24px;
      fill: #113e57;
      stroke:#113e57;
    }

    .update-icon-disabled {
      width: 24px;
      height: 24px;
      fill: white;
      stroke: white;
    }
    }


    .password-button {
      align-self: flex-start;
      margin-left: 30px;
    }

    .profile-avatar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 22px;
      min-height: 200px;
      min-width: 200px;
      cursor: pointer;

      .profile-avatar {
        display: flex;
        width: 200px;
        height: 200px;
        //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      }
      .profile-avatar-border{
        border: 2px solid #005d98;
        border-radius: 50%; 
      }

      .profile-pencil {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
      }
    }

    .emptyprofile {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      .empty-profile-container {
        display: flex;
        justify-content: center;
        border: 2px solid #c9c9c9;
        border-radius: 100%;
        width: 200px;
        gap: 22px;
        height: 200px;
        cursor: pointer;
      }

      .profile-pencil {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
      }
    }

    .account-form-row {
      display: flex;
      height: 79px;
      align-items: flex-end;
      gap: 30px;
      flex: 1 0 0;
      margin: 15px 0;

      .emailcenter {
        margin-left: 60px;
      }

      .account-form-group {
        display: flex;
        height: 79px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        // flex: 1 0 0;

        .ant-form-item-row {
          display: flex;
          flex-direction: column;
        }

        .ant-form-item-label {
          text-align: start;
        }

        .ant-select-selector {
          width: 280px;
          height: 42px;
        }

        .custom-select .ant-select-selector {
          width: 320px !important;
        }

        label {
          color: #000;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-align: left;
        }
        input {
          display: flex;
          height: 42px;
          padding: 10px;
          flex-direction: row;
          align-items: flex-start;
          border-radius: 8px;
          width: 257px;
          border: 1px solid #005c98;
          background: #fff;
        }

        .togglebutton {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          margin-top: 40px;

          .ant-switch.ant-switch-checked {
            border: 1px solid #005d98;
            background-color: white;

            .ant-switch-handle {
              top: 1px;
            }

            .ant-switch-handle::before {
              background-color: #005d98;
              top: 1px;
            }
          }

          .ant-switch {
            border: 1px solid #808080;
            background-color: white;

            .ant-switch-handle {
              top: 1px;
            }

            .ant-switch-handle::before {
              background-color: #808080;
            }
          }

          .timeformat-text {
            font-family: "Euclid Circular A", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.29px;
            white-space: nowrap;
            color: #000000;
          }
        }

        .contactingstaff {
          .togglelistbutton {
            display: flex;
            bottom: 0px;
            gap: 20px;
            left: 53%;
            align-items: center;
            margin-top: 8px;
            margin-right: 97px;

            .ant-switch.ant-switch-checked {
              border: 1px solid #808080;
              background-color: white;

              .ant-switch-handle {
                top: 1px;
              }

              .ant-switch-handle::before {
                background-color: #808080;
              }
            }
            .subtogglebuttonprincipal .ant-switch.ant-switch-checked {
              border: 1px solid #005d98 !important;
              background-color: white !important;

              .ant-switch-handle::before {
                background-color: #005d98 !important;
                top: 1px;
              }
            }
          }

          .subtogglelist {
            margin-left: 40px;

            .subtogglebutton {
              display: flex;
              bottom: 0px;
              gap: 20px;
              left: 53%;
              align-items: center;
              margin-top: 8px;

              .ant-switch.ant-switch-checked {
                border: 1px solid #808080;
                background-color: white;

                .ant-switch-handle {
                  top: 1px;
                }

                .ant-switch-handle::before {
                  background-color: #808080;
                }
              }
              .subtogglebuttonprincipal .ant-switch.ant-switch-checked {
                border: 1px solid #005d98 !important;
                background-color: white !important;

                .ant-switch-handle::before {
                  background-color: #005d98 !important;
                  top: 1px;
                }
              }
            }
          }
        }
      }

      .account-form-row {
        height: 0px !important;
        flex-direction: row !important;
      }
    }
  } 
}

/* ListSchedule */
.list-schedule-container-newui {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 128, 255, 0.2);
  display: flex;
  flex-direction: column;

  .hub-schedule-title {
    cursor: default;
  }

  .schedule-content-newui {
    flex: 1;
    overflow-y: auto;
  }

  .schedule-header-newui {
    display: flex;
    padding: 20px 50px 8px 30px;
    gap: 15px;
    height: 66px;
    background: #f5f5f5;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .schedule-title-newui {
      display: flex;
      align-items: center;

      .schedule-text-newui {
        color: #113E57;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: default;
      }

      .hub-schedule-header {
        display: flex;
        gap: 10px;
        color: #113E57;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        justify-content: center;
        align-items: center;

        .hub-icon {
          display: flex;
          align-items: center;

          .hub-schedule-icon {
            fill: #005c98;
            width: 40px;
            height: 41px;
          }
        }
      }
    }
  }
  .schedule-list-container-newui {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;

    .schedule-actions-newui {
      display: flex;
      padding: 10px 50px 20px 50px;
      align-items: center;
      gap: 15px;

      .add-event-icon {
        width: 60px;
        height: 60px;
        cursor: pointer;
      }
      .add-task-icon {
        circle {
          fill: #113e57;
        }
        path {
          fill: #d9ecfa;
        }
      }

      .schedule-datefilter {
        display: flex;
        height: 34px;
        padding: 4px 17px 4px 20px;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        border: 1px solid #fff;
        background: #fff;
        box-shadow: 0px 3px 6px 0px rgba(0, 92, 152, 0.16);
        .schedule-datapicker-hide {
          display: none;
        }
        .schedule-datefilter-content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          .schedule-date {
            color: #000;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.3px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            .schedule-day {
              font-weight: 700;
            }
            .schedule-calendar {
              padding-left: 10px;
              .action {
                display: flex;
                gap: 10px;
              }
              .ant-picker {
                height: 34px !important;
              }
            }
          }
        }
      }
      .schedule-divider {
        width: 1px;
        height: 42px;
        background: #d9d9d9;
      }
    }
    .skeleton-container-newui {
      height: 68px;
      margin-top: 10px;
    }
    .schedule-list-date-newui {
      height: 40px;
      background-color: #f5f5f5;
      color: #6087a0;
      margin-bottom: 11px;
      .schedule-date-newui {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 10px;
        padding-left: 20px;
        cursor: default;
      }

      .no-events {
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: default;
        padding: 20px 15px 20px;
        font-size: 20px;
        font-weight: 700;
        color: #005D98;
      }
      .no-events-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
        gap: 24px;
        isolation: isolate;
        background: #ffffff;
        box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
        border-radius: 16px;
        height: 105px;
        .no-events-card {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 6px;
          .no-events-column {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 6px;
            .no-events-information-content{
              font-family: "Euclid Circular A";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: #113E57;
          }
          .no-events-description-content{
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #113E57;
        }
          }
        }
      }
      
    }
    .schedule-list-today-newui {
      background-color: #f5f5f5;
      color: #005c98;
    }
    .tab_view {
      margin-top: 20px;
      padding: 0px 15px;
      display: flex;
      justify-content: space-between;

      .schedule-filter-dropdown {
        display: flex;
        gap: 20px;
        align-items: center;

        .filter-container {
          .listschedule-filter {
            border: 1px solid #D9ECFA !important;        
            box-shadow: none;
            height: 62px;
            margin-top: 0px;
          }
        }
      }

      .ant-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                font-size: 20px;
                font-weight: 400;
                color: #808080
              }

              .ant-tabs-tab-active {
                .ant-tabs-ink-bar {
                  background: #113E57;
                }
                .ant-tabs-tab-btn {
                  color: #113E57;
                  font-size: 20px;
                  font-weight: 700;
                  text-shadow: 0 0 0.25px #113E57;
                }
              }
            } 
          }
        }
      }
    }  

    .schedule-list-data {
      margin-bottom: 50px;

      .filtered_event_count {
        padding: 20px 15px 20px;
        font-size: 20px;
        font-weight: 700;
        color: #005D98;
      }
      .schedule-list-today-newui {
        background-color: #f5f5f5;
        color: #005c98;
      }
      .schedule-container-newui {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: @panel;
        border-radius: 16px;
        box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
        margin-bottom: 11px;
        padding: 4px 20px;
        height: 130px;
        color: @labels;

        &:hover {
          box-shadow: 6px 6px 2px 0px rgba(4, 76, 123, 0.3);
        }

        .ant-divider.ant-divider-vertical {
          background: @panel-outline;
          height: 35px;
        }

        .schedule-list-row1-newui {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .container-1 {
            cursor: default;
          }
        }
        .schedule-list-row2-newui {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 20px;

          .schedule-list-row2-container1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;          
          
            .status {
              color: #ffffff;
              padding: 7px 20px;
              font-size: 14px;
              border-radius: 24px;
              background: #ffc600;
              cursor: pointer;
            }

          .schedule-meeting-count {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;

            .account-icon {
              width: 24px;
              height: 24px;
              fill: #113E57;
            }

            .invited-count {
              font-size: 18px;
              font-weight: 700;
              color: #113E57;
              cursor: default;
            }
          }

          .reminder_data {
            display: flex;
            flex-direction: row;
            gap: 8px;
            font-size: 16px;
            font-weight: 700;
            color: #113E57;
            width: 185px;

            .hour_data {
              font-weight: 400;
              white-space: nowrap;
            }
          }
         
          .invited-list {
            display: flex;
            width: 134px;
            margin-left: 30px;

            .invited-count {
              font-size: 20px;
              align-items: center;
              font-weight: 900;
            }
            .user-image-container {
              width: 59px;
              height: 59px;
              margin-left: -20px;
              .user-profile-pic {
                width: 59px;
                height: 59px;
              }
              .user-image {
                width: 100%;
                width: 100%;
                border-radius: 29px;
                border: 2.3px solid #0072BC;
              }
              .user-image-hide {
                display: none;
              }
            }
            .user-more {
              justify-content: center;
              border-radius: 29px;
              border: 2.3px solid #0072BC;
              background: #0072BC;
              height: 59px;
              width: 59px;
              text-align: center;
              color: #FFFF;
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 600;
              margin-left: -40px;
            }
          }

          .event_days_info {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            color: #113E57;
            gap: 10px;
            
            .days_of_week {
              font-size: 14px;
              display: flex;
              flex-direction: row;
              gap: 8px;

              .day_info {
                padding: 9px 9px;
                background-color: #D9ECFA;
                border: 1px solid #3FA9F5;
                border-radius: 18px;
              }
            }
          }
        }

        .schedule-list-row2-container2 {
          display: flex;
          flex-direction: row;
          align-items: center;

          .status {
            color: #ffffff;
            padding: 7px 20px;
            font-size: 14px;
            border-radius: 24px;
            background: #ffc600;
            cursor: pointer;
          }
        }
      }
        .container-1 {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          min-width: 30%;
          cursor: pointer;
          color: #000;
          font-size: 16px;
          font-style: normal;
          line-height: normal;
          gap: 15px;
        }
        .schedule-eventtime-newui {
          font-weight: 700;
          cursor: default;
        }
        .schedule-eventname-newui {
          font-weight: 700;
          color: #113E57;
          cursor: pointer;
        }
      }
    }
    .container-2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px; 
            
      .task-status {
        padding: 11px;
        border-radius: 23px;
        font-size: 14px;
        font-weight: 700;
      }

      .task-status-ongoing {
        background: #F9C030;
        border: 2px solid #F9C030;
        color: #113E57;
      }

      .task-status-expired {
        background: #F46363;
        border-color: #F46363 !important;
        color: #0A0A0A;
      }

      .task-status-completed {
        background: #4BD37B;
        border: 2px solid #4BD37B;
        color: #027B0E;
      }

      .task-status-scheduled {
        background: #808080;
        border: 2px solid #808080;
        color: #FFFFFF
      }
    }

    .actions-container {
      display: flex;
      align-items: center;
      .empty-action {
        width: 30px;
        margin-left: 10px;
      }
      .action {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 20px;
        cursor: pointer;
      }
      .action:last-child {
        margin-right: 0px;
      }
    }
  }
}
.account-setup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 20px 30px 20px 30px;
  .account-setup-row.top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    .account-setup-title {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #113e57;
    }
    .account-setup-paragraph {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #113e57;
    }
  }
  .account-setup-row.bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    .account-setup-info-block {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px 32px 16px 16px;
      width: 228px;
      height: 56px;
      background: rgba(255, 222, 161, 0.4);
      border-left: 4px solid #ffa500;
      border-radius: 8px;
      cursor: pointer;
      .account-setup-info-label {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #4e758e;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1279px) {
  .list-schedule-container-newui {
    min-width: 900px;   
  }
}

/* StartEvent */
.startevent-page-wrapper {
  display: flex;
  gap: 35px;
  .contact-selection-list-wrapper {
    border: none;
    padding-top: 0px;
    max-width: 480px;
    .selected-header {
      padding: 0px 2px;
      padding-top: 0px !important;
    }
    .selected-list-container {
      border: 1px solid #d9ecfa;
      border-radius: 13px;
      padding: 30px 20px;
      height: 422px;
    }
  }
}

@media (max-width: 1279px) {
  .startevent-page-wrapper {
    min-width: 900px;     
  }
}
/* Event Details */
.event-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  .event-details-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    gap: 20px;
    color: #005D98;
  }
  .event-details-actions {
    align-items: center;
    display: flex;
    gap: 30px;
    color: #005D98;
    font-size: 16px;
    font-weight: 700;

    .edit-action {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.event-details-body {
  font-style: normal;
  line-height: normal;
  gap: 20px;
  display: flex;

  .event-details {
    display: flex;
    flex-direction: column ;
    gap: 20px;
    color: #113E57;

    .general-info-text {
      font-family: "Euclid Circular A";
      font-weight: 700;
      font-size: 18px;
    }

    .events-details-info {
      width: 560px;
      height: 190px;
      border: 1px solid #d9ecfa;
      border-radius: 13px;
      padding: 30px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #113E57;

      .event-scheduled-day-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-family: "Euclid Circular A";
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 35px;

        .event-scheduled-daydata {
          display: flex;
              flex-direction: column;
              gap: 15px;

          .event-scheduled-repeat-alert {
            display: flex;
            gap: 10px;

            .event-scheduled-infovalue {
              font-weight: 400;
            }
          }       
        }
        .event-created-date {
          font-weight: 400;
          font-size: 14px;
          color: #808080;
        }
      }

      .event-scheduled-date-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .time-text {
          font-weight: 700;
          font-size: 16px;
          color: #113E57;
        }
      }
      .join-call-exists {
        margin-bottom: 28px;
      }
      .no-join-call {
        margin-bottom: 110px;
      }
    }

    .event-description {
      display: flex;
      flex-direction: column;
      gap: 20px;
      color: #113E57;
      .label {
        font-size: 18px;
        font-weight: 700;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
      }
      .description {
        min-height: 80px;
        word-wrap: break-word;
        background: #fff;
        color: #525252;;
        width: 560px;
        height: 190px;
        border: 1px solid #d9ecfa;
        border-radius: 13px;
        padding: 20px 10px;
        height: 159px;
        overflow: auto;
      }
      .url-link,
      a {
        display: flex;
        color: #3fa9f5;
        font-size: 16px;
        text-decoration-line: underline;
      }
      .custom-icon {
        fill: #3fa9f5;
        stroke: none;
        height: 30px;
        width: 30px;
        vertical-align: middle;
        margin-left: 11px;
      }
    }
  }
}

/* About Page */
.about-card-wrapper {
  display: flex;
  width: 100%;
  height: 300px;
  max-width: 595px;
  min-width: 400px;
  padding: 1px 28px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  background: @panel;
  box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
  margin: 31px 0px -15px 36px;

  .info-header {
    width: 290px;
    color: @primary;
    font-size: 21px;
    font-weight: 700;
    margin: 11px 0px;
  }

  .data-group {
    display: flex;
    padding: 0px 45px;
    align-items: center;
    gap: 15px;
    align-self: stretch;
    margin: 12px 0px;

    img {
      width: 32px;
      height: 32px;
    }

    p,
    a {
      height: 14px;
      flex: 1 0 0;
      color: @labels;
      font-size: 13px;
      font-weight: 400;
    }

    .hslogo {
      border-radius: 14px;
    }
  }

  .mobile-data-store {
    display: flex;
    padding: 0px 35px;
    align-items: center;
    gap: 30px;
    align-self: stretch;
    margin: 12px 0px;
  }

  .mobile-data-group {
    display: flex;
    padding: 0px 70px;
    gap: 15px;
    align-items: center;
    align-self: stretch;
    margin: 12px 0px;

    img {
      width: 32px;
      height: 32px;
    }

    p,
    a {
      height: 14px;
      flex: 1 0 0;
      color: @labels;
      font-size: 13px;
      font-weight: 400;
    }

    .mobile-info-head {
      font-size: 15px;
    }

    .mobile-info-code {
      font-size: 15px;
      font-weight: 900;
    }
    .hslogo {
      border-radius: 14px;
    }
  }

  .data {
    display: flex;
    padding: 0px 45px;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    p {
      height: 14px;
      flex: 1 0 0;
      font-size: 13px;
      font-weight: 400;
      margin: 12px 0px;
      color: @labels;
    }
    a {
      height: 14px;
      font-size: 13px;
      font-weight: 400;
      margin: 12px 0px;
      color: #3fa9f5;
      gap: 3px;
    }

    .custom-icon {
      fill: #3fa9f5;
      stroke: none;
      height: 24px;
      width: 24px;
      margin-top: 1px;
    }

    span.copywriteinfo {
      font-size: 12px;
      font-weight: 400;
      margin: 13px 0px !important;
      color: #000;
      a.trademarklink {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 1280px) {
  .about-card-wrapper {
      min-width: 550px;  
    }
}

/* Devices */
.devices-container {
  display: flex;
  flex-direction: column;

  .page-header-container {
    .header-action-container {
      .search-container {
        margin-right: 15px;
      }
      .search-icon {
        margin-left: 15px;
      }
      .filter-icon {
        margin-left: 15px;
      }
    }
  }

  .devices-list-container {
    .device-container {
      flex-wrap: wrap;
      justify-content: space-between;

      .container-1 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-width: 30%;
      }

      .device-accountname {
        cursor: pointer;
      }

      .device-icon-container {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }

      .device-sn {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        align-items: center;
        margin-left: 15px;
        width: auto;
        flex-grow: 1;
      }

      .device-status-ready-to-assign {
        cursor: pointer;
        width: 148px;
        height: 26px;
        padding: 4px 20px 4px 20px;
        border-radius: 30px;
        background: #4bd37b;
        display: flex;
        align-items: center;
        margin-left: 15px;
        .device-status-text {
          width: 108px;
          height: 18px;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #ffffff;
        }
      }

      .device-status-not-activated {
        width: 136px;
        height: 26px;
        padding: 4px 20px 4px 20px;
        border-radius: 30px;
        gap: 10px;
        background: #d9d9d9;
        margin-left: 15px;
        .device-status-text {
          width: 96px;
          height: 18px;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .device-status-not-ready {
        width: 111px;
        height: 26px;
        padding: 4px 20px 4px 20px;
        border-radius: 30px;
        gap: 10px;
        background: #f9c030;
        margin-left: 15px;
        .device-status-text {
          width: 71px;
          height: 18px;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #ffffff;
        }
      }

      .account-name {
        width: 135px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #000;
        margin-left: 15px;
      }

      .container-2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 900px) {
  .devices-container {
    min-width: 700px;
    
    .devices-list-container {
      .device-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }
    }
   
}
}

/* AddAccount */
.addaccount-container {
  display: flex;
  flex-direction: column;

  .account-button-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 30px 24px 8px 44px;
  }
  .ant-form-item .ant-form-item-label > label::after {
    content: "";
    position: relative;
    margin-block: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .optional-label {
    color: #868585;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    height: 63px;
    width: 63px;
    gap: 10px;
  }

  .account-form-row {
    display: flex;
    height: 72px;
    align-items: flex-end;
    gap: 30px;
    flex: 1 0 0;
  }
  .account-form-group {
    display: flex;
    height: 72px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
  }

  .account-form-group .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .account-form-group .ant-form-item-label {
    text-align: start;
  }

  .account-form-group label {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }
  .account-form-group input {
    display: flex;
    height: 42px;
    padding: 10px;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 8px;
    width: 224px;
    border: 1px solid #005c98;
    background: #fff;
  }

  .account-form-group-disabled input {
    border: 1px solid #7a7a7a !important;
  }

  .account-form-group-disabled label {
    color: #7a7a7a !important;
  }

  .top-section {
    height: 220px;
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;

    .account-main-label {
      font-size: 30px;
      font-weight: 400;
      line-height: 38.04px;
      text-align: left;
      color: #005c98;
      padding: 12px 50px 0px 30px;
      gap: 15px;
      cursor: default;
    }

    .account-label {
      display: flex;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30.43px;
      height: 40px;
      color: #000;
      padding-top: 19px;
      padding-left: 38.56px;
      gap: 0px;
      cursor: default;
    }

    .name-device-container {
      display: flex;
      height: 72px;
      margin-left: 50px;
      margin-top: 19px;
      flex-direction: column;
      align-items: flex-start;
      gap: 53px;

      .account-devicestatus {
        display: flex;
        align-items: center;
      }
      .account-deviceinfo {
        display: flex;
        height: 72px;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        cursor: default;

        .accont-device-label {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-top: 6px;
        }

        .account-device-sn {
          overflow: hidden;
          color: #000;
          text-overflow: ellipsis;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 155px;
        }
      }
    }
  }

  .bottom-section {
    margin-top: 15px;
    margin-left: 43px;
    width: 917px;
    height: 685px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    display: flex;
    flex-direction: column;
    gap: 21px;

    .account-self-label {
      display: flex;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30.43px;
      height: 40px;
      color: #000;
      padding-left: 30px;
      padding-top: 20px;
      text-align: left;
      cursor: default;
    }
    .account-self-options {
      display: flex;
      padding: 10px 0px;
      align-items: center;
      gap: 20px;
      padding-left: 20px;
      padding-top: 21px;
      cursor: default;
      .account-self-options-text {
        color: @selected;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .account-self-manage {
      display: flex;
      padding: 10px 50px 10px 50px;
      align-items: center;
      gap: 53px;

      .account-self-email-container {
        display: flex;
        height: 72px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
        padding-left: 20px;

        .account-form-group {
          width: 100%;
        }
        .ant-form-item {
          width: 100%;
        }
        .account-form-group input {
          display: flex;
          height: 42px;
          padding: 10px 0px 0px 0px;
          flex-direction: row;
          align-items: flex-start;
          width: 419px;
          border: 1px solid #005c98;
          background: #fff;
          gap: 10px;
          border-radius: 8px;
          border: 1px solid #7a7a7a;
        }
      }

      .account-self-info-container {
        display: flex;
        height: 72px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        flex: 1 0 0;
        padding-left: 20px;
      }

      .account-self-rel-container {
        display: flex;
        height: 72px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
        padding-left: 20px;

        .account-form-group {
          width: 100%;
        }
        .ant-form-item {
          width: 100%;
        }

        .account-form-group input {
          display: flex;
          height: 42px;
          padding: 10px;
          flex-direction: row;
          align-items: flex-start;
          border-radius: 8px;
          width: 40%;
          border: 1px solid #005c98;
          background: #fff;
        }
      }
    }
    .account-self-hline {
      height: 1px;
      background: #c9c9c9;
      width: 873px;
      display: flex;
      margin-left: 20px;
      margin-top: 1px;
    }
  }
}

@media (max-width: 1280px) {
  .addaccount-container {
    min-width: 1200px; 

      .top-section {
        .name-device-container {
          .account-deviceinfo {
            width: max-content;
          }
        }
      }
  }
}

/* 
  StaffForm
  AssignStaff
  ContactForm
*/
.staff-form-container,
.asignstaff-form-container,
.contact-form-container {
  flex-direction: column;

  .top-margin {
    margin-top: 15px;
  }

  .custom-form {
    display: flex;
    overflow-y: auto;
    flex: 1;
  }
  .ant-spin-container {
    // display: flex;
    // flex-direction: column;
    flex: 1;
  }

  .form-group-1 {
    min-height: 108px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 50px;
    background: @panel;
    align-items: center;

    .cancel-icon {
      width: 13px;
      height: 13px;
    }

    .submit-icon {
      width: 20px;
      height: 20px;
    }
  }

  .staff-button-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 30px 24px 8px 44px;
  }

  .assign-staff-button-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 30px 24px 8px 44px;
  }

  .form-group-2 {
    display: flex;
    padding: 10px 50px;
    overflow-y: auto;
    flex: 1;

    .contact-selection-list-wrapper {
      max-height: 717px;
      background: @panel;
      box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
      padding-bottom: 30px;
    }
  }

  .hub-info-container {
    margin-right: 34px;
    width: 631px;

    .label {
      color: @labels;
      font-size: 16px;
      font-weight: 700;
    }

    .user-info-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: @primary;
      border: 1px solid @gray-2;
      border-radius: 10px;
      margin-top: 10px;
      padding: 5px 10px;

      .user-profile-pic {
        width: 59px;
        height: 59px;
        margin-right: 8px;
        stroke: white !important;
      }
      .user-name {
        flex: 1;
        color: @panel;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .form-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 600px;
    max-width: 1000px;
    min-height: 400px;
    max-height: 600px;
    background: @panel;
    border-radius: 16px;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    margin-top: 19px;
    padding: 20px;
    overflow: auto;
    margin-bottom: 40px;

    input {
      width: 392px;
    }

    .header-text {
      color: @labels;
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 15px;
      cursor: default;
    }

    .toggle-label {
      margin-left: 20px;
    }

    .badge {
      color: @labels;
      background: @alert-panel;
    }
  }
}

@media (max-width: 1536px) {
  .staff-form-container {
    min-width: 1200px;   
  }
}

/* AssignStaff */
.asignstaff-form-container {
  .form-group-1 {
    justify-content: flex-end;
  }
  .contact-selection-list-wrapper {
    min-width: 430px;
    width: 30%;
    margin-right: 29px;
    background: @panel;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
  }
}

.role-container {
  display: flex;
  padding: 10px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;

  .row {
    display: flex;
    width: 100%;

    .column {
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      flex-direction: column;
      align-items: start;
      gap: 10px;

      .desc {
        color: #868585;
        margin-left: 10px;
      }

      .desc-selected {
        color: @primary;
      }

      .button-selected {
        stroke: white;
        color: white;
        background: @primary;
        border: #fff;
      }

      .allow-role-change-button-selected {
        stroke: white;
        background-color: @primary-2 !important;
        border: #fff;
        cursor: not-allowed;
        opacity: 1;
      }

      .permissions-item {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-left: 10px;

        .icon {
          display: flex;
          align-items: center;
          height: 20px;
          width: 20px;

          .tick {
            stroke: @primary;
          }
        }
      }
    }
  }
  .ant-progress-bg {
    background-color: @primary;
    &::before {
      animation: none !important;
    }
  }
}

/* NotificationMiniBanner */
// .notification-banner {
//   position: absolute;
//   top: 6%;
//   left: 55%;
//   transform: translate(-50%, -50%);
//   z-index: 9999;
//   .notification-mini-banner {
//     display: flex;
//     padding: 5px;
//     margin-right: -5px;
//     align-items: center;
//     gap: 12px;
//     border-radius: 40px;
//     background: #fff;
//     border: 2px solid #005c98;
// }
// }

/* NotificationMiniBanner */
.notification-banner {
  position: absolute;
  top: 0; /* Set to 0 to align with the top of the page */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  z-index: 500;
}

.notification-mini-banner {
  width: 530px;
  display: flex;
  padding: 5px;
  margin-right: -5px;
  align-items: center;
  gap: 12px;
  border-radius: 40px;
  background: #fff;
  box-shadow: 6px 6px 20px 0px #044c7b33;
  // border: 2px solid #005c98;

  .event-info-container{
    min-width: 400px;
  }
}

.action-icons {
  width: 20px;
  height: 20px;
}

.notification-mini-banner-wrapper {
  .notification-mini-banner {
    display: flex;
    padding: 5px;
    margin-right: -5px;
    align-items: center;
    gap: 12px;
    border-radius: 40px;
    background: #fff;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      background-color: #3fa9f5;
      border-radius: 100%;

      .icon {
        width: 16px;
        height: 16px;
        z-index: 1;
        stroke: white;
      }
    }

    .info-container {
      display: flex;
      min-width: 299px;
      flex-direction: column;
      color: @primary;
      margin-right: 25px;
      line-height: normal;

      .info-heading {
        font-size: 14px;
        font-weight: 700;
      }

      .info-description {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .action-icons {
      stroke: @primary !important;
      fill: @primary !important;
    }

    .cancel-icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    .divider {
      width: 1px;
      height: 57px;
      background: #d9d9d9;
      margin: 0px 0px;
    }

    .bell-icon {
      cursor: pointer;
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 59px;
        height: 59px;
        border: 2px solid #c9c9c9;
        border-radius: 100%;
      }
    }
  }
  .notification-count {
    &.ant-badge .ant-badge-multiple-words {
      padding: 2px 5px;
    }
    &.ant-badge .ant-badge-count {
      background-color: @warning;
      color: @panel;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      box-shadow: none;
    }
  }
  .notification-count .ant-badge-dot {
    background: #F22C2C !important;
    width: 10px;
    height: 10px;
    z-index: auto;
    min-width: 10px;
    border-radius: 100%;
    outline: 1px solid #D9ECFA;
    box-shadow: none;
  }
}

/* Send Message */
.send-message-page-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  gap: 20px;
  overflow: hidden;

  .send-message-header {
    display: flex;
    padding: 15px;
    cursor: default;

    .send-message-icon {
      display: flex;
      align-items: center;

      .send-message-icon {
        fill: #005c98;
        width: 40px;
        height: 41px;
      }
    }
    .send-message-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-weight: 700;
      color: rgba(0, 92, 152, 1);
      padding: 10px;
      line-height: 38px;
      text-align: left;
    }
  }

  .send-message-actions-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 30px 24px 8px 44px;
    width: max-content;
  }

  .message-form-container {
    width: max-content;
    display: flex;
    // padding: 20px 50px 10px 50px;
    padding-left: 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    // background-color: @panel;
    margin-top: -20px;

    .message-input-container {
      display: flex;
      width: max-content;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .send-message {
        display: flex;
        // height: 72px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
        width: max-content;

        .label {
          text-align: center;
          font-size: 16px;
          color: @labels;
          font-weight: 700;
          line-height: normal;
          line-height: 20px;
        }

        .input {
          height: 100px;
          padding: 10px;
          min-width: 700px;
          max-width: fit-content;
          font-size: 16px;
          border-radius: 8px;
          border: 1px solid @primary;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border: 1px solid #005c98;
          resize: none;
          white-space: pre-wrap;
          overflow-wrap: break-word;
          overflow: hidden;
        }
      }

      .limit {
        height: 20px;
        color: @labels;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        padding: 8px;
        margin-left: 525px;
      }
    }

    .actions-container {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .cancel-icon {
        width: 13px;
        height: 13px;
      }
    }
  }

  .contact-container {
    align-self: flex-end;
    margin-right: 50px;
  }
}

// Reboot-Reset Modal
.reboot-reset-modal {
  .ant-modal-content {
    padding: 0px;
    border-radius: 16px;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    white-space: normal;
  }

  .content-container {
    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    gap: 29px;
    align-items: center;

    .icon {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    .custom-icon-color {
      fill: #005c98;
      stroke: #005c98;
    }

    .description-container {
      display: flex;
      flex-direction: column;
      width: 438px;
      align-items: center;
      text-align: center;
      gap: 10px;

      .title {
        color: @primary;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        line-height: normal;

        .messagetitle {
          font-size: 22px;
          font-weight: 900;
        }
      }

      .description {
        color: @primary;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;

        .messagedescription {
          font-size: 24px;
          font-weight: 400;
        }
      }
    }

    .actions-container {
      display: flex;
      gap: 20px;
    }
  }
}

// Auto-Call Modal
.auto-call-modal {
  .ant-modal-content {
    padding: 0px;
    border-radius: 16px;
    box-shadow: 6px 6px 20px 0px rgba(4, 76, 123, 0.2);
    white-space: normal;
  }

  .auto-call-modal-content-container {
    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    gap: 29px;
    height: 454px;
    width: 838px;
    border-radius: 16px;
    background: #ffffff;

    .auto-call-icon {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
    }

    .auto-call-description-container {
      display: flex;
      flex-direction: column;
      width: 438px;
      height: 100px;
      align-items: center;
      text-align: center;
      gap: 10px;

      .auto-call-title {
        font-family: "Euclid Circular A";
        color: #005c98;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        line-height: 38.04px;
      }

      .auto-call-description {
        font-family: "Euclid Circular A";
        color: #005c98;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 20.29px;
      }

      .sub-call-description {
        font-family: "Euclid Circular A";
        color: #005c98;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.29px;
        width: 413px;
        height: 20px;
      }
    }

    .auto-call-actions-container {
      display: flex;
      gap: 20px;
      width: 638px;
      height: 36px;

      .cancel-container {
        width: 135px;
        height: 36px;
        gap: 29px;
      }

      .ring-container {
        width: 265px;
        height: 36px;
        gap: 29px;
      }

      .proceed-container {
        width: 198px;
        height: 36px;
        gap: 29px;
      }
    }
  }
}
.mini-notification-banner {
  display: flex;
  padding: 5px;
  margin-right: -5px;
  align-items: center;
  gap: 12px;
  border-radius: 40px;
  background: #fff;

  .contact-container {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 15px;
    cursor: pointer;

    .image-container {
      display: flex;
      width: 59px;
      height: 59px;

      img {
        border: 2px solid #c9c9c9;
        border-radius: 100%;
        border-width: 0px, 0px, 0px, 0px;
        border-style: solid;
        border-color: #c9c9c9;
        width: 59px;
        height: 59px;
      }
      .user-profile-pic {
        width: 59px;
        height: 59px;
      }
    }
  }
  .call-info-container {
    display: flex;
    gap: 5px;
    align-items: center;

    .missed-icon {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      width: 16px;
      height: 16px;
      top: 6px;
      left: 4.2px;
    }
    .info-container {
      width: 260px;
      height: 30px;
      align-items: center;
      justify-content: space-between;

      .call-info {
        width: 216.54px;
        height: 16px;
        left: 30px;
        font-family: "Euclid Circular A";
        font-size: 14px;
        font-weight: 700;
        line-height: 17.75px;
        text-align: left;
        color: #005c98;
        white-space: nowrap;
      }
      .call-time {
        width: 271px;
        height: 13px;
        top: 17px;
        left: 30px;
        font-family: "Euclid Circular A";
        font-size: 12px;
        font-weight: 400;
        line-height: 15.22px;
        text-align: left;
        color: #005c98;
      }
    }
  }
  .call-icon-container {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .call-icon {
      width: 24px;
      height: 24px;
      top: 3px;
      left: 3px;
      border: 2px;
      stroke: #005d98;
      fill: #005d98;
    }
  }
  .close-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      width: 16.26px;
      height: 16.26px;
      top: 6.87px;
      left: 7.37px;
      stroke: #005d98;
      fill: #005d98;
    }
  }
  .divider {
    width: 1px;
    height: 57px;
    background: #d9d9d9;
    margin: 0px 0px;
  }
  .bell-icon {
    display: flex;
    min-width: 45px;
    min-height: 30px;
    gap: 10px;
    padding: 0 10px 0 5px;
    align-items: center;
    stroke: #005d98;
    fill: #005d98;

    .icon {
      width: 30px;
      height: 30px;
      stroke: #005d98;
      fill: #005d98;
    }
  }
}
.custom-tooltip .ant-tooltip-inner {
  background-color: black;
  color: white;
  border: none;
}
 
.custom-tooltip .ant-tooltip-arrow {
  border-top-color: black ;
}
 
.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 8px;
  min-width: 110px;
  min-height: 92px;
  background: #000000;
  border-radius: 4px;
 
  .tooltip-info {
    min-width: 94px;
    min-height: 84px;
    background: rgba(255, 255, 255, 1e-06);
  }
    .tooltip-label {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
    }
 
    .tooltip-activity {
      font-family: "Euclid Circular A";
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
}
