.assign-staff-modal-main.first .ant-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 554px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  /* ContactSelectionForStaff.jsx */
  .new-contact-selection-main-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 12px;
    isolation: isolate;
    width: 506px;
    background: #ffffff;
    border: 1.5px solid #d9ecfa;
    border-radius: 16px;
    .new-contact-search-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding: 0px;
      gap: 4px;
      width: 458px;
      .select-a-staff-label {
        width: 458px;
        height: 25px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 180%;
        display: flex;
        align-items: center;
        font-feature-settings: "salt" on, "ss01" on;
        color: #113e57;
      }
      .new-contact-search-input {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        gap: 8px;
        width: 458px;
        height: 61px;
        background: #ffffff;
        border: 1px solid #d9ecfa;
        border-radius: 16px;
        .new-search-input {
          border: none;
          box-shadow: none;
          background: transparent;
          padding: 0;
        }
        .new-search-input:focus {
          border: none;
          box-shadow: none;
          padding: 0;
        }
      }
    }
    .new-contact-list-container {
      overflow-y: auto;
      display: flex;
      gap: 10px;
      flex-direction: column;
      width: 456px;
      height: 600px;
      overflow-y: auto;
      .no-filtered-contacts-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 8px;
        background: #ffffff;
        border: 1px dashed #808080;
        border-radius: 12px;
        margin: 10px auto;
        width: 100%;
        .no-contact-available {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          color: #113e57;
        }
      }
      .selected-staff-contact {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 24px;
        gap: 16px;
        height: 92px;
        background: #d9ecfa;
        border: 1px solid #0072bc;
        border-radius: 12px;
        justify-content: space-between;
        color: #005d98;
        cursor: pointer;
      }
      .new-clickable-item {
        height: 92px;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px;
        color: #113e57;
        background: #f5f5f5;
      }
    }
    /* Styling the scrollbar */
    .new-contact-list-container::-webkit-scrollbar {
      width: 8px;
    }
    .new-contact-list-container::-webkit-scrollbar-thumb {
      background-color: #005d98;
      border-radius: 20px;
      min-height: 50px;
      height: 50px;
    }
    .new-contact-list-container::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 20px;
    }
    .new-contact-list-container::-webkit-scrollbar-thumb:hover {
      background-color: #005d98;
    }
  }
}

.assign-staff-modal-main.second .ant-modal-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: absolute;
  width: 1036px;
  left: calc(50% - 1036px / 2);
  top: calc(50% - 821px / 2 + 0.5px);
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border: 1.5px solid #d9ecfa;
  border-radius: 16px;
  .assign-staff-select-contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 410px;
    height: 60px;
    .assign-staff-contact-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 8px;
      .selected-assign-staff-profile {
        width: 59px;
        height: 59px;
      }
      .selected-assign-staff-name-role-container {
        .selectedcontact {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #113e57;
        }
        .rolename {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #113e57;
        }
      }
    }
  }
  .assign-staff-divider {
    width: 988px;
    height: 0px;
    border: 1px solid #c9c9c9;
  }
  .assign-staff-contact-details-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    isolation: isolate;
    width: 988px;
    .assign-staff-form-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0px;
      gap: 24px;
      width: 988px;
      height: 90px;
      .assign-staff-form-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding: 0px;
        gap: 4px;
        width: 482px;
        height: 90px;
        .assign-staff-relationship-title {
          width: 482px;
          height: 25px;
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 180%;
          display: flex;
          align-items: center;
          font-feature-settings: "salt" on, "ss01" on;
          color: #113e57;
        }
        .assign-staff-form-input-box {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          gap: 8px;
          width: 482px;
          height: 61px;
          background: #ffffff;
          border: 1px solid #d9ecfa;
          border-radius: 16px;
          /* content within input fields */
          .assign-staff-input-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 16px 0px 0px;
            gap: 8px;
            border: none;
            box-shadow: none !important;
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            display: flex;
            align-items: center;
            font-feature-settings: "salt" on, "ss01" on;
            color: #000000;
          }
        }
      }
    }
    .assign-staff-role-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      width: 988px;
      height: 379px;
      .assign-staff-role-title {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #005d98;
      }
    }
  }
}

.assign-staff-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 40px;
  height: 56px;
  .assign-staff-modal-header-title {
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #005d98;
  }
  .assign-staff-modal-close-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    border-radius: 32px;
  }
}

.assign-staff-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  gap: 16px;
  height: 76px;
  .cancel-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    width: 136px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #005d98;
    border-radius: 32px;
    .cancel-text {
      width: 56px;
      height: 19px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on;
      color: #005d98;
    }
  }
  .submit-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    width: 171px;
    height: 56px;
    background: #d9ecfa;
    border-radius: 32px;
    border: none;
    .submit-icon {
      width: 24px;
      height: 24px;
      max-height: 32px;
      fill: #113e57;
      stroke: #113e57;
    }
    .submit-text {
      width: 91px;
      height: 19px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on;
      color: #113e57;
    }
    &:hover {
      background: #d9ecfa !important;
    }
  }
  .disabled-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    width: 171px;
    height: 56px;
    background: #808080;
    border-radius: 32px;
    border: none;
    .disabled-icon {
      width: 24px;
      height: 24px;
      max-height: 32px;
      fill: #ffffff;
      stroke: #ffffff;
    }
    .disabled-text {
      width: 91px;
      height: 19px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on;
      color: #ffffff;
    }
    &:hover {
      background: #808080 !important;
    }
  }
}
