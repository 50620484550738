/* Wellness.jsx */
.wellness-header-container {
  display: flex;
  cursor: default;
  align-items: flex-start;
  align-self: stretch;
}

.wellness-header-subtitle {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #808080;
  margin-top: 8px;
}

.wellness-header-icon-title-new-container{
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 16px;
}

.wellness-header-title-new{
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #005D98;
  }
.wellness-header-sub-title{
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #113E57;
  display: flex;
flex-direction: row;
align-items: center;
padding: 0px;
gap: 10px;
}


.wellness-header-icon-title {
  display: flex;
}

.wellness-count {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  /* gap: 16px; */
  margin-top: 2rem;
  justify-content: space-between;
}

.wellness-actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px;
  gap: 12px;

  .wellness-datefilter {
    display: flex;
    height: 34px;
    padding: 4px 17px 4px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 92, 152, 0.16);

    .wellness-datapicker-hide {
      display: none;
    }
    .wellness-datefilter-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .wellness-date {
        color: #000;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        .wellness-day {
          font-weight: 700;
        }
        .wellness-calendar {
          padding-left: 10px;
          .action {
            display: flex;
            gap: 10px;
          }
          .ant-picker {
            height: 34px !important;
          }
        }
      }
    }
  }
  .wellness-divider {
    width: 1px;
    height: 42px;
    background: #d9d9d9;
  }
}

.wellness-count-title > label {
  width: 76px;
  height: 22px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #005d98;
}

.supported-devices-action-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 218px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ccdce4;
  border-radius: 32px;
  cursor: pointer;
}

.supported-devices-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  gap: 10px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4e758e;
}

.wellness-item-header-support-device-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.wellness-item-header-support-device-btn > label {
  width: 144px;
  height: 19px;
  cursor: pointer;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #005d98;
}

.wellness-item-header-view-history-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 166px;
  height: 48px;
  border-radius: 32px;
  cursor: pointer;
  border: none;
  background: none;
  gap: 10px;
}

.wellness-item-header-view-history-btn label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4e758e;
  cursor: pointer;
}

.wellness-item-content {
  display: flex;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  width: auto;
}

.wellness-item-no-data-message{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px dashed #808080;
  border-radius: 16px;
  width: auto;
  .wellness-item-no-data-label1{
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #113E57;
  }
  .wellness-item-no-data-label2{
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #113E57;
  }
}

.wellness-item-content-without-shawdow {
  display: flex;
  padding: 5px 28px;
  gap: 24px;
  background: #ffffff;
  border-radius: 16px;
  width: auto;
  justify-content: space-between;
}

.wellness-item-content-divider {
  margin-left: 17%;
}

.wellness-item-content-divider-without-shadow {
  padding-left: 3rem;
}

.wellness-item-content-day-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #113e57;
  margin-left: 10%;
  max-width: 32px;
}

.wellness-item-content-statistic {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
  min-width: 35%;
  margin-left: 5px;
}

.wellness-item-content-device {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 1rem 3rem;
}

.wellness-item-content-device-model-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
}

.wellness-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  gap: 1rem;
  padding-bottom: 2rem;
}

.wellness-item-with-history {
  width: 100%;
  box-sizing: border-box;
  cursor: default;
  white-space: nowrap;
}

.wellness-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -23px;
  padding: 0px;
  gap: 8px;
}

.wellness-item-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.wellness-item-header-title label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #005d98;
  margin-left: -30px;
}

.wellness-item-header-title-glucose-meter {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: 10px;
  justify-content: center;
}
.wellness-item-header-title-glucose-meter label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #005d98;
  margin-left: -30px;
}

.wellness-item-header-actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.wellness-item-header-support-device-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.wellness-item-header-support-device-btn label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-decoration: underline;
  color: #005d98;
}

.wellness-item-content-header {
  display: flex;
  align-items: center;
  min-width: 31%;
}

.wellness-item-content-time-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
  margin-left: 3%;
}

.wellness-item-content-statistic-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-width: 18%;
}

.wellness-item-content-statistic-info-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.wellness-item-content-statistic-info-value {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #005d98;
}

.wellness-item-content-device-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
}

.wellness-item-content-device-model-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #113e57;
}

.wellness-item-content-device-model-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #005d98;
  margin-right: 10px;
  min-width: 160px;
  display: flex;
  align-items: center;
}

.wellness-item-content-device-model-model{
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  margin-left: 10px;
}

.wellness-modal-container .ant-modal-content {
  max-height: 50vh; /* You can use `vh` to make it responsive based on viewport height */
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
}

.wellness-header-icon-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 32px;
  cursor: pointer;
  justify-content: center;
  background: #d9ecfa;
}

.wellness-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  order: 1;
  align-self: stretch;
}

// ##############

.wellness-page-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #d9ecfa;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  align-self: stretch;
}

.horizontal-divider {
  width: 99%; /* Same width as the SVG */
  height: 1px; /* Matches the line height of 1 */
  background-color: #c9c9c9; /* Matches the stroke color of the SVG */
  margin: 20px auto; /* Center align the line horizontally (optional) */
}

.table-data-container {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 8px;
}

.wellness-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.wellness-tabs .ant-tabs-tab {
  font-family: "Euclid Circular A";
  font-weight: 700;
  font-size: 20px;
  color: #808080;
}

.wellness-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #113e57 !important;
  font-weight: 600;
}

.wellness-tabs .ant-tabs-ink-bar {
  background-color: #113e57;
  height: 3px;
}

.modal-table-data-history-container::-webkit-scrollbar,
.table-body::-webkit-scrollbar {
  width: 15px;
}

.modal-table-data-history-container::-webkit-scrollbar-track,
.table-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.modal-table-data-history-container::-webkit-scrollbar-thumb,
.table-body::-webkit-scrollbar-thumb {
  background-color: #005d98;
  border-radius: 10px;
  border: 3px solid #f0f0f0;
}

.modal-table-data-history-container::-webkit-scrollbar-thumb:hover
  .table-body::-webkit-scrollbar-thumb:hover {
  background-color: #004075;
}

.modal-table-data-history-container::-webkit-scrollbar-button
  .table-body::-webkit-scrollbar-button {
  display: none;
}

.wellness-tabs .ant-tabs-nav::before {
  border: none;
}

.wellness-tabs .ant-tabs-ink-bar {
  background-color: #113e57;
  height: 20px;
}

.modal-wellness-item-content-header {
  display: flex;
  min-width: 28%;
}

.modal-wellness-item-content-day-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #113e57;
  margin-left: 10%;
  max-width: 50px;
}

.modal-wellness-item-content-divider {
  margin-left: 15%;
}

.modal-wellness-item-content-time-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
  margin-left: 3%;
}

.modal-wellness-item-content-statistic-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  min-width: 18%;
}

.modal-wellness-item-content-statistic-info-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.tab-content > .no_data {
  display: flex;
  justify-content: center;
}

.modal-wellness-item-content-statistic-info-value {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #005d98;
}

.modal-wellness-item-content-device {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 1rem 5rem;
}

.modal-wellness-item-content-device-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
}

.modal-wellness-item-content-device-model-info {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  white-space: nowrap;
}

.modal-wellness-item-content-device-model-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #005d98;
  margin-right: 10px;
}

.modal-wellness-item-content-device-model-model{
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #113e57;
  margin-left: 10px;
}

.modal-wellness-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  order: 1;
  align-self: stretch;
}

.modal-horizontal-divider {
  width: 99%; /* Same width as the SVG */
  height: 1px; /* Matches the line height of 1 */
  background-color: #c9c9c9; /* Matches the stroke color of the SVG */
  margin: 20px auto; /* Center align the line horizontally (optional) */
}

.wellness-modal-container .ant-modal-content {
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
}

.support-device-modal-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  // max-height: 80vh;
  display: flex;
  width: 1164px;
  height: 661px;

  .wellness-support-modal-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 1084px;
    height: 581px;
  }

  .wellness-support-device-modal-custom-tab .ant-tabs-tab-active {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 4px solid #005d98;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #003252;
  }
  .wellness-support-device-modal-custom-tab .ant-tabs-tab {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #808080;
  }
  .wellness-support-device-modal-custom-tab .ant-tabs-nav::before {
    border-bottom: none;
  }

  .wellness-support-device-modal-custom-tab
    .ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #003252;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Euclid Circular A";
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }
  .wellness-support-device-modal-custom-tab .ant-tabs-ink-bar {
    display: none;
  }
}
.wellness-support-modal-custom-table {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 32px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #d9ecfa;
  border-radius: 16px;
  height: 437px;
  .wellness-support-modal-table-body-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
    width: 1036px;
    height: 437px;
    .wellness-support-modal-table-header-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(28, 28, 28, 0.1);
      border-radius: 16px;
      width: 1036px;
      .wellness-support-modal-table-header-block {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 16px;
        isolation: isolate;
        .wellness-support-modal-table-header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 4px;
          width: 200px;
          .wellness-support-modal-header-title {
            font-family: "Euclid Circular A";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #808080;
          }
        }
      }
    }
    .wellness-support-modal-table-body {
      display: flex;
      flex-direction: column;
      padding: 24px;
      background: #f5f5f5;
      border-radius: 16px;
      align-items: flex-start;
      width: 1036px;
      overflow-y: auto;
      height: 324px;
      gap:16px;
      .wellness-support-modal-table-row {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 8px 0px;
        gap: 24px;
        .wellness-support-modal-table-row-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 16px;
          .wellness-support-modal-table-cell {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0px;
            width: 200px;

            // First column
            &:nth-child(1) {
              font-family: "Euclid Circular A";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              color: #005d98;
            }

            // Second column
            &:nth-child(2) {
              font-family: "Euclid Circular A";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #005d98;
            }

            // Third column
            &:nth-child(3) {
              font-family: "Euclid Circular A";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #005d98;
            }
          }
        }
      }
    }
  }
}
.wellness-support-modal-table-body::-webkit-scrollbar {
  width: 8px;
}
.wellness-support-modal-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.wellness-support-modal-table-body::-webkit-scrollbar-thumb {
  background: #005d98;
  border-radius: 4px;
}
.wellness-support-modal-table-body::-webkit-scrollbar-thumb:hover {
  background: #005d98;
}
.wellness-new-close-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-radius: 999px;
  cursor: pointer;
}

.wellness-modal-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 31rem;
  white-space: nowrap;
  align-self: stretch;
}

.wellness-header-icon-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.wellness-header-icon {
  display: flex;
  align-items: center;
  padding: 0px;
  isolation: isolate;
  margin-bottom: 25px;
}

.wellness-header-icon-new{
  display: flex;
  align-items: center;
  padding: 0px;
  isolation: isolate;
}

.wellness-header-title {
  color: #113e57;
  font-family: "Euclid Circular A";
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  cursor: default;
  font-style: normal;
}

.wellness-supported-device-header-title {
  color: #005d98;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  cursor: default;
}

.wellness-support-device-header-sub-title {
  color: #113e57;
  font-family: "Euclid Circular A";
  font-size: 18px;
  font-weight: 400;
  line-height: 22.82px;
  margin-top: 10px;
}

.close-button {
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 32px;
  cursor: pointer;
  background: #d9ecfa;
  align-items: center;
}

.wellness-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.wellness-tabs .ant-tabs-tab {
  font-family: "Euclid Circular A";
  font-weight: 700;
  font-size: 20px;
  color: #808080;
}

.wellness-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #113e57;
  font-weight: 600;
}

.wellness-tabs .ant-tabs-ink-bar {
  background-color: #113e57;
  height: 3px;
}

.tab-content {
  width: 100%;
  margin-top: 2rem;
}

.modal-wellness-item-content,
.modal-wellness-insight_chart-content {
  display: flex;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  gap: 16px;
  width: 87rem;
  white-space: nowrap;
  padding-left: 12px;
}

.modal-wellness-list-item-content {
  display: flex;
  background: #ffffff;
  gap: 25px;
  width: 100%;
  white-space: nowrap;
}

.modal-wellness-item-content-statistic {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  min-width: 40%;
  justify-content: center;
}

.modal-wellness-item-content-day-info,
.modal-wellness-item-content-time-info,
.modal-wellness-item-content-device-title {
  font-family: "Euclid Circular A";
  font-style: normal;
  color: #113e57;
}

.modal-wellness-item-content-statistic-info-value,
.modal-wellness-item-content-device-model-title {
  font-weight: 700;
  font-size: 20px;
  color: #005d98;
}

.vertical-divider {
  display: flex;
  width: 1px;
  height: 50px;
  background-color: #c9c9c9;
  margin-top: 23px;
}

.modal-horizontal-divider {
  width: 99%;
  height: 1px;
  background-color: #c9c9c9;
  margin: 20px auto;
}

.modal-table-data-history-container {
  display: flex;
  max-height: 40vh;
  overflow-y: auto;
  flex-direction: column;
  /* padding-right: 8px; */
  min-width: -webkit-fill-available;
}

.modal-wellness-item-content-history-statistic {
  display: flex;
  max-height: 40vh;
  /* overflow-y: auto; */
  flex-direction: column;
  padding-right: 8px;
  min-width: -webkit-fill-available;
}

/* Wellness.less */
.modal-insight-chart-tab-content {
  width: 100%;
  margin-top: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  isolation: isolate;
  background: #FFFFFF;
  border: 1px solid #D9ECFA;
  border-radius: 16px;
}

.wellness-insight-chart-view-options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 10px;
  width: 100%;
  .wellness-insight-chart-view-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 4px;
    gap: 4px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
 
    .wellness-insight-chart-view-option {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 4px;
      background: #F5F5F5;
      border-radius: 4px;
      cursor: pointer;
      .wellness-insight-chart-view-option-label {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #808080;
      }
      &.selected-option {
        background: #D9ECFA;
        .wellness-insight-chart-view-option-label {
          color: #113E57;
        }
      }
    }
  }
}

.modal-insight-chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-insight-chart-summary-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  // box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  width: 80rem;
}

.modal-insight-chart-summary-item-value-header {
  display: flex;
  padding: 5px 55px;
  width: -webkit-fill-available;
}

.modal-insight-chart-summary-item-value {
  display: flex;
  padding: 5px 55px;
  width: -webkit-fill-available;
  border: 1px solid #d9ecfa;
  border-radius: 8px;
}

.modal-insight-chart-summary-title {
  font-family: "Euclid Circular A";
  font-weight: 700;
  font-size: 18px;
  color: #113e57;
  margin-bottom: 16px;
}

.modal-insight-chart-summary-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal-insight-chart-summary-item-label {
  display: flex;
  min-width: 15%;
  align-items: center;
}

.modal-insight-chart-summary-item {
  display: flex;
  // flex-direction: column;
  gap: 8px;
}

.modal-insight-chart-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808080;
}

.modal-insight-chart-value {
  color: #005d98;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.modal-insight-chart-value.avg {
  color: #005d98;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  min-width: 44%;
}

.modal-insight-chart-value.avg > label {
  color: #113e57;
  font-family: "Euclid Circular A";
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

.modal-insight-chart-value.avg-header {
  font-family: "Euclid Circular A";
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;

  color: #808080;
}

.modal-insight-chart-value.low {
  color: #005d98;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  min-width: 42%;
}
.modal-insight-chart-value.low-header {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  margin-left: 24rem;
  color: #808080;
}

.modal-insight-chart-value.high-header {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-left: 22.8rem;
  color: #808080;
}

.modal-insight-chart-chart-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px 16px;
  gap: 16px;
  background: #FFFFFF;
  border-radius: 16px;
  width: 100%; /* Set the width to 100% */
  height: 33vh; /* You can adjust this height as needed */
}

.recharts-tooltip-label {
  font-family: "Euclid Circular A";
  font-size: 14px;
  color: #113e57;
}

.recharts-cartesian-grid-line {
  stroke: #e8e8e8;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #e8e8e8;
}

.recharts-default-legend {
  font-family: "Euclid Circular A";
  font-weight: 500;
  font-size: 14px;
  color: #113e57;
}

.date-arrow {
  cursor: pointer;
  font-size: 16px; // Adjust icon size
  color: #173f5f; // Dark teal color from your screenshot
  font-weight: 900; // Increases line thickness slightly
  margin: 0 10px;
  &:hover {
    color: #40a9ff;
  }
}

.note-box {
  width: -webkit-fill-available;
}

.close-button {
  cursor: pointer;
}

/* Custom Table Styling */
.custom-table {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .table-header-row {
    display: flex;
    justify-content: space-between;
    gap: 16.5em;
    font-weight: bold;
    font-size: 14px;
    color: #003366;
    margin-right: 33rem;
  }
  .table-body {
    border-top: 1px solid #c9c9c9;
    margin-top: 20px;
    max-height: 49vh;
    overflow-y: auto;
    .table-row {
      display: flex;
      gap: 9em;
      font-weight: bold;
      font-size: 14px;
      color: #003366;
      margin-right: 33rem;
      padding: 15px 0px 10px 2px;
      white-space: nowrap;
    }
  }
}

.table-header,
.table-cell {
  > span {
    flex: 1;
    line-height: 20.29px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
  }
}

.table-cell {
  &:nth-child(1) {
    color: #005d98;
    min-width: 30%;
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
    line-height: 22.82px;
    font-family: "Euclid Circular A";
  }
  &:nth-child(2) {
    min-width: 25%;
    font-family: "Euclid Circular A";
    font-weight: 400;
    font-size: 16px;
    line-height: 20.29px;
  }
}