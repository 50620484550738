/* DeviceInventory.jsx */
.device-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: default;
  align-self: stretch;
  gap: 24px;
}
.device-header-title {
  color: #113e57;
  margin: auto;
  font-family: "Euclid Circular A";
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  font-style: normal;
  cursor: default;
}
.device-header-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  isolation: isolate;
  width: 36px;
  height: 36px;
}
.custom-hint-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #005d98;
}
.unpair-action-info-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;
  min-width: 512px;
  min-height: 124px;
  background: #f5f5f5;
  border: 1px solid #808080;
  border-radius: 8px;
  order: 1;
  align-self: stretch;
  margin-top: 25px;
}
.unpair-custom-information-header {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #113e57;
  flex: none;
  order: 1;
  flex-grow: 1;
}
.unpair-custom-information-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #113e57;
  flex: none;
  order: 1;
  flex-grow: 1;
}

/* When no devices are under all/paired/unpaired tab*/
.no-devices-container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 40px 0px;
  // gap: 8px;
  // order: 1;
  // align-self: stretch;
  .no-devices-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    border: 1px dashed #808080;
    border-radius: 16px;
    .no-devices-info {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // padding: 0px;
      // gap: 32px;
      // align-self: stretch;
      .label {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #113e57;
        display: flex;
      }
      .description {
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #113e57;
      }
    }
  }
}

/* DeviceInventoryList.jsx */
.device-info {
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 150px;
}
.device-name {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #113e57;
  min-width: 274px;
}
.sensor-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.device-location {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #113e57;
  min-width: 150px;
}
.device-serial-number {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #808080;
  order: 1;
  min-width: 150px;
}
.device-status-icon {
  width: 60px;
  height: 60px;
  order: 1;
}
.status-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
}
.device-status-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  gap: 4px;
}
.device-status-time {
  font-weight: 400;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-weight: 400;
  line-height: 20.29px;
  white-space: nowrap;
}
.device-status-connection-time {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #808080;
  order: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.device-battery-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 8px;
}
.device-battery-level-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
}
.device-battery-level {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
}
.view-more-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  border-radius: 32px;
  order: 3;
  flex-grow: 0;
  padding: 12px 24px;
}
.view-more {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  font-feature-settings: "salt" on;
  color: #005d98;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}
.device-card {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  cursor: default;
  flex-wrap: nowrap;
}
.hc200-device-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #d9ecfa;
  border: 1px solid #3fa9f5;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  margin-bottom: 20px;
  cursor: default;
}
.device-card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: auto;
  height: 60px;
  align-self: stretch;
  flex-grow: 0;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.device-card-left-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  margin: 0 auto;
  min-width: 274px;
}
.paired-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  width: 110px;
}
.device-connection-icon-info {
  .empty-action {
    width: 60px;
  }
}
.device-online-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  justify-content: flex-end;
}

/* DeviceInventoryModal.jsx*/
.pop-up-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  min-width: 592px;
  min-height: 249px;
  left: calc(50% - 592px / 2 + 0.07px);
  top: calc(50% - 249px / 2 + 0.5px);
  gap: 32px;
  justify-content: center;
  gap: 32px;
  border-radius: 16px;
  padding: 40px;
  .ant-modal-content {
    min-width: 592px;
    min-height: 249px;
    position: absolute;
    left: calc(50% - 592px / 2 + 0.07px);
    top: calc(50% - 249px / 2 + 0.5px);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
  }
}
.pop-up-modal-container-medium .ant-modal-content {
  .custom-footer-medium {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    gap: 16px;
    width: 512px;
    height: 90px;
  }
}
.custom-modal-title {
  min-width: 548px;
  min-height: 40px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30.43px;
  text-align: center;
  color: #113e57;
  display: flex;
  gap: 16px;
}
.custom-modal-description {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #005d98;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 548px;
  min-height: 86px;
}
.custom-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  gap: 16px;
  width: 512px;
  height: 65px;
}
.custom-yes-button,
.custom-no-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  min-width: 156px;
  height: 56px;
  border-radius: 32px;
  cursor: pointer;
}
.custom-yes-button {
  border: #d9ecfa !important;
  background: #d9ecfa !important;
}
.custom-no-button {
  background: #ffffff !important;
  border: 1px solid #005d98 !important;
  border-radius: 32px !important;
}
.custom-yes-label,
.custom-no-label {
  height: 19px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on;
}
.custom-yes-label {
  color: #113e57;
}
.custom-no-label {
  color: #005d98;
}
.custom-cancel-icon {
  fill: #005d98;
}
.custom-icon-wrapper {
  width: 24px;
  height: 24px;
}
.device-modal-container .ant-modal-content {
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-container {
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  cursor: default;
}
.device-modal-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.device-header-icon-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
}
.modal-close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 32px;
  cursor: pointer;
  justify-content: center;
  background: #d9ecfa;
}
.modal-close-icon {
  fill: #113e57;
  stroke: #113e57;
  width: 13.01px;
  height: 13.01px;
}

/*Modal info container*/
.device-info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  order: 1;
  align-self: stretch;
}

/* Modal Info Header, battery, online status */
.device-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  order: 1;
  align-self: stretch;
}
.device-page-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #d9ecfa;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  align-self: stretch;
}
.hc200-styled-device-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  gap: 24px;
  background: #d9ecfa;
  border: 1px solid #3fa9f5;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
}
.device-info-block-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;
  align-self: stretch;
}
.device-information-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}
.device-connection-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;
  order: 2;
}

/*DeviceGeneralInfoCard.jsx*/
.small-device-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 16px;
  min-height: 134px;
  border: 1.5px solid #d9ecfa;
  border-radius: 16px;
  order: 1;
  align-self: stretch;
}
.general-info-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #005d98;
}
.expanded-device-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  padding: 0px;
  gap: 16px 80px;
  order: 1;
  align-self: stretch;
  min-width: 876px;
  min-height: 56px;
}
.info-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  min-width: 224px;
  min-height: 20px;
}
.info-key {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  order: 1;
}
.info-value {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
  order: 2;
}
.hc200-styled-small-device-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 16px;
  height: 134px;
  background: #ffffff;
  border-radius: 16px;
  border: 1.5px solid #d9ecfa;
}

/* Modal Info */
.device-actions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  align-self: stretch;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 470px;
}
.device-action-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  flex: none;
  align-self: stretch;
  flex-grow: 1;
}
.device-action-command-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex: none;
  align-self: stretch;
  flex-grow: 1;
}
.device-action-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #113e57;
  flex: none;
  align-self: stretch;
}
.device-action-description-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  order: 1;
  align-self: stretch;
}
.device-details-transparent {
  opacity: 30%;
}
.device-action-icon-label {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border: 1px solid #005d98;
  border-radius: 32px;
  cursor: pointer;
}
.tv-info-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  min-width: 390px;
  min-height: 20px;
}
.tv-info-key {
  min-height: 20px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #113e57;
}
.tv-info-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #113e57;
}

/* EM200 Modal Info */
.unpair-description-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #808080;
  flex: none;
  flex-grow: 1;
  word-wrap: break-word;
  width: 422px;
}
.unpair-block-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  order: 2;
  align-self: stretch;
}
.unpair-action-label,
.check-for-updates-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on;
  color: #005d98;
  order: 1;
}
.unpair-icon {
  width: 24px;
  height: 24px;
}
.em200-device-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  width: 470px;
}
.em200-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 24px;
  flex: none;
  align-self: stretch;
  flex-grow: 1;
}
.device-model-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #113e57;
  align-self: stretch;
  width: 422px;
  flex-wrap: wrap;
}
.em200-device-icon,
.hc200-device-icon {
  width: 422px;
  height: 218px;
}

/*Remote Control Modal Info*/
.rc400-bloc-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 956px;
  min-height: 557px;
  order: 1;
  align-self: stretch;
}
.bloc-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 470px;
  height: 557px;
  flex-grow: 1;
}
.actions-bloc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  min-width: 470px;
  height: 255px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  align-self: stretch;
}
.actions-bloc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 422px;
  height: 207px;
  align-self: stretch;
}
.actions-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 422px;
  height: 127px;
  align-self: stretch;
}
.device-page-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 422px;
  height: 88px;
  order: 1;
  align-self: stretch;
}
.action-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  width: 422px;
  height: 56px;
  order: 2;
  align-self: stretch;
}
.find-me,
.unpair,
.medical-action-title {
  min-width: 422px;
  min-height: 20px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.29px;
  color: #808080;
}
.find-me-desc,
.unpair-desc,
.medical-action-desc {
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-weight: 400;
  line-height: 20.29px;
  text-align: left;
  color: #808080;
}
.find-me-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 124px;
  height: 56px;
  border-radius: 32px;
  cursor: pointer;
}
.unpair-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 116px;
  height: 56px;
  border: 1px solid #005d98;
  border-radius: 32px;
  order: 1;
  cursor: pointer;
}
.find-me-icon {
  width: 24px;
  height: 24px;
  stroke: #005d98;
}
.action-label {
  width: 60px;
  height: 19px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on;
  color: #005d98;
  order: 1;
}
.find-me-button .action-label {
  text-decoration: underline;
}
.unpair-button .action-label {
  text-decoration: none;
}
.rc400-bloc-bottom.full-height .update-bloc {
  height: 100%;
}
.tv-configuration-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  min-height: 193px;
  min-width: 470px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
}
.configuration-block-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 382px;
  height: 145px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  color: #113e57;
}
.tv-custom-title-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  min-width: 382px;
  min-height: 21px;
}
.configuration-details-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  isolation: isolate;
  width: 382px;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 16px;
  background: #ffffff;
  border: 1.5px solid #d9ecfa;
  border-radius: 16px;
  position: relative;
}
.done-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 12px;
  gap: 8px;
  position: absolute;
  height: 36px;
  right: 8px;
  top: -16px;
  border-radius: 32px;
}
.device-bloc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  isolation: isolate;
  width: 510px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  position: relative;
}
.top-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 422px;
  height: 23px;
  align-self: stretch;
}
.latest-action {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 8px;
  width: 422px;
  height: 44px;
  background: #fff1d9;
  border: 1px solid #ffa500;
  border-radius: 8px;
  order: 1;
  align-self: stretch;
  z-index: 1;
}
.latest-action-icon {
  width: 20px;
  height: 20px;
}
.latest-action-title {
  height: 20px;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-weight: 700;
  line-height: 20.29px;
  text-align: left;
  color: #113e57;
}
.latest-action-subtitle {
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-weight: 400;
  line-height: 20.29px;
  text-align: left;
  color: #113e57;
  display: inline;
}
.highlight-text {
  font-weight: 700;
}
.remote-container {
  order: 2;
  align-self: stretch;
  flex-grow: 1;
  z-index: 2;
  position: relative;
}
.subtitle-info {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 4px;
  gap: 8px;
  width: 422px;
  height: 40px;
  border: 1.5px solid #ccdce4;
  border-radius: 12px;
  order: 4;
  align-self: stretch;
  z-index: 4;
  .current-orientation-label {
    width: 235px;
    height: 20px;
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #113e57;
    .current-orientation-subtitle {
      font-family: "Euclid Circular A";
      font-size: 16px;
      font-weight: 400;
      line-height: 20.29px;
      text-align: left;
    }
  }
  .arrow-up {
    width: 24px;
    height: 24px;
    order: 1;
    fill: #113e57;
  }
}
.device-bloc.small {
  height: 537px;
}
.device-bloc.large {
  height: 667px;
}

/* remote control layout */
.remote-body {
  box-sizing: border-box;
  width: 140px;
  height: 450px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 4px solid #113e57;
  border-radius: 200px;
  display: flex;
  margin: 0 auto;
}
.remote-content {
  position: relative;
  width: 119px;
  height: 306px;
  left: 6px;
  top: 46px;
}
.ok-button {
  position: absolute;
  width: 25px;
  height: 18px;
  left: calc(50% - 25px / 2 + 0.5px);
  top: 99px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #113e57;
  justify-content: center;
}
.ok-button-selected {
  position: absolute;
  width: 40px;
  height: 40px;
  left: calc(50% - 40px / 2 + 0.5px);
  top: 89px;
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #113e57;
  box-shadow: 0 0 6px 3px #f9c030;
  background-color: #f9c030;
  border-radius: 50%;
  justify-content: center;
}
.rc-button-selected {
  left: 12px;
  top: 200px;
  box-shadow: 0 0 6px 3px #f9c030;
  background-color: #f9c030;
  border-radius: 50%;
}
.navigation-selected {
  fill: yellow;
}
.navigation-default {
  fill: white;
}
.row-1,
.row-2,
.row-3,
.row-4,
.row-5 {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  gap: 4px;
}

/* HC200 Modal Info */
.hc200-container-row-1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.hc200-action-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on;
  color: #113e57;
  order: 1;
}
.actions-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 956px;
  height: 245px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  order: 1;
  align-self: stretch;
}
.hc200-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 908px;
  height: 167px;
  align-self: stretch;
}
.hc200-actions-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  align-self: stretch;
}
.action-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  order: 1;
  align-self: stretch;
}
.action-description-row {
  display: flex;
  align-items: center;
  align-self: stretch;
}
.device-action-row-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #808080;
  align-self: stretch;
}
.device-action-row-description {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #808080;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.action-bottom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  width: 908px;
  height: 56px;
  order: 2;
  align-self: stretch;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
}
.new-factory-reset-icon,
.new-restart-icon {
  cursor: pointer;
  fill: #113e57;
}
.new-check-for-updates-icon {
  fill: #0072bc;
}
.hc200-container-row-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  min-width: 956px;
  min-height: 481px;
  order: 2;
  align-self: stretch;
}
.hc200-column-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.hc200-actions-container-column-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  flex-grow: 1;
}
.hc200-networking-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  align-self: stretch;
}
.hc200-networking-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  align-self: stretch;
}
.networking-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  align-self: stretch;
}
.nw-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #113e57;
  align-self: stretch;
}

/*Networking details block */
.networking-details-block-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 16px;
  border: 1.5px solid #d9ecfa;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 1;
  position: relative;
  isolation: isolate;
  width: 422px;
  height: 108px;

  .networking-details-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;

    .networking-details-block-1 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 390px;
      height: 60px;

      .label-container-1 {
        flex: none;
        order: 0;
        flex-grow: 0;

        .label-1 {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #113e57;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
      .label-container-2 {
        display: flex;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        flex-direction: column;

        .label-2 {
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: #113e57;
          width: 337px;
        }
      }
    }

    .connectedicon-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      position: absolute;
      right: 8px;
      top: -16px;
      border-radius: 32px;
      flex: none;
      order: 1;
      flex-grow: 0;
      z-index: 1;
    }
  }
}
.hc200-tv-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 470px;
  height: 301px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  .hc200-tv-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 422px;
    height: 253px;

    .tv-label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 422px;
      height: 21px;

      .hc200-tv-label {
        width: 422px;
        height: 21px;
        font-family: "Euclid Circular A";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #113e57;
      }
    }
    .tv-details-container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 24px 16px;
      gap: 8px;
      width: 422px;
      height: 208px;
      background: #ffffff;
      border: 1.5px solid #d9ecfa;
      border-radius: 16px;
      position: relative;
    }
  }
}
.hc200-column-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  width: 470px;
  height: 518px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

/*MedicalDeviceModalInfo*/
.medical-action-description-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #808080;
  align-self: stretch;
}
.medical-actions-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  min-width: 908px;
  min-height: 56px;
  order: 2;
  align-self: stretch;
}
.clear-all-history-icon-label,
.factory-reset-icon-label {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  font-feature-settings: "salt" on;
  color: #005d98;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
}
.device-page-actions-disabled {
  pointer-events: none;
}
.device-page-actions-disabled .hc200-action-label {
  color: #ffffff;
}
.device-page-actions-disabled.custom-yes-button {
  background: #808080 !important;
}
.device-page-actions-disabled.new-restart-icon {
  fill: #ffffff !important;
}
.device-page-actions-disabled.device-action-icon-label {
  background: #ffffff;
  border: 1px solid #808080;
}
.device-page-actions-disabled .check-for-updates-label,
.device-page-actions-disabled .factory-reset-icon-label {
  color: #808080;
}
.device-page-actions-disabled .new-check-for-updates-icon {
  fill: #808080;
}
.device-page-actions-disabled .action-label {
  color: #808080;
}
.unpair-button.device-page-actions-disabled {
  border: 1px solid #808080;
}
.device-page-actions-disabled.unpair-action-label {
  color: #808080;
}
.device-page-actions-disabled .unpair-action-label {
  color: #808080;
}
/* Tooltip-specific styles for StatusInfo */
.device-page-tooltip-status-info .device-status-label,
.device-page-tooltip-status-info .device-status-time {
  font-family: "Euclid Circular A", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  white-space: nowrap;
  display: block;
  word-wrap: break-word;
}
.device-page-no-devices-banner {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 16px;
  background: #fff1d9;
  border: 1px solid #ffa500;
  border-radius: 8px;
  margin-bottom: 20px;
  .device-page-banner-container-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    height: 48px;
    .device-banner-text {
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      align-items: center;
      color: #113e57;
    }
    .device-banner-bold {
      font-family: "Euclid Circular A";
      font-size: 16px;
      font-weight: 700;
      line-height: 20.29px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .radio-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      gap: 8px;
      margin: 0 auto;
      width: 334.67px;
      height: 48px;
      border-radius: 32px;
      cursor: default;
    }
    .radio-label {
      width: 334.67px;
      height: 19px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      font-feature-settings: "salt" on;
      color: #005d98;
    }
    .assigned-radio-label {
      width: 334.67px;
      height: 19px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      font-feature-settings: "salt" on;
      color: #808080;
      cursor: default;
    }
  }
}
.device-page-assign-camera-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  height: 56px;
  background: #d9ecfa;
  border-radius: 32px;
  cursor: pointer;
  .assign-smart-camera-title {
    height: 19px;
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    font-feature-settings: "salt" on;
    color: #113e57;
  }
  .smart-camera-add-icon {
    width: 24px;
    height: 24px;
    max-height: 32px;
  }
}
.device-page-assign-smart-camera-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 554px;
  background: #ffffff;
  box-shadow: 6px 6px 20px rgba(4, 76, 123, 0.2);
  border-radius: 16px;
  .device-page-modal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 40px;
    width: 506px;
    height: 56px;
  }
  .assign-smart-camera-title {
    margin: 0 auto;
    width: 253px;
    height: 30px;
    font-family: "Euclid Circular A";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #005d98;
  }
  .assign-smart-camera-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    gap: 16px;
    width: 506px;
    height: 76px;
  }
  .no-ready-to-assign-devices-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    width: 458px;
    height: 97px;
    background: #ffffff;
    border: 1px dashed #808080;
    border-radius: 12px;
    .no-device-available {
      width: 171px;
      height: 23px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #113e57;
    }
    .no-device-message {
      width: 410px;
      height: 18px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #113e57;
    }
  }
}
.device-page-assign-smart-camera-modal.large .ant-modal-content {
  height: 970px;
}
.device-page-assign-smart-camera-modal.small .ant-modal-content {
  height: 450px;
}
.device-page-assign-smart-camera-row-2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 12px;
  isolation: isolate;
  min-width: 506px;
  background: #ffffff;
  border: 1.5px solid #d9ecfa;
  border-radius: 16px;
  .device-page-search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 4px;
    width: 458px;
    height: 90px;
    .search-label {
      width: 458px;
      height: 25px;
      font-family: "Euclid Circular A";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 180%;
      display: flex;
      align-items: center;
      font-feature-settings: "salt" on, "ss01" on;
      color: #113e57;
    }
    .device-page-assign-smart-camera-search {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      gap: 8px;
      width: 458px;
      height: 61px;
      background: #ffffff;
      border: 1px solid #d9ecfa;
      border-radius: 16px;
      .search-input {
        border: none;
        box-shadow: none;
        background: transparent;
      }
      .search-input:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .device-page-assign-camera-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    gap: 16px;
    width: 458px;
    height: 92px;
    background: #f5f5f5;
    border-radius: 12px;
    align-self: stretch;
    z-index: 1;
    .device-page-assign-camera-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 16px;
      width: 410px;
      height: 60px;
      .device-page-camera-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        width: 184px;
        height: 20px;
        .ready-to-assign-id {
          width: 184px;
          height: 20px;
          font-family: "Euclid Circular A";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #005d98;
        }
      }
      .new-hc200-icon-container {
        background: #6087a0;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .smart-camera-selected-device {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    gap: 16px;
    width: 458px;
    height: 92px;
    background: #d9ecfa;
    border: 1px solid #0072bc;
    border-radius: 12px;
  }
}
.device-page-assign-smart-camera-row-2.large {
  height: 762px;
  // overflow-y: auto;
}
.device-page-assign-smart-camera-row-2.small {
  height: 247px;
}

.device-list-scrollable-container {
  height: calc(100% - 120px); /* Adjust based on the search bar's height */
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.device-list-scrollable-container.large {
  height: 762px;
  overflow-y: auto;
}
.device-list-scrollable-container.small {
  height: 247px;
}

/* Styling the scrollbar */
.device-list-scrollable-container::-webkit-scrollbar {
  width: 8px;
}
.device-list-scrollable-container::-webkit-scrollbar-thumb {
  background-color: #005d98;
  border-radius: 20px; /* Rounded corners for the scrollbar thumb */
}
.device-list-scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}
.device-list-scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #005d98;
}
.custom-yes-button.disabled-button {
  background: #808080 !important; /* Gray background */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
}
.custom-yes-label.disabled-label {
  color: #f1f1f1 !important; /* Gray background */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
}
/* Selected label icon */
.assigned-selected-icon {
  width: 20px;
  height: 20px;
  fill: #113e57;
  stroke: #113e57;
}
.completed-selected-icon {
  width: 20px;
  height: 20px;
  fill: #027b0e;
  stroke: #027b0e;
}
.assigned-selected-icon.disabled-icon {
  fill: #f1f1f1 !important;
  stroke: #f1f1f1 !important;
  cursor: not-allowed;
}
